import React, { useState } from "react";
import {
  BackButton,
  ButtonContainer,
  CaseDialog,
  FileItem,
  FileItemsContainer,
  FileSection,
  FileUpload,
  SectionTitle,
  SelectButton,
} from "./FileDialog.styled";
import { View } from "./ExportCase.styled";
import DEFIcon from "../../../assets/images/fileTypeDEFAULT.png";
import DOCIcon from "../../../assets/images/fileTypeDOC.png";
import IMGIcon from "../../../assets/images/fileTypeIMG.png";
import DOCXIcon from "../../../assets/images/fileTypeDOCX.png";
import ODSIcon from "../../../assets/images/fileTypeODS.png";
import PDFIcon from "../../../assets/images/fileTypePDF.png";
import PLYIcon from "../../../assets/images/fileTypePLY.png";
import STLIcon from "../../../assets/images/fileTypeSTL.png";
import TXTIcon from "../../../assets/images/fileTypeTXT.png";
import XLSIcon from "../../../assets/images/fileTypeXLS.png";
import XLSXIcon from "../../../assets/images/fileTypeXLSX.png";
import ZIPIcon from "../../../assets/images/fileTypeZIP.png";

interface FileDialogProps {
  open: boolean;
  onClose: () => void;
  resourceFiles: any;
  setResourceFiles: (files: any) => void;
}

const FileDialog: React.FC<FileDialogProps> = ({
  open,
  onClose,
  resourceFiles,
  setResourceFiles,
}) => {
  const getFileExtension = (fileName: string) => {
    if (!fileName) return "";

    const match = fileName.match(/\.[^.]+$/);
    return match ? match[0].toLowerCase() : "";
  };

  const getFileIcon = (fileExtension: string) => {
    switch (fileExtension) {
      case ".doc":
      case ".rtf":
        return DOCIcon;
      case ".docx":
        return DOCXIcon;
      case ".jpg":
      case ".jpeg":
      case ".png":
        return IMGIcon;
      case ".ods":
      case ".odt":
        return ODSIcon;
      case ".pdf":
        return PDFIcon;
      case ".ply":
        return PLYIcon;
      case ".stl":
        return STLIcon;
      case ".txt":
        return TXTIcon;
      case ".xls":
        return XLSIcon;
      case ".xlsx":
        return XLSXIcon;
      case ".zip":
        return ZIPIcon;
      default:
        return DEFIcon;
    }
  };

  const [userFiles, setUserFiles] = useState<any[]>([]);

  const getFileName = (file: any): string => {
    return "name" in file ? file.name : file.fileName;
  };

  const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles: any[] = Object.values(Array.from(event.target.files));
      const existingFileNames: any[] = [
        ...(resourceFiles?.defaultAttachments || []),
        ...userFiles.map(getFileName),
      ];

      const filteredFiles: any[] = newFiles.filter(
        (file) => !existingFileNames.includes(getFileName(file))
      );

      const updatedUserFiles: any[] = [...userFiles, ...filteredFiles];
      setUserFiles(updatedUserFiles);

      const updatedSelectedAttachments = resourceFiles
        ? [...resourceFiles.selectedAttachments, ...filteredFiles]
        : [];

      setResourceFiles({
        defaultAttachments: resourceFiles?.defaultAttachments || [],
        selectedAttachments: updatedSelectedAttachments,
      });
    }
  };

  const handleFileSelection = (clickedFile: any) => {
    if (resourceFiles && setResourceFiles) {
      if (resourceFiles.selectedAttachments.includes(clickedFile)) {
        const updatedSelectedAttachments =
          resourceFiles.selectedAttachments.filter(
            (file: any) => file !== clickedFile
          );

        const updatedUserFiles = userFiles.filter(
          (file) => file !== clickedFile
        );

        setResourceFiles({
          ...resourceFiles,
          selectedAttachments: updatedSelectedAttachments,
        });

        setUserFiles(updatedUserFiles);
      } else {
        setResourceFiles({
          ...resourceFiles,
          selectedAttachments: [
            ...resourceFiles.selectedAttachments,
            clickedFile,
          ],
        });
      }
    }
  };

  return (
    <CaseDialog open={open} fullWidth>
      <View>
        <FileSection>
          <SectionTitle>
            <hr />
            <span> Acquisition files</span>
            <hr />
          </SectionTitle>
          <FileItemsContainer>
            {resourceFiles?.defaultAttachments
              .filter((f: any) => f.seriesInstanceUid != null)
              .map((file: any, index: number) => {
                const fileExtension = getFileExtension(file.fileName);
                const icon = getFileIcon(fileExtension);
                return (
                  <FileItem
                    key={index}
                    selected={
                      resourceFiles?.selectedAttachments
                        ?.filter((f: any) => f.seriesInstanceUid != null)
                        .includes(file) || false
                    }
                    onClick={() => handleFileSelection(file)}
                  >
                    <img
                      className={
                        file.thumbnailUrl ? "thumbnailImg" : "thumbnailFile"
                      }
                      src={
                        file.thumbnailUrl !== null ? file.thumbnailUrl : icon
                      }
                      alt="Attached file thumbnail"
                    />
                    <span> {file.fileName}</span>
                  </FileItem>
                );
              })}
          </FileItemsContainer>
        </FileSection>
        <FileSection>
          <SectionTitle>
            <hr />
            <span>User files</span>
            <hr />
          </SectionTitle>
          <FileItemsContainer>
            {resourceFiles?.selectedAttachments.map(
              (file: any, index: number) => {
                const fileExtension = getFileExtension(getFileName(file));
                const icon = getFileIcon(fileExtension);
                return (
                  <FileItem
                    key={index}
                    selected={true}
                    onClick={() => handleFileSelection(file)}
                  >
                    <img
                      className={"thumbnailFile"}
                      src={icon}
                      alt="Attached file thumbnail"
                    />
                    <span> {getFileName(file)} </span>
                  </FileItem>
                );
              }
            )}
          </FileItemsContainer>
          <FileUpload>
            <label htmlFor="fileInput">
              <input
                id="fileInput"
                type="file"
                multiple
                onChange={handleFilesChange}
              />
            </label>
          </FileUpload>
        </FileSection>
        <FileSection>
          <SectionTitle>
            <hr />
            <span> Patient files</span>
            <hr />
          </SectionTitle>
          <FileItemsContainer>
            {resourceFiles?.defaultAttachments
              .filter((f: any) => f.seriesInstanceUid == null)
              .map((file: any, index: number) => {
                const fileExtension = getFileExtension(file.fileName);
                const icon = getFileIcon(fileExtension);
                return (
                  <FileItem
                    key={index}
                    selected={
                      resourceFiles?.selectedAttachments
                        ?.filter((f: any) => f.seriesInstanceUid == null)
                        .includes(file) || false
                    }
                    onClick={() => handleFileSelection(file)}
                  >
                    <img
                      className={
                        file.thumbnailUrl ? "thumbnailImg" : "thumbnailFile"
                      }
                      src={
                        file.thumbnailUrl !== null ? file.thumbnailUrl : icon
                      }
                      alt="Attached file thumbnail"
                    />
                    <span> {file.fileName}</span>
                  </FileItem>
                );
              })}
          </FileItemsContainer>
        </FileSection>
        <ButtonContainer>
          <BackButton onClick={onClose}>Back</BackButton>
          <SelectButton
            disabled={resourceFiles?.selectedAttachments.length === 0}
            onClick={onClose}
          >
            Attach
          </SelectButton>
        </ButtonContainer>
      </View>
    </CaseDialog>
  );
};

export default FileDialog;
