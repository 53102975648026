export function normalizeDateTime(date: string) {
    if (/\d{14}/.test(date)) {
        const year = parseInt(date.substring(0, 4), 10);
        const month = parseInt(date.substring(4, 6), 10) - 1;
        const day = parseInt(date.substring(6, 8), 10);
        const hour = parseInt(date.substring(8, 10), 10);
        const minute = parseInt(date.substring(10, 12), 10);
        const second = parseInt(date.substring(12, 14), 10);
        return new Date(year, month, day, hour, minute, second);
    } else if (/\d{8}/.test(date)) {
        const year = parseInt(date.substring(0, 4), 10);
        const month = parseInt(date.substring(4, 6), 10) - 1;
        const day = parseInt(date.substring(6, 8), 10);
        return new Date(year, month, day);
    } else {
        return new Date(date);
    }
}