import React from "react";

const SearchIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path d="M6.5,1.5a5,5,0,1,0,5,5,5.006,5.006,0,0,0-5-5M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" />
      <path
        d="M5.222,6.778l-6-6L.778-.778l6,6Z"
        transform="translate(10.5 10.5)"
      />
    </svg>
  );
};

export default SearchIcon;
