import http from "../../../services/api/http";
import xmlJs from "xml-js";

export async function XmlReader(url: string) {
  try {
    const response = await http.get(url);
    const xmlObject = xmlJs.xml2js(response.data, { compact: true });
    return xmlObject;
  } catch (error) {
    console.log("Error while catching XML File", error);
  }
}
