import React from "react";
import { WaitContainer, Text } from "./Wait.styled";

export interface IWaitProps {
  leftOrTopText?: string;
  rightOrBottomText?: string;
}

const Wait: React.FC<IWaitProps> = ({ leftOrTopText, rightOrBottomText }) => {
  rightOrBottomText = "Loading...";

  return (
    <WaitContainer>
      {leftOrTopText && <Text>{leftOrTopText}</Text>}
      {rightOrBottomText && <Text>{rightOrBottomText}</Text>}
    </WaitContainer>
  );
};

export default Wait;
