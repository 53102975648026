import styled from "styled-components";
import { colors } from "../../../theme";

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;

  & .error {
    color: ${colors["error-red-text"]};
  }
`;

export const Message = styled.p`
  padding: 0 50px;
  color: black;
  font-size: 15px;
  font-weight: bold;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 50px;
  justify-content: center;
`;
