import React, { ChangeEvent, FormEvent, useState } from "react";
import validator from "validator";
import {
  EmailInput,
  ErrorMessage,
  RequestForm,
  RequestFormContainer,
  SubmitButton,
} from "./RequestBetaAccessForm.styled.";
import { registrationRequest } from "../../../../services/api/registrationRequestService";

const RequestBetaAccessForm: React.FC = () => {
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleSubmitForm = async (e: FormEvent) => {
    e.preventDefault();

    try {
      if (validator.isEmail(inputValue)) {
        await registrationRequest(inputValue);
        setIsFormSubmitted(true);
      } else {
        setErrorMessage("Please enter a valid email");
      }
    } catch (err: any) {
      err.statusCode === 409
        ? setErrorMessage("This email request is already registered")
        : setErrorMessage("Something went wrong! Please try again!");
    }
  };

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    setErrorMessage("");
    setInputValue(e.target.value.trim());
  }

  return (
    <RequestFormContainer>
      {!isFormSubmitted ? (
        <RequestForm onSubmit={handleSubmitForm}>
          <h2>Thank you for your interest in DEXIS IS Connect Cloud!</h2>

          <p>
            Please provide your email address and we will contact you with the
            next steps to create your account.
          </p>

          <EmailInput
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Email"
          />

          <ErrorMessage>{errorMessage}</ErrorMessage>

          <SubmitButton disabled={!inputValue}>Submit</SubmitButton>
        </RequestForm>
      ) : (
        <>
          <h2>Thank you!</h2>
          <p>A DEXIS representative will contact you shortly.</p>
        </>
      )}
    </RequestFormContainer>
  );
};

export default RequestBetaAccessForm;
