import React, { useEffect } from "react";
import {
  InteractionStatus,
  EventMessage,
  EventType,
  AuthenticationResult,
  AccountInfo,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { consoleTextStyles } from "./msalClient";
import Wait from "../../components/shared/Wait/Wait";
import IAuthService from "../../shared/interfaces/IAuthService";
import { MessageContainer } from "../../components/shared/ErrorBoundary.styled";

interface IProps {
  children: any;
}

const MsalContainer: React.FC<{
  authService: IAuthService;
  logMessages: boolean;
  children: IProps;
}> = ({ authService, logMessages, children }) => {
  const { instance, inProgress } = useMsal();

  // Handle MSAL events
  useEffect(() => {
    const setLoggedInUser = async (account: AccountInfo | null) => {
      await authService.setLoggedInUser(account);
    };

    // Add event handler
    const callbackId = instance.addEventCallback((event: EventMessage) => {
      if (logMessages) {
        console.log(
          `%cMSAL%c Event: ${event.eventType}. Interaction: ${event.interactionType}. Error: ${event.error}`,
          consoleTextStyles.Title,
          consoleTextStyles.Unset
        );
      }

      switch (event.eventType) {
        case EventType.LOGIN_START:
        case EventType.LOGOUT_START:
          setLoggedInUser(null);
          break;

        case EventType.LOGIN_SUCCESS:
          const account = (event.payload as AuthenticationResult).account;
          setLoggedInUser(account);
          break;

        case EventType.LOGIN_FAILURE:
          console.groupCollapsed(
            "%cLogin failed. There's likely a configuration issue.",
            "background: #faa; color: red"
          );
          console.error(event.error);
          console.groupEnd();
      }
    });

    // Remove event handler
    return function detachEvent() {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [authService, instance, logMessages]);

  // Is the app handling MSAL events?
  if (inProgress !== InteractionStatus.None) {
    return (
      <MessageContainer>
        <Wait />
      </MessageContainer>
    );
  }

  // All good. Load rest of app.
  return <>{children}</>;
};

export default MsalContainer;
