import styled from "styled-components";
import { colors } from "../../../theme";

export const DotsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Dot = styled.span<{ delay: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${colors["gray-8"]};
  margin: 0 2px;
  animation: dotsAnimation 1s infinite ${(props) => props.delay};

  @keyframes dotsAnimation {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
`;
