import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import {
  ICaseDetails,
  ICaseRecord,
  IConnectUser,
  IDentalCase,
  ISprintRayTreatmentType,
} from "../../../services/api/models";
import {
  createDentalCase,
  getSprintrayTreatmentList,
  convertDate,
} from "../../../services/api/caseService";
import { getCurrentUser } from "../../../services/api/userService";
import logo from "../../../assets/images/sprintray-logo.png";
import i18n, { getLanguageCode } from "../../../utils/i18n";
import { useTranslation } from "react-i18next";
import {
  AppContainer,
  Title,
  ButtonContainer,
  TreatmentGrid,
  TreatmentCard,
  TreatmentButton,
} from "./Sprintray.styled";
import { convertSex } from "../../../utils/convertSex";

interface ISprintrayProps extends ICaseDetails {
  onTransactionIdReceived: (id: string) => void;
}

type UserStepType =
  | "INIT"
  | "AUTHENTICATION"
  | "CONNECTING"
  | "CONNECTED"
  | "ERROR";

type CaseStepType =
  | "INIT"
  | "CONNEXION"
  | "ERROR"
  | "TREATMENT_SELECTION"
  | "TREATMENT_SELECTED"
  | "CASE_FINALIZATION";

const errorMessages = {
  noUserLogged: "ERROR_AUTHENTICATION_FAILURE",
  requestFailure: "NETWORK_ERROR",
  internalError: "ERROR_OCCURRED_WARNING",
  noTreatmentsAvailable: "NO_TREATMENTS_AVAILABLE",
  noPairingFound: "ERROR_NO_PAIRING_FOUND_WITH_PARTNER",
};

const Sprintray: React.FC<ISprintrayProps> = ({
  scan_mode,
  pt_pid,
  pt_last_name,
  pt_middle_name,
  pt_first_name,
  pt_birthdate,
  pt_sex,
  case_uid,
  case_date,
  acquisition_date,
  case_type,
  scan_object,
  partner_pk,
  lang,
  device_sn,
  transaction_id,
  software_name,
  software_version,
  model_name,
  onTransactionIdReceived,
}) => {
  const { t } = useTranslation();

  const [userStep, setUserStep] = useState<UserStepType>("INIT");
  const [caseStep, setCaseStep] = useState<CaseStepType>("INIT");
  const [localNodeId, setLocalNodeId] = useState(0);
  const [submittedDexisCaseId, setSubmittedDexisCaseId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userPk, setUserPk] = useState(0);
  const [selectedTreatment, setSelectedTreatment] = useState<number | null>(
    null
  );
  const [treatmentList, setTreatmentList] = useState<ISprintRayTreatmentType[]>(
    []
  );

  const handleTreatmentSelection = (id: number) => {
    setSelectedTreatment(id);
  };

  const handleValidation = () => {
    setCaseStep("TREATMENT_SELECTED");
    if (userStep === "CONNECTED") {
      if (selectedTreatment != null) {
        createConnectCase(selectedTreatment);
      } else {
        setCaseStep("INIT");
      }
    }
  };

  const createConnectCase = (treatment_id: number) => {
    const caseInfo: ICaseRecord = {
      case_uid: case_uid,
      case_path: null,
      case_date: convertDate(case_date),
      acquisition_date : acquisition_date,
      case_type: case_type,
      scan_mode: scan_mode,
      scan_object: scan_object,
      device_sn: device_sn,
      pt_pid: pt_pid,
      pt_last_name: pt_last_name,
      pt_middle_name: pt_middle_name,
      pt_first_name: pt_first_name,
      pt_birthdate: convertDate(pt_birthdate),
      pt_sex: convertSex(pt_sex),
      recipient: partner_pk,
      node_id: localNodeId,
      partner_case_id: undefined,
      is_refinement_case: false,
      treatment_id: treatment_id,
      transaction_id: transaction_id,
      software_name: software_name,
      software_version: software_version,
      model_name: model_name,
    };

    createDentalCase("sprintray", caseInfo)
      .then((response: IDentalCase) => {
        const caseId = response.caseId;
        setSubmittedDexisCaseId(caseId);
        onTransactionIdReceived(response.transactionId);
        setCaseStep("CASE_FINALIZATION");
      })
      .catch((err: any) => {
        setErrorMessage(err.message);
        setCaseStep("ERROR");
      });
  };

  const fetchTreatments = async (pk: string) => {
    try {
      const data = await getSprintrayTreatmentList(pk);
      if (!data || data.length === 0) {
        setErrorMessage(errorMessages.noTreatmentsAvailable);
        setCaseStep("ERROR");
      } else {
        setTreatmentList(data);
        setCaseStep("TREATMENT_SELECTION");
      }
    } catch (error: any) {
      const errorMessage =
        error.message === errorMessages.noPairingFound
          ? errorMessages.noPairingFound
          : error.message;
      setErrorMessage(errorMessage);
      setUserStep("ERROR");
    }
  };

  useEffect(() => {
    if (userStep === "INIT") {
      if (lang) {
        const lang_str = getLanguageCode(lang);
        i18n.changeLanguage(lang_str);
      }

      const getUser = () => {
        let pk = 0;
        let nodeId = 0;

        setUserStep("AUTHENTICATION");
        getCurrentUser()
          .then((data: IConnectUser) => {
            pk = data.userPk;
            setUserPk(pk);
            setUserStep("CONNECTING");
            if (pk === 0) {
              throw new Error(errorMessages.noUserLogged);
            }
          })
          .then((res: any) => {
            if (transaction_id == null) {
              nodeId = res.node_id;
              setLocalNodeId(nodeId);
            } else {
              nodeId = -1;
            }
            setUserStep("CONNECTED");
          })
          .catch((error: any) => {
            if (error.message === errorMessages.noUserLogged) {
              setErrorMessage(errorMessages.noUserLogged);
            } else {
              setErrorMessage(errorMessages.requestFailure);
            }
            setUserStep("ERROR");
          });
      };
      getUser();
    }
    if (caseStep === "INIT") {
      const getCases = () => {
        setCaseStep("CONNEXION");
        if (partner_pk === null) {
          setErrorMessage(errorMessages.internalError);
          return;
        }
        fetchTreatments(partner_pk);
      };
      if (userPk > 0) {
        try {
          getCases();
        } catch (error) {
          setErrorMessage(errorMessages.internalError);
        }
      }
    }
  }, [userStep, caseStep, lang, partner_pk, transaction_id, userPk]);

  return (
    <AppContainer>
      <img src={logo} alt="logo" />
      {caseStep === "TREATMENT_SELECTION" && userStep === "CONNECTED" && (
        <div className="main-content">
          <Title>{t("Select Treatment")}</Title>
          <TreatmentGrid>
            {treatmentList
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((treatment) => (
                <TreatmentCard
                  key={treatment.id}
                  $length={treatmentList.length}
                >
                  <TreatmentButton
                    onClick={() => handleTreatmentSelection(treatment.id)}
                    $isSelected={selectedTreatment === treatment.id}
                  >
                    {selectedTreatment === treatment.id && (
                      <div>
                        <span>✓</span>
                      </div>
                    )}
                    <img src={treatment.imagePath} alt="treatment icon" />
                  </TreatmentButton>
                  <span>{t(treatment.name)}</span>
                </TreatmentCard>
              ))}
          </TreatmentGrid>
          <ButtonContainer>
            <Button
              variant="contained"
              sx={{ bgcolor: "#009BB4" }}
              disabled={!selectedTreatment}
              onClick={handleValidation}
            >
              OK
            </Button>
          </ButtonContainer>
        </div>
      )}
      {caseStep === "CASE_FINALIZATION" && (
        <div>
          <h5>
            {t("Your request has been processed with the following Case ID:")}{" "}
            <strong>{submittedDexisCaseId}</strong>
          </h5>
          <h5>{t("You can close this window.")}</h5>
        </div>
      )}
      {(caseStep === "ERROR" || userStep === "ERROR") && (
        <div className="error">
          <h5>{t("ERROR_OCCURRED_WARNING")}</h5>
          <h5> {t(errorMessage)}</h5>
        </div>
      )}
    </AppContainer>
  );
};

export default Sprintray;
