import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  FormEvent,
} from "react";
import { useParams } from "react-router-dom";
import PatientCard from "../../../components/shared/Patient/PatientCard/PatientCard";
import PatientDetails from "../../../components/shared/Patient/PatientDetails/PatientDetails";
import Loader from "../../../components/shared/Loader/Loader";
import TooltipMUI from "../../../components/shared/TooltipMUI/TooltipMUI";
import LoaderDots from "../../../components/shared/LoaderDots/LoaderDots";
import NoDataCard from "../../../components/shared/NoDataCard/NoDataCard";
import { ILocation, IPatient } from "../../../services/api/models";
import { getPatientsList } from "../../../services/api/patientService";
import { getLocationById } from "../../../services/api/locationService";
import { AlignHorizontalLeft, Refresh } from "@mui/icons-material";
import {
  PatientsContainer,
  PatientListWithSearchContainer,
  PatientsList,
  Tabs,
  MainHeader,
  SearchContainer,
  ErrorMessage,
  ErrorBox,
  PatientDataContainer,
  MarkerElement,
  SearchForm,
  LoadingDotsContainer,
} from "./PatientList.styled";
import { ClearButton, IconsButton } from "../../../components/common/buttons.styled";
import SortAtoZIcon from "../../../components/svg-icons/SortAtoZIcon";
import SortZtoAIcon from "../../../components/svg-icons/SortZtoAIcon";
import SearchIcon from "../../../components/svg-icons/SearchIcon";
import DeleteIcon from "../../../components/svg-icons/DeleteIcon";
import useAuthService from "../../../services/authService";

interface PatientListProps {
  onShowTimeline: () => void;
}

const PatientList: React.FC<PatientListProps> = ({ onShowTimeline }) => {
  const params: any = useParams<{ locId: string }>();
  const locationId = params.locId;
  const [location, setLocation] = useState<ILocation>();
  const [patients, setPatients] = useState<IPatient[]>([]);
  const [patientsLoading, setPatientsLoading] = useState<boolean>(true);
  const [initialPatientsLoading, setInitialPatientsLoading] =
    useState<boolean>(true);
  const [selectedPatient, setSelectedPatient] = useState<IPatient | null>(null);
  const [error, setError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [activeTab, setActiveTab] = useState<"history" | "cases">("history");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const patientsListRef = useRef<HTMLDivElement>(null);
  const markerRef = useRef<HTMLDivElement>(null);
  const [markerTriggerMargin] = useState("300px");
  const [patientsPerPage] = useState(10);
  const [page, setPage] = useState<number>(0);
  const [allDataFetched, setAllDataFetched] = useState<boolean>(false);
  const [currentInput, setCurrentInput] = useState("");
  const { isPaidLocation } = useAuthService();

  useEffect(() => {
    const checkPaymentStatus = async () => {
      const status = await isPaidLocation();
      if (!status) {
        setActiveTab("cases");
      } else {
        setActiveTab("history");
      }
    };

    checkPaymentStatus();
  }, [isPaidLocation]);

  useEffect(() => {
    const getLocation = async () => {
      const locationData = await getLocationById(locationId);
      setLocation(locationData);
    };
    getLocation();
  }, [locationId]);

  const fetchPatients = useCallback(
    async (top: number, skip: number, isRefreshingData: boolean = false) => {
      setPatientsLoading(true);
      setError("");
      setAllDataFetched(false);

      try {
        const { patients: newPatients, totalCount } = await getPatientsList(
          location!.account.region.apiUrl,
          sortOrder,
          top,
          skip,
          searchQuery
        );

        if (isRefreshingData || page === 1) {
          setPatients([...newPatients]);
        } else {
          setPatients((prevPatients) => [...prevPatients, ...newPatients]);
        }

        if (skip + newPatients.length === totalCount) {
          setAllDataFetched(true);
        }
      } catch (error) {
        console.error("Error fetching patient data:", error);
        setError("Error fetching patient data");
      }
      setInitialPatientsLoading(false);
      setPatientsLoading(false);
    },
    [location, sortOrder, searchQuery, page]
  );

  useEffect(() => {
    if (!page || (page && allDataFetched)) {
      return;
    }

    const fetchData = async () => {
      await fetchPatients(patientsPerPage, (page - 1) * patientsPerPage);
    };

    fetchData();
  }, [fetchPatients, page, allDataFetched, patientsPerPage]);

  useEffect(() => {
    if (!location || !markerRef.current || !patientsListRef.current) {
      return;
    }
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setPage((prevState) => prevState + 1);
        }
      },
      { rootMargin: markerTriggerMargin, root: patientsListRef.current }
    );

    const markerElement = markerRef.current;

    if (markerElement) {
      observer.observe(markerElement);
    }

    return () => {
      if (markerElement) {
        observer.unobserve(markerElement);
      }
    };
  }, [location, markerRef, patientsListRef, markerTriggerMargin]);

  const manualRefresh = useCallback(async () => {
    setInitialPatientsLoading(true);
    const selectedPatientId = selectedPatient?.recordId;
    const scrollPosition = patientsListRef.current?.scrollTop;

    await fetchPatients(patients.length, 0, true);

    if (patientsListRef.current && scrollPosition !== undefined) {
      patientsListRef.current.scrollTop = scrollPosition;
    }

    if (selectedPatientId) {
      const newSelectedPatient = patients.find(
        (patient) => patient.recordId === selectedPatientId
      );
      setSelectedPatient(newSelectedPatient || null);
    }
  }, [fetchPatients, patients, selectedPatient, patientsListRef]);

  const resetPaginationState = useCallback(() => {
    setPage(1);
    setAllDataFetched(false);
    setSelectedPatient(null);
    if (patientsListRef.current) {
      patientsListRef.current.scrollTop = 0;
    }
  }, [patientsListRef]);

  const toggleSortOrder = useCallback(() => {
    setSortOrder((prevState) => {
      return prevState === "asc" ? "desc" : "asc";
    });
    resetPaginationState();
  }, [resetPaginationState]);

  const onSearchSubmitHandle = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const query = (formData.get("searchInput") as string) || "";
      setSearchQuery(query.trim());
      resetPaginationState();
    },
    [resetPaginationState]
  );

  const handleClearInput = () => {
    setCurrentInput("");
    setSearchQuery("");
    resetPaginationState();
  };

  return (
    <PatientsContainer>
      <PatientListWithSearchContainer>
        <SearchContainer>
          <TooltipMUI
            title={[sortOrder === "asc" ? "Sort Z to A" : "Sort A to Z"]}
          >
            <IconsButton onClick={toggleSortOrder}>
              {sortOrder === "asc" ? <SortZtoAIcon /> : <SortAtoZIcon />}
            </IconsButton>
          </TooltipMUI>
          <SearchForm onSubmit={onSearchSubmitHandle}>
            <label>
              <SearchIcon />
              <input
                type="text"
                name="searchInput"
                placeholder="Search Patient"
                onChange={(e) => setCurrentInput(e.target.value)}
                value={currentInput}
              />
              <ClearButton
                type="button"
                onClick={handleClearInput}
                className={
                  currentInput.length > 0 ? "clear-button-visible" : ""
                }
              >
                <DeleteIcon />
              </ClearButton>
            </label>
          </SearchForm>
          <TooltipMUI title={["Refresh data"]}>
            <IconsButton onClick={manualRefresh}>
              <Refresh />
            </IconsButton>
          </TooltipMUI>
          <TooltipMUI title={["Change view"]}>
            <IconsButton onClick={onShowTimeline}>
              <AlignHorizontalLeft />
            </IconsButton>
          </TooltipMUI>
        </SearchContainer>

        <PatientsList ref={patientsListRef}>
          {!error && (
            <>
              {patients?.length > 0 ? (
                patients.map((patient, index) => (
                  <PatientCard
                    key={index}
                    patient={patient}
                    onSelectPatient={() => setSelectedPatient(patient)}
                    isSelected={patient.recordId === selectedPatient?.recordId}
                  />
                ))
              ) : initialPatientsLoading ? (
                <LoadingDotsContainer>
                  <LoaderDots />
                </LoadingDotsContainer>
              ) : (
                <p>No patients found</p>
              )}

              {patientsLoading && !initialPatientsLoading && <LoaderDots />}
            </>
          )}
          <MarkerElement ref={markerRef} />
        </PatientsList>
      </PatientListWithSearchContainer>

      <PatientDataContainer
        className={initialPatientsLoading ? "loading-patients" : ""}
      >
        <MainHeader>
          <Tabs>
            <button
              className={activeTab === "history" ? "active-tab" : ""}
              onClick={() => setActiveTab("history")}
            >
              History
            </button>
            <button
              className={activeTab === "cases" ? "active-tab" : ""}
              onClick={() => setActiveTab("cases")}
            >
              Cases
            </button>
          </Tabs>
        </MainHeader>
        {initialPatientsLoading ? (
          <Loader />
        ) : error ? (
          <ErrorBox>
            <ErrorMessage>{error}</ErrorMessage>
          </ErrorBox>
        ) : (
          <>
            {location && selectedPatient && (
              <PatientDetails
                location={location}
                patient={selectedPatient}
                activeTab={activeTab}
              />
            )}
            {!selectedPatient && (
              <NoDataCard
                title={`No Patient is selected in ${activeTab}`}
                message={`Please select a patient from the list for ${activeTab}.`}
              />
            )}
          </>
        )}
      </PatientDataContainer>
    </PatientsContainer>
  );
};

export default PatientList;
