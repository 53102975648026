import styled from "styled-components";
import { colors, media } from "../../theme";

export const MainLayout = styled.main`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 30px 10%;

  & h1 {
    text-align: center;
    border-bottom: 2px solid ${colors["orange-1"]};
    padding-bottom: 10px;
  }

  & h2 {
    text-align: center;
  }

  & span {
    display: block;
    margin: 7px 0;
  }

  & ul,
  ol {
    margin-top: 0;
  }

  & ul li,
  ol li {
    padding: 5px 0;
  }

  & .discList {
    list-style-type: disc;
  }

  & .lowerRomanList {
    list-style-type: lower-roman;
  }

  & .upperAlphabeticList {
    list-style-type: upper-alpha;
  }

  & .pWithSpan {
    margin-bottom: 3px;
  }

  ${media.tablet} {
    padding: 10px 25px;

    & h1 {
      font-size: 27px;

      padding-bottom: 5px;
    }

    & h2 {
      font-size: 20px;
    }

    & h3,
    p,
    h2 {
      margin-bottom: 0;
    }

    & ul,
    ol {
      padding-left: 30px;
      margin: 0;
    }

    & span {
      margin: 5px 0;
    }
  }

  ${media.phoneL} {
    padding: 10px 15px;

    & h1 {
      font-size: 23px;
    }

    & h2 {
      font-size: 18px;
    }

    & h3 {
      font-size: 16px;
    }

    & p,
    li,
    span {
      font-size: 14px;
    }

    & ul,
    ol {
      padding-left: 25px;
    }

    & ul li,
    ol li {
      padding: 2px 0;
    }

    & span {
      margin: 0;
    }

    & .pWithSpan {
      margin-bottom: 0;
    }
  }

  ${media.phoneS} {
    padding: 10px;

    & h1 {
      font-size: 21px;
    }

    & h2 {
      font-size: 16px;
    }

    & h3 {
      font-size: 15px;
    }

    & p,
    li,
    span {
      font-size: 13px;
    }

    & ul,
    ol {
      padding-left: 20px;
    }
  }
`;

export const TimeSpan = styled.span`
  background-color: yellow;
  font-weight: 600;
  width: fit-content;
  padding: 2px 5px;
  align-self: flex-end;
`;
