import React, { useState, useEffect } from "react";
import { PlanCardContainer, PlanInfo } from "./PlanCard.styled";
import useAuthService from "../../../services/authService";

interface PlanCardProps {}

const PlanCard: React.FC<PlanCardProps> = () => {
  const { getLocationPlan } = useAuthService();
  const [paymentStatus, setPaymentStatus] = useState<string | null>(null);

  useEffect(() => {
    const checkPaymentStatus = async () => {
      const status = await getLocationPlan();
      setPaymentStatus(status);
    };
    checkPaymentStatus();
  }, [getLocationPlan]);

  const isFreeUser = paymentStatus === "Free";

  return (
    <PlanCardContainer>
      <PlanInfo>
        {isFreeUser ? (
          <li>Dexis IS Connect Cloud Free</li>
        ) : (
          <li>Dexis IS Connect Cloud Premium</li>
        )}
      </PlanInfo>
    </PlanCardContainer>
  );
};

export default PlanCard;
