import React, { useMemo } from "react";
import { IPatient } from "../../../../services/api/models";
import {
  PatientCardContainer,
  PatientNameContainer,
  PatientInfo,
  PatientId,
  LabelSpan,
  InfoSpan,
  PatientAvatar,
} from "./PatientCard.styled";

interface PatientCardProps {
  patient: IPatient;
  onSelectPatient: (selectedPatient: IPatient) => void;
  isSelected: boolean;
}

const PatientCard: React.FC<PatientCardProps> = ({
  patient,
  onSelectPatient,
  isSelected,
}) => {
  const handleClick = () => {
    onSelectPatient(patient);
  };

  const formatDate = (date: string | any[]) => {
    if (!date) {
      return "Not available";
    }

    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);

    return `${day}-${month}-${year}`;
  };

  const formatGender = (gender: string) => {
    if (!gender) {
      return "Not available";
    }

    switch (gender) {
      case "F":
        return "Female";
      case "M":
        return "Male";
      case "O":
        return "Other";
      default:
        return "Not available";
    }
  };

  const patientFullName = useMemo(() => {
    const fullName = `${patient.lastName || ""} ${
      patient.firstName || ""
    }`.trim();
    return fullName !== "" ? fullName : "Not available";
  }, [patient]);

  const patientInitials = useMemo(() => {
    const initials = ((patient.lastName && patient.lastName[0]) || "") + ((patient.firstName && patient.firstName[0]) || "");
    return initials.toUpperCase() || "?";
  }, [patient]);

  return (
    <PatientCardContainer
      onClick={handleClick}
      className={isSelected ? "selected_patient" : ""}
    >
      <PatientNameContainer>
        <PatientAvatar>{patientInitials}</PatientAvatar>
        <span>{patientFullName}</span>
      </PatientNameContainer>
      <PatientInfo>
        <div>
          <LabelSpan>Date of Birth</LabelSpan>
          <InfoSpan>
            {patient.dateOfBirth === ""
              ? "Not available"
              : formatDate(patient.dateOfBirth)}
          </InfoSpan>
        </div>
        <div>
          <LabelSpan>Gender</LabelSpan>
          <InfoSpan>{formatGender(patient.gender)}</InfoSpan>
        </div>
      </PatientInfo>
      <PatientId>
        <InfoSpan>ID: {patient.patientId}</InfoSpan>
      </PatientId>
    </PatientCardContainer>
  );
};

export default PatientCard;
