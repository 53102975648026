import styled from "styled-components";
import { Menu } from "@mui/material";
import { colors, media } from "../../../theme";

export const StyledContainer = styled(Menu)`
  backdrop-filter: blur(3px);

  & ul[role="menu"] {
    padding: 0;
  }

  ${media.laptop} {
    backdrop-filter: blur(1px);
  }

  ${media.tablet} {
    backdrop-filter: blur(2px);
  }
`;

export const MainBox = styled.div`
  display: flex;
  width: 97vw;
  max-width: 1200px;
  height: 650px;
  padding: 0;
  overflow: hidden;
`;

export const PopupArrow = styled.div`
  position: fixed;
  top: 30px;
  right: 100px;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  background-color: white;
`;

export const UserContainer = styled.div`
  width: 100%;
  padding-bottom: 10px;

  ${media.laptop} {
    padding: 14px;
  }

  ${media.tablet} {
    padding: 10px;
  }
`;

export const UserListParent = styled.div`
  height: calc(100% - 100px);

  overflow: auto;

  & table {
    width: 100%;
    border-spacing: 12px 10px;

    & th {
      font-weight: 500;
    }

    & tbody tr {
      margin-bottom: 10px;
    }

    & tbody tr > td {
      text-align: center;

      & span {
        font-size: 14px;
      }

      ${media.laptop} {
        & span {
          font-size: 14px;
        }
      }

      ${media.tablet} {
        font-size: 14px;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors["gray-4"]};
    border-radius: 10px;
  }
`;

export const PopupAddUser = styled.div`
  width: 15px;
  height: 15px;
  position: absolute;
  top: -7px;
  right: 50px;
  transform: rotate(45deg);
  background-color: ${colors["background-grey-light-color"]};
`;

export const PopupWrapper = styled.div`
  width: 100%;
  padding: 32px;
  border-radius: 4px;
  background: white;
  position: relative;
  z-index: 998;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 6px;
  right: 20px;
  z-index: 30;
  padding: 0;
  border: none;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }
`;

export const SidebarBox = styled.div`
  display: flex;
  height: 100%;
  background-color: ${colors["gray-2"]};
  width: 20%;
  min-width: 250px;
  flex-direction: column;
  justify-content: space-around;

  ${media.laptop} {
    min-width: 200px;
  }
`;

export const FirstSidebarDiv = styled.div`
  width: 100%;
  & .menu-practice {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0;
    font-size: 24px;
    margin-top: 0.5px;
    color: ${colors["gray-10"]};
  }

  & .menu-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
    color: ${colors["gray-10"]};
    &.active {
      background-color: ${colors["gray-4"]};
    }

    &.inactive {
      color: ${colors["gray-5"]};
      cursor: auto;
    }
    &.inactive svg {
      fill: ${colors["gray-5"]};
    }
  }

  & .menu-item svg {
    min-width: 36px;
    fill: ${colors["gray-7"]};
    font-size: 28px;
  }
  ${media.laptop} {
    & .menu-practice {
      font-size: 20px;
    }

    & .menu-item {
      padding: 8px 8px;
      font-size: 16px;
    }

    & .menu-item svg {
      min-width: 30px;
      font-size: 24px;
    }
  }
`;

export const FirstSidebarSecondSubDiv = styled.div`
  flex-grow: 1;

  & .menu-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    cursor: pointer;
    color: ${colors["gray-10"]};
    &.active {
      background-color: ${colors["gray-4"]};
    }
  }
`;

export const SubDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & .user-info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  ${media.laptop} {
    padding: 0px;
    & .user-info {
      margin-right: 20px;
      font-size: 16px;
    }
  }
`;

export const SecondSidebarDiv = styled.div`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;

  & .divider {
    width: 225px;
    background-color: ${colors["gray-4"]};
    height: 0;
    margin-top: 20px;
    margin-left: 12px;
  }
  & .menu-item {
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
    color: ${colors["gray-10"]};
    &.active {
      background-color: ${colors["gray-4"]};
    }
  }

  & .menu-item-version {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  & .menu-item svg {
    margin-right: 10px;
    min-width: 36px;
    fill: ${colors["gray-7"]};
    font-size: 28px;
  }

  & .menu-item-version.active {
    background-color: ${colors["gray-4"]};
  }

  & .menu-item-version:not(.active) {
    background-color: ${colors["gray-2"]};
  }

  & .menu-item-version p {
    margin-left: 10px;
  }
  ${media.laptop} {
    & .divider {
      width: 180px;
      margin-left: 10px;
    }

    & .menu-item {
      font-size: 16px;
    }

    & .menu-item svg {
      min-width: 30px;
      font-size: 24px;
    }

    & .menu-item-version p {
      margin-left: 8px;
    }
  }
`;

export const ContentBox = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;
`;

export const BaseTabHeader = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 4% 0 4%;
  height: 100px;
  font-size: 20px;
  border-bottom: 1px solid ${colors["gray-4"]};

  & span {
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${colors["gray-10"]};
    margin-top: 25px;
  }

  & span:last-child {
    cursor: pointer;
  }

  ${media.laptop} {
    & span {
      font-size: 18px;
    }
  }
`;

export const UserSub2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 2px;
  cursor: pointer;
  margin-top: 30px;

  &:hover {
    color: ${colors["orange-1"]};
  }
  ${media.laptop} {
    margin-top: 30px;
    margin-right: 200px;
    font-size: 14px;

    &:hover {
      color: ${colors["orange-1"]};
    }
  }
`;

export const UserTabSecondDiv = styled.div`
  margin-top: 5px;
  background-color: white;
  overflow-y: auto;

  ${media.laptop} {
    max-height: 500px;
    overflow-y: hidden;
  }
  ${media.tablet} {
    max-height: 400px;
  }
`;

export const AppsContainer = styled.div`
  width: 100%;
  padding-bottom: 10px;

  ${media.tablet} {
    padding: 10px;
  }
`;

export const AppListParent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
  margin: 0 4% 0 4%;
`;

export const AppItem = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;

  & span {
    font-weight: 500;
  }

  &:hover {
    background-color: ${colors["gray-4"]};
    border-radius: 2px;
    cursor: pointer;
  }

  :last-child {
    margin-left: auto;
  }
`;

export const AppIcon = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 10px;
`;

export const CustomParagraph = styled.p`
  margin-left: 16px;
`;

export const CustomParagraph3 = styled.p`
  margin-left: 10px;
`;

export const StyledMenuItem = styled.div`
  background-color: ${(props: any) => (props.isActive ? "blue" : "gray")};
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${(props: any) =>
      props.isActive ? "darkblue" : "darkgray"};
  }
`;

export const UserLogo = styled.div`
  border: 2px solid ${colors["gray-7"]};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors["gray-7"]};
  color: white;

  & span.initials-text {
    font-size: 20px;
    padding: 0;
    margin: 0;
    color: white;
  }

  ${media.laptop} {
    width: 40px;
    height: 40px;
  }
`;

export const CustomH2 = styled.h2`
  margin-top: -10px;
  font-size: 22px;

  ${media.laptop} {
    padding: 10px;
    font-size: 20px;
  }

  ${media.tablet} {
    font-size: 18px;
  }
`;

export const CrossButton = styled.div`
  color: ${colors["gray-15"]};
  transition: color 0.5s ease-in-out;
  margin-right: 25px;
  cursor: pointer;

  &:hover {
    color: ${colors["orange-1"]};
  }

  & svg {
    height: 14px;
    width: 14px;
  }

  ${media.laptop} {
    transform: scale(1);
  }

  ${media.tablet} {
    transform: scale(0.8);
  }
`;
export const VersionMainDiv = styled.div`
  display: flex;
  align-items: center;
  text-align: center;

  ${media.laptop} {
    padding: 30px;
  }

  ${media.tablet} {
    padding: 20px;
  }
`;
export const VersionContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px;
  margin: auto;

  & img {
    width: 95%;
    max-width: 400px;
  }

  & .versionDialogContent > div {
    justify-content: center;
    margin-top: 20%;
  }

  & hr {
    margin: 20px 0;
  }

  & span {
    display: block;
    font-size: 15px;
    color: ${colors["gray-7"]};
  }

  & p {
    text-align: center;
    font-size: 11px;
    color: ${colors["gray-10"]};
    margin-top: 35px;
  }
`;

export const VersionImage = styled.div`
  width: 250px;
  height: 50px;
`;
