import styled from "styled-components";
import { colors, media } from "../../../theme";

export const PracticeCardContainer = styled.article`
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
  padding: 10px 10px 10px 40px;
  -webkit-filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
  filter: drop-shadow(0px 2px 8px rgba(141, 140, 140, 0.32));
  height: 200px;
  width: 320px;
  margin: auto;
  border: 0px solid ${colors["gray-5"]};

  ${media.tablet} {
    padding: 10px 25px;
    height: 250px;
  }

  ${media.phoneL} {
    padding: 10px 15px;
    height: 230px;
  }

  ${media.phoneS} {
    padding: 10px;
    height: 190px;
  }
`;

export const PracticeCardHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  ${media.tablet} {
    font-size: 20px;
    padding: 10px;
  }

  ${media.phoneL} {
    font-size: 15px;
    padding: 5px;
  }

  ${media.phoneS} {
    font-size: 10px;
    padding: 2px;
  }
`;

export const PracticeInfo = styled.ul`
  list-style: none;
  line-height: 50px;
  padding: 0;
  margin-top: 40px;
  color: ${colors["gray-10"]};
  overflow: auto;

  & li {
    text-wrap: nowrap;
    margin-right: 20px;
  }

  ${media.tablet} {
    font-size: 18px;
  }

  ${media.phoneL} {
    font-size: 16px;
  }

  ${media.phoneS} {
    font-size: 14px;
  }

  li:first-child {
    font-size: 40px;
  }

  li:last-child {
    font-size: 24px;
    margin-top: 26px;
  }
`;
