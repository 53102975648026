import styled from "styled-components";
import { colors, media } from "../../../theme";

export const TimelineContainer = styled.main`
  box-sizing: border-box;
  height: calc(100vh - 116px);
  margin: 30px 15px 5px 15px;
  padding: 10px 20px;
  background-color: ${colors["background-grey-light-color"]};
  border-radius: 8px;

  ${media.tablet} {
    height: calc(100vh - 100px);
  }
`;

export const SearchForm = styled.form`
  box-sizing: border-box;
  position: relative;
  width: 100%;

  & label {
    display: flex;
    width: 100%;
    height: 35px;
    margin-left: 5px;

    &:focus-within {
      outline: 1px solid ${colors["orange-1"]};
    }
  }

  & input {
    width: 100%;
    min-width: 160px;
    text-indent: 45px;
    border: none;
    outline: none;

    &::placeholder {
      color: ${colors["gray-7"]};
      text-indent: 50px;
    }
  }

  & svg {
    position: absolute;
    top: 8px;
    left: 25px;
    fill: ${colors["gray-7"]};
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & button {
    width: 30px;
    height: 30px;
    padding: 0;
  }

  & button:nth-of-type(2) svg {
    width: 18px;
  }
`;

export const ContentHeader = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 30px;

  & h1 {
    justify-self: center;
    margin: 0;
    font-size: 24px;
    font-weight: 700;
  }
`;

export const MainView = styled.div`
  box-sizing: border-box;
  display: grid;
  height: calc(100% - 75px);
  overflow-y: scroll;
  padding-bottom: 20px;

  &::-webkit-scrollbar {
    width: 3px;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 20px;
    background: ${colors["background-grey-light-color"]};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors["orange-1"]};
    border-radius: 20px;
    height: 50%;
  }

  & .vertical-timeline {
    width: 75%;
    height: min-content;
    padding: 0;

    &::before {
      height: 100%;
    }
  }

  & .vertical-timeline > div > div > p {
    position: relative;
    width: fit-content;
    top: 50px;
    right: 79px;
  }

  & .vertical-timeline > div > span {
    right: 86px;
    top: 82px;
    position: relative;
    font-weight: 600;
  }

  & .vertical-timeline-element-icon {
    background-color: ${colors["orange-1"]};
    height: 15px;
    width: 15px;
    left: 12px;
  }

  & .vertical-timeline-element-content {
    background-color: white;
    color: white;
    display: flex;
    flex-direction: column;
    margin-bottom: -24px;

    & > span {
      color: black;
      font-weight: 500;
      margin-left: 3%;
    }
  }

  & .vertical-timeline-element-content-arrow {
    border-right: 7px solid white;
  }

  & .vertical-timeline-element-date {
    display: none;
  }
`;