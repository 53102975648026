import React from "react";

const EyeIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="13"
      viewBox="0 0 23 13"
    >
      <path d="M0,6.558a13.362,13.362,0,0,1,23,0,13.351,13.351,0,0,1-11.5,6.558A13.352,13.352,0,0,1,0,6.558Z" />
      <path
        d="M3.268,0A3.268,3.268,0,1,0,6.537,3.268,3.268,3.268,0,0,0,3.268,0m0-2A5.268,5.268,0,1,1-2,3.268,5.274,5.274,0,0,1,3.268-2Z"
        transform="translate(8.2 3.2)"
        fill="#fff"
      />
    </svg>
  );
};

export default EyeIcon;
