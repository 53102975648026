import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { colors } from "../../../theme";

const themeColors: { [key: string]: string } = {
  light: "white",
  gray: colors["gray-4"],
  dark: colors["gray-10"],
};

const ToBeStyledTooltip = ({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
);

export const StyledTooltip = styled(ToBeStyledTooltip)<{
  fontSize: number;
  themeColor: "light" | "gray" | "dark";
  isVisible: boolean;
}>(({ fontSize, themeColor, isVisible }) => ({
  display: isVisible ? "" : "none",
  backgroundColor: themeColors[themeColor],
  color: themeColor === "dark" ? "white" : "black",
  border: `1px solid ${
    themeColor === "dark" ? colors["gray-12"] : colors["gray-5"]
  }`,
  borderRadius: "2px",
  fontSize: `${fontSize}px`,
  letterSpacing: "0.5px",
  boxShadow: `1px 1px 5px ${colors["gray-5"]}`,
  paddingInlineStart: "11px",
  padding: fontSize <= 11 ? "2px 5px" : "4px 10px",

  [`& .${tooltipClasses.arrow}`]: {
    color: themeColors[themeColor],

    "&:before": {
      border: `1px solid ${
        themeColor === "dark" ? colors["gray-12"] : colors["gray-5"]
      }`,
    },
  },
}));
