import styled from "styled-components";
import { colors } from "../../../theme";

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;

  & > img {
    height: 70px;
    margin: 20px;
  }

  & .error {
    color: ${colors["error-red-text"]};
  }

  & .main-content {
    width: -webkit-fill-available;
    margin: 0 20px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const Title = styled.h1`
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
`;

export const TreatmentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 10px;
  margin-top: 15px;
`;

export const TreatmentCard = styled.div<{ $length: number }>`
  display: flex;
  flex-direction: column;

  & > span {
    display: block;
    width: 100%;
    text-align: center;
    font-family: Arial;
    font-size: 12px;
    line-height: 14px;
    color: ${colors["gray-14"]};
    margin-top: 5px;
  }

  & img {
    width: ${({ $length }) => ($length > 12 ? "38" : "82")}px;
    height: ${({ $length }) => ($length > 12 ? "28" : "62")}px;
  }
`;

export const TreatmentButton = styled.button<{ $isSelected: any }>`
  background: white;
  border: 1px solid
    ${({ $isSelected }) => ($isSelected ? "#009BB4" : "#AFAFAF")};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100%;
  outline: none;
  cursor: pointer;
  position: relative;
  box-shadow: ${({ $isSelected }) =>
    $isSelected ? `0 0 0 2px ${colors["login-blue"]} ` : "none"};

  & > div {
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #39c48f;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & span {
    color: white;
    font-size: 12px;
  }
`;
