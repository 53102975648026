import styled from "styled-components";
import { colors } from "../../../theme";

export const StyledRXCard = styled.div`
  position: relative;
  display: inline-flex;
  gap: 5px;
  background-color: white;
  border-radius: 8px;
  padding: 0 8px;
  overflow-x: auto;
  box-shadow: inset -5px 0 10px white;
  height: 190px;
  overscroll-behavior: contain;

  &::-webkit-scrollbar {
    height: 3px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    margin: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors["orange-1"]};
    border-radius: 10px;
  }

  &::before,
  &::after {
    position: sticky;
    top: 0;
    left: 0;
    margin-right: 5px;
    background-color: white;
    height: 90%;
    min-width: 1px;
    box-shadow: -5px 0 8px 12px white;
    z-index: 1;
  }

  &::before {
    left: 0;
    margin-right: 5px;
    box-shadow: -5px 0 8px 12px white;
  }

  &::after {
    content: "";
    position: sticky;
    top: 0;
    right: -8px;
    margin-left: 10px;
    background-color: white;
    height: 90%;
    min-width: 1px;
    box-shadow: 0 0 10px 15px white;
  }
`;
