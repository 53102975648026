import styled from "styled-components";
import { colors } from "../../theme";

export const OrangeButton = styled.button`
  width: 100px;
  height: 50px;
  padding: 4px 10px;
  color: white;
  background-color: ${colors["orange-1"]};
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s ease;

  &:disabled {
    color: white;
    background-color: ${colors["gray-5"]};
    cursor: not-allowed;
  }
`;

export const IconsButton = styled.button`
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin: 0;
  padding: 5px;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  & svg {
    width: 24px;
    height: 24px;
    fill: ${colors["gray-6"]};
  }

  &:hover svg {
    fill: ${colors["orange-1"]};
  }

  &:active svg {
    fill: ${colors["gray-10"]};
  }
`;

export const ClearButton = styled(IconsButton)`
  position: absolute;
  display: none;
  right: 0px;
  padding: 0;

  &.clear-button-visible {
    display: block;
    height: 35px;
    display: flex;
    align-items: center;

    & > svg {
      position: static;
      width: 15px;
      height: 15px;
    }
  }
`;
