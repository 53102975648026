export interface IUser {
  oid: string;
  emailAddress: string;
  firstName: string;
  surname: string;
  recordId: string;
  isAllowed: boolean;
}

export enum PlanTransactionTypes {
  FreeTrial = 0,
  PaidPlan = 1,
}

export interface ICreationLocation {
  name: string;
  country: string;
}

export interface ILocationListResult {
  "@odata.count": number;
  value: ILocation[];
}

export interface ILocation {
  name: string;
  country: string;
  accountId: string;
  regionalLocationId: string;
  recordId: string;
  recordStatus: string;
  recordStatusValue: number;
  recordCreated: string;
  recordLastUpdated: string;
  cloudTieringEnabled: boolean;
  account: {
    region: {
      apiUrl: string;
    };
  };
  userLocations: [
    {
      locationId: string;
      userId: string;
      role: string;
      user: {
        recordId: string;
        emailAddress: string;
        firstName: string;
        surname: string;
      };
    }
  ];
  planTransactions: [
    {
      userId: string;
      locationId: string;
      orderId: string | null;
      transactionType: PlanTransactionTypes;
      startDate: string;
      expiresOn: string;
      comment: string;
      price: number | null;
      currency: string;
      productId: string | null;
      location: string;
      recordId: string;
      recordStatus: string;
      recordStatusValue: string;
      recordCreated: string;
      recordLastUpdated: string;
    }
  ];
}

export interface ICreationLocation {
  name: string;
  country: string;
}

export interface IPatient {
  patientId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: string;
  gender: string;
  recordId: string;
  cases: ICaseInfo[];
  recordCreated: string;
}

export interface IDicomSeries {
  thumbnailUrl: string;
  modality: string;
  seriesInstanceUid: string;
  recordCreated: string;
  recordLastUpdated: string;
  recordId: string;
  seriesDateTime: string;
  acquisitionDateTime: string;
  acquisitionDateUtc: string | null;
  lastName: string;
  firstName: string;
  sopInstanceUid: string;
  studyInstanceUid: string;
  patientId: string;
  scanMode: string;
  caseType: string;
  scanObject: string;
  deviceModelName: string;
  deviceSerialNumber: string;
}

export enum BlobFileType {
  Other = "Other",
  Prescription_pdf = "Prescription_pdf",
  Prescription_json = "Prescription_json",
  ShadeReport_pdf = "ShadeReport_pdf",
  QuadrantSnapshotReport_pdf = "QuadrantSnapshotReport_pdf",
}

export interface IBlobFile {
  fileName: string;
  fileType: BlobFileType;
  filesize: string;
  recordId: string;
  recordCreated: string;
  recordLastUpdated: string;
  seriesInstanceUid: string;
  firstName: string;
  lastName: string;
  patientId: string;
  fileLastModifiedDateTime: string;
  thumbnailUrl: string;
}

export interface IPatientFiles {
  recordId: string;
  fileType?: BlobFileType;
  fileLastModifiedDateTime?: string;
  recordLastUpdated: string;
  acquisitionDateTime?: string;
  seriesInstanceUid: string;
}

export interface IRX {
  seriesInstanceUid: string;
  dicom: IDicomSeries;
  attachedFiles: IBlobFile[];
}

export interface ICreationUser {
  userEmail: string;
  locationId: string;
}

export interface IPartner {
  partner_id: string;
  partner_pk: string;
  partner_name: string;
  partner_logo: string;
  partner_email: string;
  partner_type: "laboratory" | "thirdparty";
  premium: boolean;
  software_name: "csc_agent" | "web_indexer";
  paired: boolean;
  indexation_url: string;
}

export interface ICaseCreation {
  series_id: string;
  recipient: string;
  partner_type: "laboratory" | "thirdparty";
  due_date: string;
  submission_comment: string;
  user_attachments: IUserAttachments[];
}

export interface ICaseInfo {
  caseId: string;
  status: number;
  statusDate: string;
  submissions: Submission[];
  seriesInstanceUid: string;
  laboratory: {
    email: string;
    logo: string;
    laboratoryName: string;
    laboratoryType: number;
  };
  caseFiles?: ICaseFile[];
}

export interface ICase {
  rx: IRX;
  caseInfo: ICaseInfo | null;
  caseFiles?: ICaseFile[];
}

export interface ICaseFile {
  id: number;
  casePk: number;
  filePath: string;
  fileSize: number;
  fileType: number;
  uploadStatus: number;
  caseFiles?: ICaseFile[];
}

export interface Submission {
  cdate: string;
  dueDate: any;
}

export interface IBlobFileInfo {
  locationId: string;
  userOid: string;
  fileName: string;
  fileType: string;
  fileSize: number;
  fileLastModifiedDateTime: string;
  studyInstanceUid: string;
  seriesInstanceUid: string;
  patient: {
    patientId: string;
    firstName: string;
    lastName: string;
  };
}

export interface ICaseRecord {
  case_uid: string | null;
  case_path: string | null;
  case_date: string | null;
  case_type: string | null;
  scan_mode: string | null;
  scan_object: string | null;
  device_sn: string | null;
  pt_pid: string | null;
  pt_last_name: string | null;
  pt_middle_name: string | null;
  pt_first_name: string | null;
  pt_birthdate: string | null;
  pt_sex: string | null;
  recipient: string | null;
  node_id: number;
  partner_case_id: number | undefined;
  is_refinement_case: boolean;
  treatment_id: number | null;
  transaction_id: string | null;
  software_name: string | null;
  software_version: string | null;
  model_name: string | null;
}

export interface IConnectUser {
  userPk: number;
  email: string;
  firstName: string;
  lastName: string;
}

export interface IDentalCase {
  casePk: number;
  userPk: number;
  labPk: number;
  status: number;
  caseId: string;
  caseUid: string;
  caseType: string;
  caseDate: string;
  caseTime: string;
  transactionId: string;
}

export interface ISparkCase {
  caseId: number;
  firstName: string;
  lastName: string;
  middleName: string;
  orderIndicator: string;
  status: string;
}

export interface IDentalCaseAnswer {
  queryable: IDentalCase[];
}

export interface ISprintRayTreatmentType {
  id: number;
  name: string;
  imagePath: string;
}

export interface IBaseCaseDetails {
  scan_mode: string | null;
  scan_object: string | null;
  device_sn: string | null;
  pt_pid: string | null;
  pt_last_name: string | null;
  pt_middle_name: string | null;
  pt_first_name: string | null;
  pt_birthdate: string | null;
  pt_sex: string | null;
  transaction_id: string | null;
  software_name: string | null;
  software_version: string | null;
  model_name: string | null;
  case_uid: string | null;
  case_date: string | null;
  case_type: string | null;
  acquisition_date: string | null;
}

export interface ICaseDetails extends IBaseCaseDetails {
  partner_pk: string | null;
  lang: string | null;
  token: string | null;
}

export interface ICaseRecord extends IBaseCaseDetails {
  case_path: string | null;
  recipient: string | null;
  node_id: number;
  partner_case_id: number | undefined;
  is_refinement_case: boolean;
  treatment_id: number | null;
  transaction_id: string | null;
}

export interface IDentalCaseStatus {
  casePk: number;
  status: number;
  caseId: string;
  partnerCaseDirectLink: string;
}

export interface IDentalCaseStatusAnswer {
  value: IDentalCaseStatus[];
}
export interface IDentalCaseStatus {
  casePk: number;
  status: number;
  caseId: string;
  partnerCaseDirectLink: string;
}

export interface IUserAttachments {
  fileName: string;
  fileSize: number;
  file: File;
}

export interface IFinalizedPayload {
  series_id: string;
  case_id: string;
  stored_attachments: IStoredAttachments[];
  partner_type: string;
}

export interface IStoredAttachments {
  fileName: string;
  recordId: string;
}
