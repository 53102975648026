import React from "react";
import { TermsOfUseLink, FooterContainer } from "./PageFooter.styled";

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <TermsOfUseLink to="/terms-of-use">Terms of Use</TermsOfUseLink>
      <p>
        &copy; Copyright Dental Imaging Technologies Corporation. All Rights
        Reserved.
      </p>
    </FooterContainer>
  );
};

export default Footer;
