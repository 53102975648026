import React, { Fragment } from "react";
import { ICase } from "../../../../../services/api/models";
import { StyledStatusIcon, StyledToolTip } from "./CaseStatus.styled";

interface IStatus {
  color: string;
  statusString: string;
}

interface CaseStatusProps {
  caseData: ICase;
  addDatesInfo?: boolean;
}

const getStatusStringAndColor = (status: number | undefined) => {
  let caseStatus: IStatus = {
    color: "#7D7E7E",
    statusString: "Unsubmitted",
  };

  switch (status) {
    case 0:
    case undefined:
      return caseStatus;
    case 1:
    case 5:
    case 11:
      caseStatus.color = "#6ECFF6";
      caseStatus.statusString = "Transferring";
      return caseStatus;
    case 12:
    case 13:
    case 15:
    case 16:
    case 95:
      caseStatus.color = "#7EA7D8";
      caseStatus.statusString = "Transferred";
      return caseStatus;
    case 14:
    case 21:
    case 22:
    case 23:
    case 30:
      caseStatus.color = "#40C140";
      caseStatus.statusString = "Consulted";
      return caseStatus;
    case 19:
      caseStatus.color = "#FF4E40";
      caseStatus.statusString = "Error";
      return caseStatus;
    case 91:
    case 92:
    case 93:
    case 96:
      caseStatus.color = "#FE9223";
      caseStatus.statusString = "Rejected";
      return caseStatus;
  }
};

const CaseStatus: React.FC<CaseStatusProps> = ({ caseData, addDatesInfo }) => {
  const formatDate = (dateString: string | null) => {
    if (!dateString) {
      return "N/A";
    }

    const date = new Date(dateString);
    return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date
      .getDate()
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  };

  const getTooltipContent = () => {
    const status = getStatusStringAndColor(caseData.caseInfo?.status)?.statusString;

    if (addDatesInfo && caseData.caseInfo) {
      return (
        <>
          <span>
            {status === "Consulted"
              ? `${status}: ${formatDate(caseData.caseInfo.statusDate)}`
              : status}
          </span>
          {caseData.caseInfo?.submissions?.map((ci, index) => (
            <Fragment key={index}>
              <span>{`Submission Date: ${formatDate(ci.cdate)}`}</span>
              <span>{`Due Date: ${formatDate(ci.dueDate)}`}</span>
            </Fragment>
          ))}
        </>
      );
    } else {
      return <span>{status}</span>;
    }
  };

  return (
    <>
      <StyledStatusIcon
        fontSize="large"
        color={`${getStatusStringAndColor(caseData.caseInfo?.status)?.color}`}
        data-tooltip-id={caseData.caseInfo?.caseId}
      />
      {caseData.caseInfo && (
        <StyledToolTip
          id={caseData.caseInfo.caseId}
          className="tooltip"
          place={caseData.caseInfo ? "bottom" : "top"}
        >
          {getTooltipContent()}
        </StyledToolTip>
      )}
    </>
  );
};

export default CaseStatus;
