import React from "react";
import countries from "i18n-iso-countries";
import { ILocation } from "../../../services/api/models";
import { PracticeCardContainer, PracticeInfo } from "./PracticeCard.styled";

interface PracticeCardProps {
  location: ILocation;
}

const PracticeCard: React.FC<PracticeCardProps> = ({ location }) => {
  return (
    <PracticeCardContainer>
      <PracticeInfo>
        <li>{location.name}</li>
        <li>{countries.getName(location.country, "en")}</li>
      </PracticeInfo>
    </PracticeCardContainer>
  );
};

export default PracticeCard;
