import styled from "styled-components";
import { IconsButton } from "../../common/buttons.styled";
import { Menu } from "@mui/material";
import { colors } from "../../../theme";

export const StyledIconsButton = styled(IconsButton)<{ $isMenuOpen: boolean }>`
  & svg {
    width: 18px;
    fill: ${(props) => props.$isMenuOpen && `${colors["gray-10"]}`};
  }
`;

export const StyledMenu = styled(Menu)`
  & div {
    border-radius: 0;
  }
`;
