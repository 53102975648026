import React from "react";
import { ICaseFile } from "../../../../services/api/models";
import { StyledCaseFileCard, FileName, FileInfo } from "./CaseFileCard.styled";
import TooltipMUI from "../../TooltipMUI/TooltipMUI";
import DEFIcon from "../../../../assets/images/fileTypeDEFAULT.png";
import DOCIcon from "../../../../assets/images/fileTypeDOC.png";
import IMGIcon from "../../../../assets/images/fileTypeIMG.png";
import DOCXIcon from "../../../../assets/images/fileTypeDOCX.png";
import ODSIcon from "../../../../assets/images/fileTypeODS.png";
import PDFIcon from "../../../../assets/images/fileTypePDF.png";
import PLYIcon from "../../../../assets/images/fileTypePLY.png";
import STLIcon from "../../../../assets/images/fileTypeSTL.png";
import TXTIcon from "../../../../assets/images/fileTypeTXT.png";
import XLSIcon from "../../../../assets/images/fileTypeXLS.png";
import XLSXIcon from "../../../../assets/images/fileTypeXLSX.png";
import ZIPIcon from "../../../../assets/images/fileTypeZIP.png";

const CaseFileCard: React.FC<{ caseFile: ICaseFile }> = ({ caseFile }) => {
  const fileName = caseFile.filePath.split(".").slice(0, -1).join(".");
  const match = caseFile.filePath.match(/\.([a-z0-9]{3,4})$/i);
  const fileExtension = match ? match[1].toLowerCase() : "";

  if (fileExtension === "dcm") {
    return null;
  }

  const getFileIcon = () => {
    switch (fileExtension) {
      case "doc":
      case "rtf":
        return DOCIcon;
      case "docx":
        return DOCXIcon;
      case "jpg":
      case "jpeg":
      case "png":
        return IMGIcon;
      case "ods":
      case "odt":
        return ODSIcon;
      case "pdf":
        return PDFIcon;
      case "ply":
        return PLYIcon;
      case "stl":
        return STLIcon;
      case "txt":
        return TXTIcon;
      case "xls":
        return XLSIcon;
      case "xlsx":
        return XLSXIcon;
      case "zip":
        return ZIPIcon;
      default:
        return DEFIcon;
    }
  };

  return (
    <TooltipMUI title={[fileName]} isStatic themeColor="dark" fontSize={11}>
      <StyledCaseFileCard>
        <img alt="Case file thumbnail" src={getFileIcon()} />
        <FileInfo>
          <FileName>{fileName}</FileName>
        </FileInfo>
      </StyledCaseFileCard>
    </TooltipMUI>
  );
};

export default CaseFileCard;
