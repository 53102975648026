import styled from "styled-components";
import { colors, media } from "../../theme";

export const SettingsHeader = styled.h2`
  display: flex;
  font-size: 28px;
  width: 100%;
  margin: 40px 0 60px 0;

  & svg {
    font-size: 30px;
    margin-left: 10px;
  }

  ${media.tablet} {
    font-size: 28px;
    display: inline-flex;

    & svg {
      font-size: 20px;
    }
  }

  ${media.phoneL} {
    font-size: 26px;

    & svg {
      font-size: 28px;
    }
  }

  ${media.phoneS} {
    font-size: 20px;
    margin: 20px 0 30px 0;

    & svg {
      font-size: 20px;
    }
  }
`;

export const LocationContainer = styled.div`
  display: flex;
  gap: 10%;

  ${media.tablet} {
    gap: 30px;
    align-items: center;
  }

  ${media.phoneL} {
    gap: 25px;
  }

  ${media.phoneS} {
    gap: 15px;
  }
`;

export const MainLayoutContainer = styled.main<{ $hasLocation: boolean }>`
  box-sizing: border-box;
  min-height: calc(100vh - 74px);
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-bottom: 15px;
  width: ${(props) => (props.$hasLocation ? "80%" : "100%")};

  ${media.tablet} {
    padding: 10px;
    min-height: calc(100vh - 65px);
  }

  ${media.phoneL} {
    padding: 8px;
  }

  ${media.phoneS} {
    padding: 5px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: calc(100vh - 74px);

  ${media.tablet} {
    min-height: calc(100vh - 65px);
  }
`;

export const StyledBox = styled.div`
  position: absolute;
  top: 32%;
  right: -70px;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 150px;
  background-color: ${colors["background-grey-light-color"]};
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 11px 50px -20px;
  border-color: white;

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${colors["orange-1"]};
  }

  & .MuiInputLabel-outlined.MuiInputLabel-shrink {
    color: black;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  & button {
    background-color: ${colors["orange-1"]};
    color: white;
    margin-right: 8px;
    margin-top: 9px;
    border-radius: 6px;
  }

  &:hover button {
    background-color: ${colors["orange-1"]};
  }

  & button:disabled {
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26);
    pointer-events: none;
  }

  ${media.laptop} {
    & button {
      margin-right: 5px;
      margin-top: 6px;
      padding: 7px 14px;
    }
  }
`;

export const ErrorMessage = styled.p`
  color: ${colors["error-red-text"]};
`;
