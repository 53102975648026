import { Link } from "react-router-dom";
import styled from "styled-components";
import { Menu } from "@mui/material";
import { colors, media } from "../../../theme";

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  padding: 0 10px;
  background-color: white;
  border-bottom: solid 1px ${colors["gray-2"]};
  transition: top 0.3s ease-in-out;
  box-sizing: border-box;

  ${media.tablet} {
    height: 40px;
  }

  ${media.phoneS} {
    padding: 0 5px;
  }
`;

export const HomeLinkLogo = styled(Link)`
  display: flex;
  align-items: center;
  gap: 13px;
  height: fit-content;
  margin-left: 15px;

  & img {
    height: 27px;
  }

  ${media.tablet} {
    margin-left: 0;
    & img {
      height: 24px;
    }
  }

  ${media.phoneL} {
    gap: 10px;

    & img:first-of-type {
      height: 22px;
    }

    & img:nth-of-type(2) {
      height: 18px;
    }
  }

  ${media.phoneS} {
    gap: 5px;

    & img:first-of-type {
      height: 19px;
    }

    & img:nth-of-type(2) {
      height: 17px;
    }
  }
`;

export const HeaderLocationName = styled.span`
  color: ${colors["gray-7"]};
  font-size: 15px;

  ${media.phoneL} {
    display: none;
  }
`;

export const ButtonsHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  gap: 7px;

  ${media.tablet} {
    gap: 5px;
    margin-right: 10px;
  }

  ${media.phoneS} {
    margin: 0;
  }
`;

export const UserAvatar = styled.div`
  transition: 0.5s ease;

  & .avatar-logo {
    width: 36px;
    height: 36px;
    background-color: ${colors["orange-1"]};
    transition: 0.5s ease;

    &:hover {
      background-color: ${colors["orange-2"]};
      cursor: pointer;
    }
  }

  & .active-menu.avatar-logo {
    background-color: ${colors["orange-2"]};
  }

  ${media.tablet} {
    & .avatar-logo {
      width: 30px;
      height: 30px;
      font-size: 15px;
    }
  }

  ${media.phoneS} {
    & .avatar-logo {
      width: 28px;
      height: 28px;
      font-size: 14px;
    }
  }
`;

export const MenuContainer = styled.div`
  margin-left: 20px;
  overflow: visible;
`;

export const StyledMenu = styled(Menu)`
  & > .MuiMenu-paper {
    overflow: visible;
    -webkit-filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
    margin-top: 12px;
    box-shadow: unset;
    border-radius: 0;
    width: 160px;
  }

  & > .MuiMenu-paper:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 14px;
    width: 10px;
    height: 10px;
    background-color: white;
    transform: translateY(-50%) rotate(45deg);
    z-index: 0;
  }

  & .version-webapp,
  .info-icon {
    color: black;
  }

  & .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
    background-color: transparent;
    color: ${colors["orange-1"]};
    & svg {
      color: ${colors["orange-1"]};
    }
  }

  ${media.phoneL} {
    & > .MuiMenu-paper {
      margin-top: 8px;
      width: 100vw;
      max-width: unset;
      margin-left: -16px;
      border-radius: 0;
      & li {
        justify-content: center;
      }
    }
    & > .MuiMenu-paper:before {
      display: none;
    }
  }
`;
