import styled from "styled-components";
import { colors } from "../../../theme";
import { OrangeButton } from "../../common/buttons.styled";
import { MenuItem } from "@mui/material";

export const DicomAppsButtonStyled = styled.button`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  fill: ${colors["gray-7"]};

  &:hover {
    fill: ${colors["orange-1"]};
    cursor: pointer;
  }
`;

export const ModalBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const ModalContent = styled.div`
  position: relative;
  width: 600px;
  height: 300px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & span {
    font-size: 18px;
    margin-bottom: 40px;
    font-weight: 600;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  font-size: 18px;
  top: 10px;
  right: 10px;
  color: ${colors["gray-6"]};
  background-color: transparent;
  border: none;
  &:hover {
    cursor: pointer;
  }
`;

export const LaunchControl = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 40px 0;
`;

export const LaunchedButton = styled(OrangeButton)`
  width: 180px;
`;

export const MenuItemStyled = styled(MenuItem)`
  display: flex;
  align-items: left;
  gap: 10px;
  border: none;

  & svg > path {
    fill: ${colors["gray-7"]};
  }

  &:hover {
    & svg > path {
      fill: ${colors["gray-9"]};
    }
  }
`;
