import { useLocation } from "react-router-dom";

type Dictionary = { [key: string]: string | null };

// Convert the query string paramters in to a dictionary
const urlSearchParamsToDictionary = (query: URLSearchParams) => {
  const dictionary: Dictionary = {};

  query.forEach((value, key) => {
    dictionary[key.toLowerCase()] = value;
  });

  return dictionary;
};

const storageKeyPrefix = "query.";

const setPersistedQueryParams = (key: string, value: string | null) => {
  if (value === null) {
    sessionStorage.removeItem(storageKeyPrefix + key);
  } else {
    sessionStorage.setItem(storageKeyPrefix + key, value);
  }
};

const usePersistedAppQueryParams = () => {
  const queryParams = urlSearchParamsToDictionary(
    new URLSearchParams(useLocation().search)
  );

  // List of query string keys suppported by the app
  //stopRedict : stop the redirection auto to the single avaiable document to sign
  const result = {
    embedded: null as string | null,
    token: null as string | null,
    transaction_id: null as string | null,
  };

  // Iterate the supported keys and get their value from the query string and store it
  // in session storage, or get their value from session storage
  for (const key of Object.keys(result)) {
    let k = key.toLowerCase();
    let value = queryParams[k];

    if (value) setPersistedQueryParams(key, value);
    else value = sessionStorage.getItem(storageKeyPrefix + key);

    (result as Dictionary)[key] = value;
  }

  return result;
};

export { usePersistedAppQueryParams as default, setPersistedQueryParams };
