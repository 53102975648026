import { ICaseCreation, IFinalizedPayload, IPartner } from "./models";
import http from "./http";

export async function getPartnersList(seriesId: string, regionBaseApi: string) {
  const url =
    regionBaseApi + `api/v1/Partners/GetPartners?seriesId=${seriesId}`;
  const { data } = await http.get<IPartner[]>(url);
  return data;
}

export async function createCase(
  newCase: ICaseCreation,
  regionBaseApi: string
) {
  const url = regionBaseApi + `api/v1/DicomSeries/CreateCase`;
  const { data } = await http.post(url, newCase);
  return data;
}

export async function finalizePartnerCase(
  regionBaseApi: string,
  seriesId: string,
  transactionId: string
) {
  const url =
    regionBaseApi +
    `api/v1/DicomSeries/FinalizePartnerCase?transactionUid=${transactionId}&seriesId=${seriesId}`;
  const { data } = await http.post(url);
  return data;
}

export async function finalizeDentalCase(
  regionBaseApi: string,
  finalizedCase: IFinalizedPayload
) {
  const url = regionBaseApi + `api/v1/DicomSeries/FinalizeDentalCase`;
  const { data } = await http.post(url, finalizedCase);
  return data;
}
