import React, { useState } from "react";
import { IconButton } from "@mui/material";
import {
  ErrorMessage,
  UserCardContainer,
  UserCardHeader,
  UsersList,
} from "./UsersCard.styled";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { ILocation } from "../../../services/api/models";
import { removeUserFromLocation } from "../../../services/api/locationService";

interface UsersCardProps {
  location: ILocation;
  userId: string;
  locationUsers: {
    role: string;
    user: {
      recordId: string;
      emailAddress: string;
    };
  }[];
  openForm: () => void;
  onRemoveUser: (
    users: {
      role: string;
      user: {
        recordId: string;
        emailAddress: string;
      };
    }[]
  ) => void;
}

const UsersCard: React.FC<UsersCardProps> = ({
  location,
  userId,
  locationUsers,
  openForm,
  onRemoveUser,
}) => {
  const [error, setError] = useState("");

  const handleRemoveUser = async (userIdToDelete: string) => {
    try {
      await removeUserFromLocation(location.recordId, userIdToDelete);
      const updatedUsers = locationUsers.filter(
        (user) => user.user.recordId !== userIdToDelete
      );
      onRemoveUser(updatedUsers);
      error && setError("");
    } catch (error: any) {
      console.error("Error removing user from location:", error);
      setError("Failed to remove user from location.");
    }
  };

  const adminUser = locationUsers.find(
    (user) => user.role === "LocationAdministrator"
  );
  const otherUsers = locationUsers.filter(
    (user) => user.role !== "LocationAdministrator"
  );

  return (
    <UserCardContainer>
      <UserCardHeader>
        <h2>Users</h2>
        {adminUser?.user.recordId === userId && (
          <IconButton onClick={openForm}>
            <PersonAddAlt1Icon />
          </IconButton>
        )}
      </UserCardHeader>
      <UsersList>
        {otherUsers.map((user) => (
          <li key={user.user.recordId}>
            {user.user.emailAddress}

            {adminUser?.user.recordId === userId && (
              <IconButton onClick={() => handleRemoveUser(user.user.recordId)}>
                <DeleteIcon />
              </IconButton>
            )}
          </li>
        ))}

        {adminUser && <li>{adminUser.user.emailAddress}</li>}
      </UsersList>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </UserCardContainer>
  );
};

export default UsersCard;
