import React, { useEffect, useState } from "react";
import {
  CloseButton,
  ContentBox,
  CrossButton,
  CustomH2,
  CustomParagraph,
  CustomParagraph3,
  FirstSidebarDiv,
  FirstSidebarSecondSubDiv,
  MainBox,
  PopupArrow,
  PopupAddUser,
  SecondSidebarDiv,
  SidebarBox,
  StyledContainer,
  SubDiv,
  UserContainer,
  UserListParent,
  UserLogo,
  VersionContainer,
  BaseTabHeader,
  AppsContainer,
  AppListParent,
  AppItem,
  AppIcon,
} from "./PopupMenu.styled";
import VersionIcon from "../../svg-icons/VersionIcon";
import DeleteIcon from "../../svg-icons/DeleteIcon";
import {
  MenuItem,
  Divider,
  ListItemIcon,
  Modal,
  TextField,
  Button,
} from "@mui/material";
import PracticeCard from "../../../pages/location-management/PracticeCard/PracticeCard";
import FeedbackIcon from "../../svg-icons/FeedbackIcon";
import SettingsIcon from "../../svg-icons/SettingsIcon";
import dexisLogoShadow from "../../../assets/images/dexis-logo-shadow.png";
import UserIcon from "../../svg-icons/UserIcon";
import FeedbackForm from "../FeedbackForm/FeedbackForm";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { ErrorMessage } from "../FeedbackForm/FeedbackForm.styled";
import {
  ButtonsContainer,
  StyledBox,
} from "../../../pages/location-management/index.styled";
import getLoadedConfig from "../../../services/appConfigService";
import { ICreationUser, ILocation } from "../../../services/api/models";
import validator from "validator";
import {
  addUserToUserLocations,
  removeUserFromLocation,
} from "../../../services/api/locationService";
import DownloadArrowIcon from "../../svg-icons/DownloadArrowIcon";
import DexisIcon from "../../../assets/images/iscloud-logo.png";
import AppsIcon from "../../svg-icons/AppsIcon";
import CardIcon from "../../svg-icons/CardIcon";
import PlanCard from "../../../pages/location-management/PlanCard/PlanCard";
import TooltipMUI from "../TooltipMUI/TooltipMUI";

interface PopupMenuProps {
  openMenu: boolean;
  setOpenMenu: (open: boolean) => void;
  currentLocation: ILocation | undefined;
  anchorElMenu: HTMLElement | null;
  userEmail: string;
}

const PopupMenu: React.FC<PopupMenuProps> = ({
  openMenu,
  setOpenMenu,
  currentLocation,
  anchorElMenu,
  userEmail,
}) => {
  const [activeMenu, setActiveMenu] = useState<
    | "practice"
    | "version"
    | "feedback"
    | "users"
    | "adduser"
    | "userlist"
    | "apps"
    | "plan"
  >("practice");
  const [isFormOpen, setIsFormOpen] = useState(false);
  const config = getLoadedConfig();
  const [locationUsers, setLocationUsers] = useState<
    {
      role: string;
      user: {
        recordId: string;
        emailAddress: string;
        firstName: string;
        surname: string;
      };
    }[]
  >([]);
  const [emailInput, setEmailInput] = useState("");
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null);
  const [error, setError] = useState("");
  const downloadBridgeUrl = getLoadedConfig().cloudBridgeInstallerUrl;

  useEffect(() => {
    if (currentLocation) {
      const users = currentLocation.userLocations.map((userLocation) => ({
        role: userLocation.role,
        user: {
          recordId: userLocation.user.recordId,
          emailAddress: userLocation.user.emailAddress,
          firstName: userLocation.user.firstName,
          surname: userLocation.user.surname,
        },
      }));
      setLocationUsers(users);
    }
  }, [currentLocation]);

  const handleMenuClick = () => {
    setOpenMenu(false);
  };

  const handleMenuItemClick = (item: typeof activeMenu) => {
    setActiveMenu(item);
  };

  const handleRemoveUser = async (userIdToDelete: string) => {
    try {
      await removeUserFromLocation(currentLocation?.recordId!, userIdToDelete);
      const updatedUsers = locationUsers.filter(
        (user) => user.user.recordId !== userIdToDelete
      );
      setLocationUsers(updatedUsers);
      error && setError("");
    } catch (error: any) {
      console.error("Error removing user from location:", error);
      setError("Failed to remove user from location.");
    }
  };

  const getInitialsFromName = (firstName: string, surname: string): string => {
    return `${firstName.charAt(0)}${surname.charAt(0)}`.toUpperCase();
  };

  const currentUser = locationUsers.find(
    (user) => user.user.emailAddress === userEmail
  );
  const isAdmin = currentUser?.role === "LocationAdministrator";
  const handleAddUserClick = async (locationId: string) => {
    try {
      const userData: ICreationUser = {
        userEmail: emailInput,
        locationId: locationId,
      };
      const newUserResponse = (await addUserToUserLocations(userData)).value[0];
      const newUser = {
        role: newUserResponse.role,
        user: {
          recordId: newUserResponse.userId,
          emailAddress: emailInput,
          firstName: "",
          surname: "",
        },
      };

      setLocationUsers((prevUsers) => [...prevUsers, newUser]);
      closeForm();
    } catch (error: any) {
      console.error("Error fetching users:", error);
      if (error.statusCode === 409)
        setError("This user is already associated to an existing practice.");
      else if (error.statusCode === 400)
        setError("This email is not registered as a DEXIS user.");
    }
  };

  const closeForm = () => {
    setIsFormOpen(false);
    setEmailInput("");
    setError("");
    setIsEmailValid(null);
  };

  const openForm = () => {
    setIsFormOpen(true);
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    error && setError("");
    const newEmail = e.target.value;
    setEmailInput(newEmail);
    setIsEmailValid(validator.isEmail(newEmail));
  };

  const handleDownload = (url: string) => {
    window.location.href = url;
  };

  return (
    <StyledContainer
      anchorEl={anchorElMenu}
      keepMounted={false}
      open={openMenu}
      onClose={handleMenuClick}
    >
      <PopupArrow />
      <MainBox>
        <CloseButton onClick={() => handleMenuClick()}>
          <DeleteIcon />
        </CloseButton>
        <SidebarBox>
          <FirstSidebarDiv>
            <p className="menu-practice">Practice Settings</p>

            <MenuItem
              className={`menu-item ${
                activeMenu === "practice" ? "active" : ""
              }`}
              onClick={() => handleMenuItemClick("practice")}
            >
              <ButtonsContainer>
                <SettingsIcon />
              </ButtonsContainer>

              <CustomParagraph>Practice</CustomParagraph>
            </MenuItem>

            <FirstSidebarSecondSubDiv>
              <TooltipMUI
                title={["You have to be an admin to access this item"]}
                isVisible={!isAdmin ? true : false}
              >
                <MenuItem
                  className={`menu-item ${
                    activeMenu === "users" ? "active" : ""
                  }
                ${!isAdmin ? "inactive" : ""}`}
                  onClick={() =>
                    isAdmin ? handleMenuItemClick("users") : null
                  }
                >
                  <SubDiv className="user-container">
                    <div className="user-info">
                      <ButtonsContainer>
                        <UserIcon />
                      </ButtonsContainer>
                      <CustomParagraph>Users</CustomParagraph>
                    </div>
                  </SubDiv>
                </MenuItem>
              </TooltipMUI>
            </FirstSidebarSecondSubDiv>
            <MenuItem
              className={`menu-item ${activeMenu === "apps" ? "active" : ""}`}
              onClick={() => handleMenuItemClick("apps")}
            >
              <ButtonsContainer>
                <AppsIcon />
              </ButtonsContainer>
              <CustomParagraph>Apps</CustomParagraph>
            </MenuItem>

            <TooltipMUI
              title={["You have to be an admin to access this item"]}
              isVisible={!isAdmin ? true : false}
            >
              <MenuItem
                className={`menu-item ${activeMenu === "plan" ? "active" : ""}
               ${!isAdmin ? "inactive" : ""}`}
                onClick={() => (isAdmin ? handleMenuItemClick("plan") : null)}
              >
                <ButtonsContainer>
                  <CardIcon />
                </ButtonsContainer>
                <CustomParagraph>Plan</CustomParagraph>
              </MenuItem>
            </TooltipMUI>
          </FirstSidebarDiv>

          <SecondSidebarDiv>
            <Divider className="divider" />
            <MenuItem
              className={`menu-item ${
                activeMenu === "feedback" ? "active" : ""
              }`}
              onClick={() => handleMenuItemClick("feedback")}
            >
              <ButtonsContainer>
                <FeedbackIcon />
              </ButtonsContainer>

              <CustomParagraph3>Feedback</CustomParagraph3>
            </MenuItem>
            <MenuItem
              className={`menu-item ${
                activeMenu === "version" ? "active" : ""
              }`}
              onClick={() => handleMenuItemClick("version")}
            >
              <ListItemIcon className="info-icon">
                <ButtonsContainer>
                  <VersionIcon />
                </ButtonsContainer>
              </ListItemIcon>
              <CustomParagraph3>Version</CustomParagraph3>
            </MenuItem>
          </SecondSidebarDiv>
        </SidebarBox>

        <ContentBox>
          {activeMenu === "practice" && (
            <PracticeCard location={currentLocation!} />
          )}

          {activeMenu === "users" && (
            <UserContainer>
              <BaseTabHeader>
                <span>Users List</span>
                {isAdmin && (
                  <span onClick={() => openForm()}>
                    <PersonAddAltIcon />
                    Add Users
                  </span>
                )}

                <Modal open={isFormOpen} onClose={closeForm}>
                  <StyledBox>
                    <PopupAddUser />
                    <CustomH2>Add User</CustomH2>
                    <TextField
                      label="Email"
                      variant="outlined"
                      fullWidth
                      value={emailInput}
                      onChange={handleEmailChange}
                      style={{}}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                    {isEmailValid === false && (
                      <ErrorMessage>Please, enter a valid email!</ErrorMessage>
                    )}
                    <ButtonsContainer>
                      <Button
                        onClick={() =>
                          handleAddUserClick(currentLocation?.recordId!)
                        }
                        disabled={!isEmailValid}
                      >
                        ADD
                      </Button>

                      <Button onClick={closeForm}>CANCEL</Button>
                    </ButtonsContainer>
                  </StyledBox>
                </Modal>
              </BaseTabHeader>

              <UserListParent>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Full Name</th>
                      <th>Email</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {locationUsers
                      ?.sort((a, b) => {
                        if (a.user.recordId === currentUser?.user.recordId)
                          return -1;
                        if (b.user.recordId === currentUser?.user.recordId)
                          return 1;
                        return 0;
                      })
                      .map((item, index) => (
                        <tr key={index}>
                          <td>
                            <UserLogo>
                              <span className="initials-text">
                                {getInitialsFromName(
                                  item.user.firstName,
                                  item.user.surname
                                )}
                              </span>
                            </UserLogo>
                          </td>
                          <td>
                            <span className="value">
                              {item.user.firstName} {item.user.surname}
                            </span>
                          </td>
                          <td>
                            <span className="emailset">
                              {item?.user?.emailAddress}
                            </span>
                          </td>
                          <td>
                            {item.user.recordId !==
                              currentUser?.user.recordId && (
                              <CrossButton
                                onClick={() =>
                                  handleRemoveUser(item?.user?.recordId)
                                }
                              >
                                <DeleteIcon />
                              </CrossButton>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </UserListParent>
            </UserContainer>
          )}

          {activeMenu === "apps" && (
            <AppsContainer>
              <BaseTabHeader>
                <span>App List</span>
              </BaseTabHeader>
              <AppListParent>
                <AppItem onClick={() => handleDownload(downloadBridgeUrl)}>
                  <AppIcon src={DexisIcon} />
                  <span>IS Connect Cloud Bridge</span>
                  <DownloadArrowIcon />
                </AppItem>
              </AppListParent>
            </AppsContainer>
          )}

          {activeMenu === "plan" && (
            <>
              <BaseTabHeader>
                <span>Current Plan</span>
              </BaseTabHeader>
              <PlanCard />
            </>
          )}

          {activeMenu === "feedback" && <FeedbackForm userEmail={userEmail} />}

          {activeMenu === "version" && (
            <VersionContainer>
              <img src={dexisLogoShadow} alt="DEXIS logo" />
              <hr />
              <span> Version {config.buildVersion}</span>

              <span>Release Date {config.releaseDate}</span>
              <p>
                &copy;2023, Dental Imaging Technologies Corporation.
                <br />
                All right reserved.
              </p>
            </VersionContainer>
          )}
        </ContentBox>
      </MainBox>
    </StyledContainer>
  );
};

export default PopupMenu;
