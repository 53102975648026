import styled from "styled-components";
import { colors, media } from "../../../../theme";
import { IconsButton } from "../../../../components/common/buttons.styled";

export const CalendarWrapper = styled.div`
  position: relative;
`;
export const CloseCalendarButton = styled(IconsButton)`
  display: none;
  transition: none;

  & svg {
    width: 16px;
  }

  &:hover {
    cursor: pointer;
  }

  ${media.tablet} {
    position: absolute;
    display: inline-block;
    z-index: 99;
    top: 130px;
    right: 47px;
  }

  ${media.phoneL} {
    top: 0;
    left: 90%;
  }
`;

export const CalendarPopUp = styled.div`
  position: absolute;
  z-index: 98;
  top: 100%;
  margin-top: 10px;
  background-color: white;
  width: auto;
  line-height: 1.125em;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0 3px 0 #ddd;

  & .rdrMonthName {
    display: none;
  }

  & .rdrStaticRangeLabel {
    font-size: 12px;
  }

  & .rdrDateRangePickerWrapper button {
    border: 0;
  }

  & .rdrPprevButton {
    i {
      border-color: transparent ${colors["gray-7"]} transparent transparent;
    }
  }

  & .rdrNextButton {
    i {
      margin: 0 0 0 7px;
      border-width: 4px 4px 4px 6px;
      border-color: transparent transparent transparent ${colors["gray-7"]};
      transform: translate(3px, 0px);
    }
  }

  & .rdrDefinedRangesWrapper {
    background-color: ${colors["gray-2"]};
  }

  & .rdrInputRangeInput {
    border: none;
    border-radius: 0;
  }

  & .rdrInputRanges .rdrInputRange:last-child {
    display: none;
  }

  & .rdrStaticRangeLabel {
    background-color: ${colors["gray-2"]};
    color: ${colors["gray-9"]};
    font-weight: 500;
  }

  & .rdrStaticRangeLabel:hover {
    background-color: ${colors["gray-4"]};
  }

  & .rdrDateDisplayWrapper {
    background-color: white;
  }

  & .rdrMonthAndYearWrapper {
    border-bottom: 1px solid ${colors["gray-4"]};
    margin: 0 auto;
    width: 310px;
  }

  & .rdrMonthAndYearWrapper::after {
    border-bottom: solid 1px black;
  }

  & .rdrDateInput {
    background-color: ${colors["gray-2"]};
    border-radius: 0;
    box-shadow: none;
  }

  & .rdrDayToday .rdrDayNumber > span::after {
    display: none;
  }

  & .rdrDayEndPreview,
  .rdrEndEdge {
    right: 11px;
  }

  & .rdrDayStartPreview,
  .rdrStartEdge {
    left: 11px;
  }

  & .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    top: 5px;
    bottom: 5px;
  }

  & button {
    border: none;
    outline: none;
    background-color: white;
    color: black;
    margin: 0;
    border: 0;
    font-size: 1em;
  }
  ${media.tablet} {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    top: 0;
    right: 0;
    box-sizing: border-box;

    & .rdrDateRangePickerWrapper {
      position: absolute;
      top: 130px;
      margin: auto;
      right: 40px;
    }
    & .rdrDefinedRangesWrapper {
      width: 180px;
    }

    & .rdrDateRangePickerWrapper::after {
      content: " ";
      position: absolute;
      transform: translate(20%, -100%);
      right: 70px;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent white transparent;
    }

    & .rdrCalendarWrapper {
      justify-content: center;
      padding-top: 20px;
    }

    & .rdrDateDisplayWrapper,
    .rdrMonthAndYearWrapper,
    .rdrMonth {
      margin-right: 3%;
    }
  }
  ${media.phoneL} {
    margin: 0 auto;
    min-width: 100vw;

    & .rdrDateRangePickerWrapper {
      position: absolute;
      width: 100%;
      box-sizing: border-box;
      top: 0;
      left: 0;
    }

    & .rdrCalendarWrapper {
      justify-content: center;
      width: 100%;
      min-width: 320px;
      padding-top: 5vw;
    }

    & .rdrMonthAndYearWrapper,
    .rdrMonths,
    .rdrDateDisplayWrapper {
      box-sizing: border-box;
      margin: 0 auto;
    }

    & .rdrDateDisplayWrapper {
      margin: 0 10%;
    }

    & .rdrStaticRangeLabel,
    .rdrDefinedRangesWrapper,
    .rdrDateRangePickerWrapper::after {
      display: none;
    }
  }
`;

export const Calendar = styled.div`
  height: 100%;
  display: inline-flex;
  width: 100%;
  justify-content: flex-end;
  position: relative;

  & > button {
    background-color: transparent;

    color: ${colors["gray-10"]};
    border: none;
    outline: none;
    cursor: pointer;
    width: fit-content;
    justify-self: flex-end;
  }

  & > span {
    cursor: pointer;
    padding: 3px 12px;
    color: ${colors["gray-10"]};
    height: 22px;
    align-self: center;
    font-size: 14px;
  }
`;
