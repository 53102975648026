import styled from "styled-components";
import { colors } from "../../../../theme";

export const InfoSpan = styled.span`
  display: block;
  color: ${colors["gray-7"]};
  font-size: 15px;
`;

export const LabelSpan = styled(InfoSpan)`
  color: ${colors["gray-5"]};
  font-size: 11px;
`;

export const PatientCardContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  max-width: 280px;
  background-color: ${colors["gray-1"]};
  padding: 15px;
  border: 1px solid ${colors["gray-4"]};
  box-shadow: 1px 2px 7px ${colors["gray-5"]};
  cursor: pointer;

  &.selected_patient {
    background-color: white;
    border-color: ${colors["gray-10"]};
    box-shadow: 1px 3px 23px ${colors["gray-8"]};

    & ${InfoSpan} {
      color: ${colors["gray-12"]};
    }

    & ${LabelSpan} {
      color: ${colors["gray-8"]};
    }
  }

  &:hover {
    border-color: ${colors["orange-1"]};
  }
`;

export const PatientNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 3px;
  padding-bottom: 7px;
  border-bottom: 1px solid ${colors["gray-7"]};
  font-size: 17px;
  color: ${colors["gray-10"]};
  font-weight: 600;
`;

export const PatientAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: ${colors["orange-1"]};
  color: white;
  font-size: 19px;
`;

export const PatientInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0px 15px 0;
  padding: 0 5px;
`;

export const PatientId = styled.div`
  padding: 5px;
  border-top: 1px solid ${colors["gray-7"]};
`;
