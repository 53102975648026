import styled from "styled-components";
import { colors, media } from "../../../theme";

export const PlanCardContainer = styled.article`
  border: 1px solid ${colors["gray-5"]};
  border-radius: 5px;
  padding: 15px;
  max-width: 500px;
  min-width: 300px;
  margin: auto;
  text-align: left;
  flex-direction: row;
  text-align: left;

  ${media.tablet} {
    padding: 10px 25px;
  }

  ${media.phoneL} {
    padding: 10px 15px;
  }

  ${media.phoneS} {
    padding: 10px;
  }
`;

export const PlanCardHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 0 0 0 15px;

  ${media.tablet} {
    font-size: 20px;
    padding: 10px;
  }

  ${media.phoneL} {
    font-size: 15px;
    padding: 5px;
  }

  ${media.phoneS} {
    font-size: 10px;
    padding: 2px;
  }
`;

export const PlanInfo = styled.ul`
  flex: 2;
  list-style: none;
  padding: 0;
  margin: 0 0 0 15px;
  flex-grow: inherit;

  li {
    margin-bottom: 10px;
  }

  ${media.tablet} {
    font-size: 18px;
  }

  ${media.phoneL} {
    font-size: 16px;
  }

  ${media.phoneS} {
    font-size: 14px;
  }

  li:last-child {
    font-size: 18px;
  }
`;
