import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors, media } from "../../../theme";

export const FooterContainer = styled.footer`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 25px;
  background-color: ${colors["grey-color"]};
  color: white;
  font-size: 12px;
  letter-spacing: 0.5px;
  padding: 0 10px;
  overflow: hidden;

  & p {
    margin: 0;
    text-indent: -12px;
    padding-left: 12px;
  }

  ${media.phoneL} {
    gap: 10px;
    letter-spacing: 0;
    font-size: 10px;
    padding: 0 3px;
    line-height: 12px;
  }

  ${media.phoneS} {
    & p {
      font-size: 9px;
      line-height: 10px;
    }
  }
`;

export const TermsOfUseLink = styled(Link)`
  min-width: fit-content;
  color: white;
  text-decoration: none;
  cursor: pointer;
`;
