import React from "react";

const SettingsIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g transform="translate(1286.594 -3694.902)">
        <path d="M-1266.611,3704.226a.842.842,0,0,0-.725-.825l-1.964-.258a7.5,7.5,0,0,0-.917-2.192l1.134-1.542a.827.827,0,0,0-.083-1.083l-.934-.925a.837.837,0,0,0-1.092-.075l-1.525,1.158a7.543,7.543,0,0,0-2.242-.908l-.295-1.957a.842.842,0,0,0-.833-.717l-1.317.008a.833.833,0,0,0-.817.725l-.289,2.007a7.537,7.537,0,0,0-2.067.909l-1.667-1.234a.828.828,0,0,0-1.092.083l-.925.934a.836.836,0,0,0-.067,1.092l1.267,1.684a7.5,7.5,0,0,0-.825,2.017l-1.983.275a.842.842,0,0,0-.717.825l-.008,1.317a.827.827,0,0,0,.717.825l1.992.3a7.4,7.4,0,0,0,.867,2.1l-1.2,1.583a.826.826,0,0,0,.075,1.083l.925.942a.847.847,0,0,0,1.092.083l1.592-1.183a7.394,7.394,0,0,0,2.033.884l.309,2.033a.836.836,0,0,0,.833.708l1.317-.008a.833.833,0,0,0,.817-.717l.275-1.958a7.543,7.543,0,0,0,2.167-.858l1.625,1.192a.826.826,0,0,0,1.083-.083l.925-.933a.837.837,0,0,0,.075-1.092l-1.167-1.55a7.526,7.526,0,0,0,.942-2.242l1.981-.283a.827.827,0,0,0,.717-.825Zm-9.982,4.842a4.168,4.168,0,0,1-4.167-4.167,4.168,4.168,0,0,1,4.167-4.167,4.168,4.168,0,0,1,4.167,4.167A4.168,4.168,0,0,1-1276.593,3709.068Z" />
      </g>
    </svg>
  );
};

export default SettingsIcon;
