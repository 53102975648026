import React, { useCallback } from "react";
import useAuthService from "../../services/authService";
import {
  LandingPageContainer,
  MainContent,
  LogoContainer,
  LoginButton,
  TermsOfUseLink,
} from "./index.styled";
import dexisLogoShadow from "../../assets/images/dexis-logo-shadow.png";
import { useScreenWidth } from "../../utils/useScreenWidth";
import { breakpoints } from "../../theme";

const Landing: React.FC = () => {
  const authService = useAuthService();
  const screenWidth = useScreenWidth();
  const doLogin = useCallback(() => {
    authService.login();
  }, [authService]);

  return (
    <LandingPageContainer>
      <MainContent>
        {screenWidth <= breakpoints.phoneL ? (
          <h1>
            Smart <br /> Simplicity <br /> for you & <br /> your team
          </h1>
        ) : (
          <h1>
            Productivity and <br /> Smart Simplicity <br /> for you & your team.
          </h1>
        )}
        <LogoContainer>
          <img src={dexisLogoShadow} alt="DEXIS logo" />
          <LoginButton onClick={doLogin}>Sign in</LoginButton>
        </LogoContainer>
      </MainContent>
      <TermsOfUseLink to="/terms-of-use">Terms of Use</TermsOfUseLink>
    </LandingPageContainer>
  );
};

export default Landing;
