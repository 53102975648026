import InfoIcon from "@mui/icons-material/Info";
import { styled } from "styled-components";
import { Tooltip } from "react-tooltip";

export const StyledStatusIcon = styled(InfoIcon)<{ color: string }>`
  position: absolute;
  top: 10px;
  left: 10px;
  color: ${(props) => props.color};
`;

export const StyledToolTip = styled(Tooltip)`
  z-index: 10;

  & span {
    display: block;
  }
`;
