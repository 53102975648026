import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import countries from "i18n-iso-countries";
import "react-tooltip/dist/react-tooltip.css";
import { BodyLayout } from "./App.styled";

// Register english local for now for country name display, to be moved to i18n when languages and translations will be setup
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const root = createRoot(document.getElementById("root")!); // createRoot(container!) if you use TypeScript
root.render(
  // <React.StrictMode>
  <>
    <BodyLayout />
    <App />
  </>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
