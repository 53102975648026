import React from "react";
import getLoadedConfig from "../../services/appConfigService";
import { MainLayout } from "./index.styled";
const DownloadBridgePage: React.FC = () => {

  window.location.href = getLoadedConfig().cloudBridgeInstallerUrl

  return (<MainLayout>You can close this tab once DEXIS IS Cloud Bridge is downloaded</MainLayout>)
}

export default DownloadBridgePage;
