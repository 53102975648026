import React from "react";

const DeleteIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
    >
      <g transform="translate(0.091 0.091)">
        <path
          d="M.424,9.424l-.849-.849,9-9,.849.849Z"
          transform="translate(0.333 0.333)"
        />
        <path
          d="M8.576,9.424l-9-9L.424-.424l9,9Z"
          transform="translate(0.333 0.333)"
        />
      </g>
    </svg>
  );
};

export default DeleteIcon;
