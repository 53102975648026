import React from "react";

const AppsIcon: React.FC = () => {
  return (
    <svg
      version="1.1"
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 2 45 30"
    >
      <path
        className="st0"
        d="M10,4h10v10H10V4z M22,4h10v10H22V4z M10,18h10v10H10V18z M22,18h10v10H22V18z"
      />
    </svg>
  );
};

export default AppsIcon;
