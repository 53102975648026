import React, { useEffect, useState } from "react";
import {
  CheckboxInput,
  CheckboxLabel,
  ErrorMessage,
  FeedbackFormContainer,
  FileInputLabel,
  CheckboxInputsContainer,
  SendButton,
  StyledForm,
  StyledH2,
  ThankYouMessage,
  FilesButton,
  FilesContainer,
  FilesMenu,
  DeleteButton,
} from "./FeedbackForm.styled";
import AttachmentIcon from "../../svg-icons/AttachmentIcon";
import { MenuItem } from "@mui/material";
import { submitFeedback } from "../../../services/api/feedbackService";
import DeleteIcon from "../../svg-icons/DeleteIcon";
import TooltipMUI from "../TooltipMUI/TooltipMUI";

interface FeedbackFormProps {
  userEmail: string;
}

type RequestTypes =
  | { type: 1; name: "Issue" }
  | { type: 2; name: "Idea" }
  | { type: 3; name: "Question" };

const FeedbackForm: React.FC<FeedbackFormProps> = ({ userEmail }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [requestType, setRequestType] = useState<RequestTypes>({
    type: 1,
    name: "Issue",
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [error, setError] = useState("");
  const [isSendingInProgress, setIsSendingInProgress] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [timeShow] = useState(3000);

  useEffect(() => {
    if (!isFormSubmitted) return;

    const timer = setTimeout(() => {
      setIsFormSubmitted(false);
    }, timeShow);

    return () => clearTimeout(timer);
  }, [isFormSubmitted, timeShow]);

  const openDropDown = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeDropDown = () => {
    setAnchorEl(null);
  };

  const onFormSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");

    if (!title || !description) return;
    setIsSendingInProgress(true);
    const data = new FormData();

    data.append("message", title);
    data.append("description", description);
    data.append("requestType", JSON.stringify(requestType.type));
    data.append("isPrivate", "false");
    data.append("email", userEmail);
    files.forEach((file) => {
      data.append("attachements", file);
    });

    try {
      await submitFeedback(data);

      setTitle("");
      setDescription("");
      setFiles([]);
      setRequestType({
        type: 1,
        name: "Issue",
      });
      setIsFormSubmitted(true);
    } catch (err: any) {
      setError("Something went wrong. Please try again!");
    }
    setIsSendingInProgress(false);
  };

  const onAttachingFilesHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj = e.target.files!;

    for (let i = 0; i < fileObj.length; i++) {
      if (!files.some((el) => el.name === fileObj[i].name)) {
        setFiles((prevState) => [...prevState, fileObj[i]]);
      }
    }
  };

  const onDraggingFiles = (e: React.DragEvent) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;

    for (let i = 0; i < droppedFiles.length; i++) {
      if (!files.some((el) => el.name === droppedFiles[i].name)) {
        setFiles((prevState) => [...prevState, droppedFiles[i]]);
      }
    }
  };

  const onRemoveAttachmentHandler = (name: string) => {
    setFiles((prevState) => {
      const newState = prevState.filter((el) => el.name !== name);
      if (!newState.length) {
        closeDropDown();
      }
      return newState;
    });
  };

  return (
    <FeedbackFormContainer>
      <StyledH2>Help us improve</StyledH2>
      <StyledForm
        onSubmit={(e) => onFormSubmitHandler(e)}
        isSendingInProgress={isSendingInProgress}
      >
        <CheckboxInputsContainer>
          <CheckboxLabel>
            <CheckboxInput
              type="checkbox"
              checked={requestType.type === 1}
              onChange={() => setRequestType({ type: 1, name: "Issue" })}
            />
            Issue
          </CheckboxLabel>
          <CheckboxLabel>
            <CheckboxInput
              type="checkbox"
              checked={requestType.type === 2}
              onChange={() => setRequestType({ type: 2, name: "Idea" })}
            />
            Idea
          </CheckboxLabel>
          <CheckboxLabel>
            <CheckboxInput
              type="checkbox"
              checked={requestType.type === 3}
              onChange={() => setRequestType({ type: 3, name: "Question" })}
            />
            Question
          </CheckboxLabel>
        </CheckboxInputsContainer>
        <input
          type="text"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
            setError("");
          }}
          placeholder={`A brief description of the ${requestType.name} *`}
        />
        <textarea
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
            setError("");
          }}
          placeholder="Provide more details here *"
        />
        <FilesContainer>
          <FileInputLabel
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => onDraggingFiles(e)}
          >
            <input
              type="file"
              multiple
              value=""
              onChange={(e) => onAttachingFilesHandler(e)}
            />
            <span>
              <AttachmentIcon />
              Drag and drop files here or click to select files
            </span>
          </FileInputLabel>
          <FilesButton
            onClick={openDropDown}
            type="button"
            disabled={!files.length}
            isMenuOpen={!!anchorEl}
          >
            {files.length} files selected
          </FilesButton>
          <FilesMenu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={closeDropDown}
            transformOrigin={{ horizontal: "center", vertical: "bottom" }}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
          >
            {files.map((el, index) => (
              <TooltipMUI
                title={[el.name]}
                fontSize={11}
                themeColor="gray"
                key={index}
              >
                <MenuItem>
                  {el.name}{" "}
                  <DeleteButton
                    onClick={() => onRemoveAttachmentHandler(el.name)}
                  >
                    <DeleteIcon />
                  </DeleteButton>
                </MenuItem>
              </TooltipMUI>
            ))}
          </FilesMenu>
        </FilesContainer>

        {error && <ErrorMessage>{error}</ErrorMessage>}

        <SendButton disabled={!title || !description}>Send</SendButton>

        <ThankYouMessage isFormSubmitted={isFormSubmitted} timeShow={timeShow}>
          Thank you for your message!
        </ThankYouMessage>
      </StyledForm>
    </FeedbackFormContainer>
  );
};

export default FeedbackForm;
