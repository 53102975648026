import React, { createContext, useEffect, useState, useContext } from "react";
import Wait from "./Wait/Wait";
import { loadConfig, IAppConfig } from "../../services/appConfigService";
import {
  ErrorTitle,
  ErrorText,
  MessageContainer,
  LoadingContainer,
} from "./ErrorBoundary.styled";

interface IProps {
  children: any;
}

const configContext = createContext<IAppConfig | undefined>(undefined);

export const AppConfigProvider: React.FC<IProps> = ({ children }) => {
  const [config, setConfig] = useState<IAppConfig | undefined | null>();
  useEffect(() => {
    loadConfig()
      .then(setConfig)
      .catch(() => setConfig(null));
  }, []);

  // Config is loading ....
  if (typeof config === "undefined") {
    return (
      <LoadingContainer>
        <Wait />
      </LoadingContainer>
    );
  }

  // Failed to load config
  if (config === null) {
    return (
      <MessageContainer>
        <ErrorTitle>
          <h4>Error :</h4>
        </ErrorTitle>
        <ErrorText>
          <p>Failed to load application configuration.</p>
        </ErrorText>
      </MessageContainer>
    );
  }

  // Config loaded ...
  // console.log("CONFIG LOADED:", config);
  return (
    <configContext.Provider value={config}>{children}</configContext.Provider>
  );
};

const useAppConfig = () => {
  const config = useContext(configContext);
  if (!config) {
    throw new Error("AppConfig not available on context!");
  }
  return config;
};

export default useAppConfig;
