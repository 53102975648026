import styled from "styled-components";

export const WaitContainer = styled.div`
  font-family: sans-serif;
  height: 2rem;
  width: 10rem;
  padding: 8px 20px;
  background-color: white;
  color: black;
  border-radius: 4px;
  border: solid 1px #cde;
  margin: 0 auto;
  box-shadow: 1px 2px 4px #cde, 1px 2px 3px #cde;
  align-items: center;
  vertical-align: middle;
`;

export const Text = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
