import React from "react";

const ExitIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.514"
      height="20.2"
      viewBox="0 0 21.514 20.2"
    >
      <g transform="translate(1714.1 -4871.9)">
        <path
          d="M13,1.1H0V-1.1H13Z"
          transform="translate(-1707 4882)"
          fill="#aaa"
        />
        <path
          d="M4,5.414-.707.707.707-.707,4,2.586,7.293-.707,8.707.707Z"
          transform="translate(-1698 4886) rotate(-90)"
          fill="#aaa"
        />
        <path
          d="M178.956,4184.083h-12.5a1.1,1.1,0,0,1-1.1-1.1v-18a1.1,1.1,0,0,1,1.1-1.1h12.5a1.1,1.1,0,0,1,1.1,1.1v3.5h-2.2v-2.4h-10.3v15.8h10.3v-2.393h2.2v3.493A1.1,1.1,0,0,1,178.956,4184.083Z"
          transform="translate(-1879.456 708.017)"
          fill="#aaa"
        />
      </g>
    </svg>
  );
};

export default ExitIcon;
