import React from "react";

const PatientsListViewIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
    >
      <g transform="translate(1790.301 -4727.321)">
        <rect
          width="2.5"
          height="2.5"
          transform="translate(-1777.801 4727.321)"
        />
        <rect
          width="2.5"
          height="2.5"
          transform="translate(-1784.051 4727.321)"
        />
        <rect
          width="2.5"
          height="2.5"
          transform="translate(-1790.301 4727.321)"
        />
        <rect
          width="2.5"
          height="2.5"
          transform="translate(-1777.801 4733.571)"
        />
        <rect
          width="2.5"
          height="2.5"
          transform="translate(-1784.051 4733.571)"
        />
        <rect
          width="2.5"
          height="2.5"
          transform="translate(-1790.301 4733.571)"
        />
        <rect
          width="2.5"
          height="2.5"
          transform="translate(-1777.801 4739.821)"
        />
        <rect
          width="2.5"
          height="2.5"
          transform="translate(-1784.051 4739.821)"
        />
        <rect
          width="2.5"
          height="2.5"
          transform="translate(-1790.301 4739.821)"
        />
      </g>
    </svg>
  );
};

export default PatientsListViewIcon;
