const routes = {
  home: { path: "/" },
  locationManagement: { path: "/location" },
  location: { path: "/location/:locId" },
  login: { path: "/login" },
  termsOfUse: { path: "/terms-of-use" },
  redirectPartner: { path: "/redirect-partner" },
  downloadBridge: { path: "/download-bridge" },
};

export default routes;
