import { DotsContainer, Dot } from "./LoaderDots.styled";

const LoaderDots = () => {
  return (
    <DotsContainer>
      <Dot delay="0s" />
      <Dot delay=".1s" />
      <Dot delay=".2s" />
    </DotsContainer>
  );
};

export default LoaderDots;
