import styled from "styled-components";
import { colors, media } from "../../../theme";

export const PatientsContainer = styled.main`
  box-sizing: border-box;
  position: relative;
  display: flex;
  padding: 5px 5px 0px 0px;
  height: calc(100vh - 70px);
  width: 100%;

  ${media.tablet} {
    min-height: calc(100vh - 65px);
  }
`;

export const PatientListWithSearchContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  padding: 10px 15px 5px 15px;
  height: calc(100vh - 74px);
  width: 100%;
  min-width: 310px;
  max-width: 360px;

  ${media.tablet} {
    height: calc(100vh - 70px);
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  & button {
    width: 30px;
    height: 30px;
    padding: 0;
  }
`;

export const SearchForm = styled.form`
  box-sizing: border-box;
  position: relative;
  width: 100%;

  & label {
    display: flex;
    width: 100%;
    height: 35px;
    margin-left: 5px;

    &:focus-within {
      outline: 1px solid ${colors["orange-1"]};
    }
  }

  & input {
    width: 100%;
    text-indent: 45px;
    border: none;
    outline: none;
    background-color: ${colors["gray-1"]};

    &::placeholder {
      color: ${colors["gray-7"]};
      text-indent: 50px;
    }
  }

  & svg {
    position: absolute;
    top: 8px;
    left: 25px;
    fill: ${colors["gray-7"]};
  }
`;

export const PatientsList = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: calc(100% - 80px);
  margin-top: 30px;
  padding: 18px 15px 0 15px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors["gray-4"]};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    margin-block: 20px;
  }

  & > p {
    background-color: white;
    padding: 8px;
    border-radius: 8px;
    min-width: 195px;
    text-indent: 20px;
  }
`;

export const LoadingDotsContainer = styled.div`
  padding-top: 25px;
`;

export const MarkerElement = styled.div`
  background-color: transparent;
  height: 1px;
`;

export const PatientDataContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: calc(100vh - 90px);
  padding: 5px 10px 5px 0;
  overflow-x: hidden;

  &.loading-patients {
    display: grid;
    grid-template-rows: 55px auto;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors["gray-4"]};
    border-radius: 10px;
  }

  ${media.tablet} {
    height: calc(100vh - 75px);
  }
`;

export const MainHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0 15px 25px;
  margin-bottom: 35px;

  & span {
    color: ${colors["gray-6"]};
  }

  & span b {
    font-weight: 600;
    color: ${colors["orange-1"]};
  }
`;

export const Tabs = styled.div`
  margin-top: 12px;

  button {
    font-size: 15px;
    border: none;
    background-color: transparent;
    color: ${colors["gray-7"]};
    cursor: pointer;

    &.active-tab {
      color: ${colors["gray-10"]};
    }
  }
`;

export const ErrorBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  min-height: 70px;
  margin: 15% auto 0 auto;
  padding: 30px 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
`;

export const ErrorMessage = styled.p`
  font-weight: 600;
  color: ${colors["red-1"]};
  letter-spacing: 0.5px;
`;
