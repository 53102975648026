import React, { useCallback, useEffect, useState } from "react";
import validator from "validator";
import { Button, Modal, TextField } from "@mui/material";
import { ICreationUser, ILocation } from "../../services/api/models";
import {
  addUserToUserLocations
} from "../../services/api/locationService";
import Wait from "../../components/shared/Wait/Wait";
import { useNavigate } from "react-router-dom";
import useAuthService from "../../services/authService";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  ButtonsContainer,
  LocationContainer,
  LoadingContainer,
  MainLayoutContainer,
  SettingsHeader,
  StyledBox,
  ErrorMessage,
} from "./index.styled";
import PracticeCard from "./PracticeCard/PracticeCard";
import UsersCard from "./UsersCard/UsersCard";
import LocationForm from "../../components/shared/Locations/LocationForm/LocationForm";

const LocationManagement: React.FC = () => {
  const navigate = useNavigate();
  const [loadingLocations, setLoadingLocations] = useState(true);
  const [currentLocation, setCurrentLocation] = useState<ILocation>();
  const [hasError, setHasError] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const authService = useAuthService();
  const [locationUsers, setLocationUsers] = useState<
    { role: string; user: { recordId: string; emailAddress: string } }[]
  >([]);
  const [error, setError] = useState("");
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    error && setError("");
    const newEmail = e.target.value;
    setEmailInput(newEmail);
    setIsEmailValid(validator.isEmail(newEmail));
  };

  const fetchLocations = useCallback(async () => {
    try {
      const locationData = await authService.getCurrentLocation();
      if (locationData) {
        const users = locationData.userLocations.map((userLocation) => ({
          role: userLocation.role,
          user: {
            recordId: userLocation.user.recordId,
            emailAddress: userLocation.user.emailAddress,
          },
        }));
        setCurrentLocation(locationData);
        setLocationUsers(users);
      }
      setLoadingLocations(false);
    } catch (error: any) {
      console.error("Error fetching locations:", error);
      setLoadingLocations(false);
      setHasError(true);
    }
  }, [setCurrentLocation, setLocationUsers, setLoadingLocations, setHasError, authService]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const userid = await authService.getUserId();
      setUserId(userid);
    };
    fetchUserDetails();
  }, [authService]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchLocations();
    };
    fetchData();
  }, [fetchLocations]);

  if (loadingLocations) {
    return (
      <LoadingContainer>
        <Wait />
      </LoadingContainer>
    );
  }

  const openForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
    setEmailInput("");
    setError("");
    setIsEmailValid(null);
  };

  const handleAddUserClick = async (locationId: string) => {
    try {
      const userData: ICreationUser = {
        userEmail: emailInput,
        locationId: locationId,
      };
      const newUserResponse = (await addUserToUserLocations(userData)).value[0];
      const newUser = {
        role: newUserResponse.role,
        user: {
          recordId: newUserResponse.userId,
          emailAddress: emailInput,
        },
      };

      setLocationUsers((prevUsers) => [...prevUsers, newUser]);
      closeForm();
    } catch (error: any) {
      console.error("Error fetching users:", error);
      if (error.statusCode === 409)
        setError("This user is already associated to an existing practice.");
      else if (error.statusCode === 400)
        setError("This email is not registered as a DEXIS user.");
    }
  };

  return (
    <MainLayoutContainer $hasLocation={!!currentLocation}>
      {!loadingLocations && !currentLocation && !hasError && (
        <LocationForm
          onLocationCreate={() => {
            fetchLocations();
            navigate("/");
          }}
        />
      )}
      {currentLocation && (
        <>
          <SettingsHeader>
            Practice Settings
            <SettingsIcon />
          </SettingsHeader>
          {!hasError && (
            <LocationContainer key={currentLocation.recordId}>
              <PracticeCard location={currentLocation} />
              <UsersCard
                location={currentLocation}
                userId={userId}
                openForm={openForm}
                locationUsers={locationUsers}
                onRemoveUser={setLocationUsers}
              />
              <Modal open={isFormOpen} onClose={closeForm}>
                <StyledBox>
                  <h2>Add User</h2>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={emailInput}
                    onChange={handleEmailChange}
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                  {isEmailValid === false && (
                    <ErrorMessage>Please, enter a valid email!</ErrorMessage>
                  )}
                  <ButtonsContainer>
                    <Button
                      variant="contained"
                      onClick={() =>
                        handleAddUserClick(currentLocation.recordId)
                      }
                      disabled={!isEmailValid}
                    >
                      Add
                    </Button>
                    <Button variant="contained" onClick={closeForm}>
                      Cancel
                    </Button>
                  </ButtonsContainer>
                </StyledBox>
              </Modal>
            </LocationContainer>
          )}
        </>
      )}
    </MainLayoutContainer>
  );
};

export default LocationManagement;
