import React from "react";

const CardIcon: React.FC = () => {
  return (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="20pt" height="20pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
stroke="none">
<path d="M230 4291 c-91 -28 -174 -104 -206 -187 -18 -47 -19 -98 -19 -1544 0
-1446 1 -1497 19 -1544 24 -63 86 -130 151 -164 l50 -27 2335 0 2335 0 50 27
c65 34 127 101 151 164 18 47 19 98 19 1544 0 1446 -1 1497 -19 1544 -24 63
-86 130 -151 164 l-50 27 -2320 2 c-1276 1 -2331 -2 -2345 -6z m4637 -211 c45
-30 48 -47 48 -329 l0 -266 -2355 0 -2355 0 -3 225 c-4 275 1 320 34 354 14
13 35 27 47 29 12 2 1043 4 2292 3 1967 -1 2273 -3 2292 -16z m48 -2096 c0
-951 2 -916 -53 -945 -37 -20 -4566 -21 -4603 -1 -57 30 -54 -15 -57 932 -1
481 0 880 2 887 4 11 442 13 2358 11 l2353 -3 0 -881z"/>
<path d="M864 2232 c-61 -49 -55 -140 11 -170 19 -9 261 -12 975 -12 l950 0
31 28 c26 24 32 36 32 72 0 36 -6 48 -32 72 l-31 28 -958 0 c-892 0 -958 -2
-978 -18z"/>
<path d="M864 1822 c-61 -49 -55 -140 11 -170 19 -9 168 -12 565 -12 l538 0
30 25 c16 13 32 39 36 56 8 40 -17 92 -50 108 -18 8 -183 11 -567 11 -498 0
-544 -2 -563 -18z"/>
</g>
</svg>
  );
};

export default CardIcon;
