import { IRX, ILocation } from "../../../services/api/models";
import { StyledRXCard } from "./RXCard.styled";
import DicomSeriesCard from "../Dicoms/DicomSeriesCard";
import BlobFileCard from "../BlobFiles/BlobFileCard";

interface RXCardProps {
  rx: IRX;
  location: ILocation;
  patient: {
    patientId: string;
    lastName: string;
    firstName: string;
  };
}

const RXCard: React.FC<RXCardProps> = ({ rx, location, patient }) => {
  function horizontalScroll(event: any) {
    const delta = Math.max(
      -1,
      Math.min(1, event.nativeEvent.wheelDelta || -event.nativeEvent.detail)
    );
    event.currentTarget.scrollLeft -= delta * 50;
  }

  return (
    <StyledRXCard onWheel={horizontalScroll}>
      {rx.dicom && (
        <DicomSeriesCard
          location={location}
          key={rx.dicom.recordId}
          dicomSeries={rx.dicom}
          showDownload
        />
      )}
      {rx.attachedFiles?.map((blobFile) => (
        <BlobFileCard
          location={location}
          blobFile={blobFile}
          key={blobFile.recordId}
        />
      ))}
    </StyledRXCard>
  );
};

export default RXCard;
