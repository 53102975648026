import { AuthenticationResult, SilentRequest } from "@azure/msal-browser";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { getLoggedInUser, getAzureAdScopes } from "../authService";
import getLoadedConfig from "../appConfigService";
import { getMsalClient } from "../authService/msalClient";

interface IServerApiInnerError {
  type: string;
  message: string;
  stacktrace: string;
  innerError?: IServerApiInnerError;
}

export interface IServerApiError {
  error: {
    code: string;
    message: string;
    innererror?: IServerApiInnerError;
  };
}

function isServerApiError(error: any): error is IServerApiError {
  return error.error && error.error.code && error.error.message;
}

// This is returned, for example, when the WebApi fails to parse the JSON (ex. invalid Guid)
export interface IServerException {
  Message: string;
  ExceptionType: string;
  ExceptionMessage: string;
}

function isServerException(error: any): error is IServerException {
  return error.Message && error.ExceptionType && error.ExceptionMessage;
}

// Custom HTTP Error
export class HttpError extends Error {
  readonly statusCode: number | undefined;

  constructor(message: string, statusCode?: number) {
    super(message);
    this.name = "XHttpError" + (statusCode ? `.${statusCode}` : "");
    this.statusCode = statusCode;
  }
}

function throwHttpError(error: AxiosError<any>, isRetry: boolean): never {
  const statusCode = error.response && error.response.status;
  console.assert(statusCode !== 401);
  const prefix = isRetry ? "[Retry-Phase] " : "";

  // Handle "400 - Bad Data" error.
  if (statusCode === 400) {
    const errorResponse = error.response!.data;

    if (isServerException(errorResponse)) {
      throw new HttpError(prefix + errorResponse.ExceptionMessage, statusCode);
    }

    if (isServerApiError(errorResponse)) {
      throw new HttpError(prefix + errorResponse.error.message, statusCode);
    }

    if (errorResponse.Message) {
      throw new HttpError(prefix + errorResponse.Message, statusCode);
    }

    throw new HttpError(prefix + errorResponse, statusCode);
  }

  throw new HttpError(prefix + error.message, statusCode);
}

// Create custom instance of Axios for API requests
const http = axios.create();

// Set the request bearer token
function setRequestBearerToken(
  config: AxiosRequestConfig,
  accessToken: string
) {
  console.assert(!!accessToken);
  config.headers!.Authorization = `Bearer ${accessToken}`;
}

// Custom request interceptor
http.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  if (!config.method) {
    throw new Error("HTTP Rquest invoked witout a method.");
  }

  // No need to send authorization when fetching blobfiles
  if (config.url && config.url?.indexOf("blob.core.windows.net") >= 0)
    return config;

  // Azure AD Bearer token
  if (!config?.headers?.Authorization) {
    let authResponse: AuthenticationResult | undefined = undefined;

    try {
      const account = getLoggedInUser();
      if (account) {
        let scopes;
        const loadedConfig = getLoadedConfig();
        const urlType = config.url?.includes(loadedConfig.globalApiBaseUrl)
          ? "globalApi"
          : config.url?.includes(loadedConfig.connectBaseUrl)
          ? "connectData"
          : null;
        switch (urlType) {
          case "globalApi":
            scopes = getAzureAdScopes().globalScopes;
            break;
          case "connectData":
            scopes = getAzureAdScopes().connectScopes;
            break;
          default:
            scopes = getAzureAdScopes().regionalScopes;
            break;
        }
        const request: SilentRequest = {
          scopes: scopes,
          account: account,
        };

        authResponse = await getMsalClient().acquireTokenSilent(request);
      } else {
        throw new Error("HTTP Request invoked without a logged-in user.");
      }
      console.assert(!!authResponse?.accessToken);
    } catch (error: any) {
      throw new HttpError(
        "Auth token aquisition failure. " + error.message,
        401
      );
    }

    setRequestBearerToken(config, authResponse!.accessToken);
  }

  return config;
});

http.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    return response;
  },
  (error: AxiosError<any>) => {
    console.log("Interceptor Response Error" + error);

    // NOTE: When a CORS request fails, no response is passed along to Axios, and Axios will return
    // `error.code === undefined`, `error.response === undefined` and `error.message` === "Network Error".
    // Ref: https://github.com/axios/axios/issues/383
    const statusCode = error.response && error.response.status;
    if (!statusCode) {
      throw new HttpError(
        typeof error.code === "undefined"
          ? "Possible CORS failure."
          : error.message,
        statusCode
      );
    }

    // Is this an authentication error?
    if (statusCode === 401) {
      throw new HttpError(
        "Authentication failure: " + error.message,
        statusCode
      );
    }

    throwHttpError(error, false);
  }
);

export default http;
