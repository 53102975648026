export const colors = {
  "error-red-text": "#cc0000",
  "grey-color": "#b1b1b1",
  "background-grey-light-color": "#f9f9f9",
  "login-blue": "#009bb4",
  "red-1": "#f73944",
  "red-2": "#fa2642",
  "orange-1": "#ff5f30",
  "orange-2": "#d04e27",
  "yellow-1": "#ffd50b",
  "yellow-2": "#ffcc01",
  "green-1": "#73db49",
  "green-2": "#72c943",
  "green-3": "#6bba3b",
  "blue-1": "#4ad9ff",
  "blue-2": "#3dd0fd",
  "blue-3": "#489eb5",
  "blue-4": "#4399b1",
  "blue-5": "#017386",
  "blue-6": "#016f81",
  "gray-1": "#f9f9f9",
  "gray-2": "#eee",
  "gray-3": "#e5e5e5",
  "gray-4": "#dfdfdf",
  "gray-5": "#ccc",
  "gray-6": "#b3b3b3",
  "gray-7": "#aaa",
  "gray-8": "#999",
  "gray-9": "#717171",
  "gray-10": "#616161",
  "gray-11": "#424242",
  "gray-12": "#323232",
  "gray-13": "#252525",
  "gray-14": "#262626",
  "gray-15": "#050505",
};

export const breakpoints = {
  phoneS: 400,
  phoneL: 576,
  tablet: 768,
  laptop: 1024,
  lg: 1280,
  xl: 1440,
};
export const media = Object.entries(breakpoints).reduce(
  (prev, [key, breakpoint]) => {
    prev[key] = `@media (max-width: ${breakpoint}px)`;
    return prev;
  },
  {} as { [index: string]: string }
);
