import React from "react";

const UserIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
    >
      <g transform="translate(-3672 1435)">
        <path
          d="M0,8V4C0,1.791,3.805,0,8.5,0S17,1.791,17,4V8Z"
          transform="translate(3672 -1426)"
        />
        <circle cx="4.1" cy="4.1" r="4.1" transform="translate(3676.4 -1435)" />
      </g>
    </svg>
  );
};

export default UserIcon;
