import styled from "styled-components";
import { colors, media } from "../../../theme";
import { Menu } from "@mui/material";
import { IconsButton } from "../../common/buttons.styled";

export const FeedbackFormContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 32px;
`;

export const StyledH2 = styled.h2`
  box-sizing: border-box;
  padding: 0 0 10px 30px;
  margin: 20px 15px;
  color: ${colors["gray-10"]};
  font-weight: 400;
  border-bottom: 2px solid ${colors["gray-5"]};
`;

export const StyledForm = styled.form<{
  isSendingInProgress: boolean;
}>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 20px;

  & input[type="text"],
  textarea {
    border: none;
    background-color: ${colors["gray-2"]};
    padding: 10px 5px;
    text-indent: 10px;
    font-size: 15px;

    &::placeholder {
      color: ${colors["gray-7"]};
    }

    &:focus {
      outline: 1px solid ${colors["orange-1"]};
    }
  }

  & textarea {
    resize: none;
    height: 200px;
  }

  ${({ isSendingInProgress }) =>
    isSendingInProgress &&
    `
    &::before {
      content: "Sending ...";
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding-top: 30%;
      height: 100%;
      width: 100%;
      font-size: 20px;
      letter-spacing: 0.5px;
      background-color: rgba(255, 255, 255, 0.9);
      z-index: 1;
    }
  `}
`;

export const CheckboxInputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 70px;

  ${media.phoneL} {
    gap: 10px;
    justify-content: space-around;
  }
`;

export const CheckboxLabel = styled.label`
  display: flex;
  flex-direction: row-reverse;
  gap: 15px;
  outline: none;
`;

export const CheckboxInput = styled.input`
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 2px solid ${colors["gray-7"]};
  border-radius: 2px;
  color: white;
  font-size: 18px;
  outline: none;
  cursor: pointer;

  &:checked {
    background-color: ${colors["orange-1"]};
    border-color: ${colors["orange-1"]};
  }

  &:checked:before {
    content: "\\2518";
    width: 100%;
    height: 100%;
    font-size: 20px;
    transform: rotate(40deg) translate(3px, 0px);
  }
`;

export const SendButton = styled.button`
  border-radius: 0;
  padding: 10px;
  background-color: ${colors["orange-1"]};
  color: white;
  border: none;
  font-size: 18px;
  width: 150px;
  margin-bottom: 20px;
  box-shadow: 1px 2px 7px ${colors["gray-5"]};
  cursor: pointer;
  transition: 0.2s ease;

  &:hover {
    transform: translate(0, -1px);
    box-shadow: 1px 3px 5px ${colors["gray-5"]};
  }

  &:disabled {
    cursor: unset;
    background-color: ${colors["gray-4"]};
    box-shadow: none;

    &:hover {
      transform: none;
    }
  }
`;

export const FilesContainer = styled.div`
  display: flex;
  gap: 15px;
`;

export const FileInputLabel = styled.label`
  display: flex;
  align-items: center;
  min-height: 35px;
  padding: 0 20px;
  background-color: ${colors["gray-2"]};
  color: ${colors["gray-7"]};
  font-size: 13px;
  cursor: pointer;

  & input {
    display: none;
  }

  & span {
    display: flex;
    line-height: 25px;
  }

  & svg {
    fill: ${colors["gray-7"]};
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
`;

export const FilesButton = styled.button<{
  isMenuOpen: boolean;
}>`
  width: fit-content;
  border: none;
  background-color: transparent;
  font-size: 13px;
  color: ${colors["orange-1"]};
  color: ${(props) =>
    props.isMenuOpen ? colors["gray-10"] : colors["orange-1"]};
  cursor: pointer;

  &:hover {
    color: ${colors["orange-2"]};
  }

  &:disabled {
    color: ${colors["gray-5"]};
    filter: none;
    cursor: unset;
  }
`;

export const FilesMenu = styled(Menu)`
  & div:has(ul) {
    min-height: 170px;
    max-height: 280px;
    padding: 10px 0 5px 0;
    overflow: visible;
    border-radius: 0;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 10px;
      height: 10px;
      transform: translateY(50%) rotate(45deg);
      background-color: white;
      z-index: 0;
    }
  }

  & ul {
    box-sizing: border-box;
    max-height: 260px;
    overflow-y: overlay;
    padding: 0;
    border-right: 2px solid white;

    &::-webkit-scrollbar {
      width: 5px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${colors["gray-6"]};
      border-radius: 10px;
    }
  }

  & li {
    position: relative;
    display: block;
    width: 150px;
    padding: 5px 15px 5px 10px;
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      cursor: unset;
    }
  }
`;

export const DeleteButton = styled(IconsButton)`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 15px;
  height: 15px;
  padding: 0;

  & svg {
    width: 10px;
  }
`;

export const ErrorMessage = styled.p`
  color: ${colors["red-2"]};
  margin: 0;
`;

export const ThankYouMessage = styled.p<{
  isFormSubmitted: boolean;
  timeShow: number;
}>`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  display: ${(props) => (props.isFormSubmitted ? "" : "none")};
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 35% 0 0 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  color: ${colors["gray-12"]};
  font-size: 25px;
  animation-name: fadeOut;
  animation-duration: ${(props) => props.timeShow + 300 + "ms"};

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    85% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
`;
