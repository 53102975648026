import React from "react";

const AttachmentIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
    >
      <path
        d="M19.329,9.71H4.814a4.9,4.9,0,1,1,0-9.792h9.6a.5.5,0,1,1,0,1h-9.6a3.9,3.9,0,1,0,0,7.792H19.329a2.589,2.589,0,1,0,0-5.177H5.737a1.281,1.281,0,1,0,0,2.562H18.051a.5.5,0,0,1,0,1H5.737a2.281,2.281,0,1,1,0-4.562H19.329a3.589,3.589,0,1,1,0,7.177Z"
        transform="translate(6.924 0.116) rotate(45)"
      />
    </svg>
  );
};

export default AttachmentIcon;
