import React from "react";
import {
  IBlobFile,
  ILocation,
  BlobFileType,
} from "../../../services/api/models";
import { getBlobFileUrl } from "../../../services/api/patientService";
import {
  ButtonsContainer,
  FileName,
  StyledBlobFileCard,
  TimeStampSpan,
} from "./BlobFileCard.styled";
import { IconsButton } from "../../common/buttons.styled";
import DownloadArrowIcon from "../../svg-icons/DownloadArrowIcon";
import TooltipMUI from "../TooltipMUI/TooltipMUI";
import EyeIcon from "../../svg-icons/EyeIcon";
import DEFIcon from "../../../assets/images/fileTypeDEFAULT.png";
import DOCIcon from "../../../assets/images/fileTypeDOC.png";
import IMGIcon from "../../../assets/images/fileTypeIMG.png";
import DOCXIcon from "../../../assets/images/fileTypeDOCX.png";
import ODSIcon from "../../../assets/images/fileTypeODS.png";
import PDFIcon from "../../../assets/images/fileTypePDF.png";
import PDFPIcon from "../../../assets/images/fileTypePDFP.png";
import PDFQSRIcon from "../../../assets/images/fileTypePDFQSR.png";
import PDFSRIcon from "../../../assets/images/fileTypePDFSR.png";
import PLYIcon from "../../../assets/images/fileTypePLY.png";
import STLIcon from "../../../assets/images/fileTypeSTL.png";
import TXTIcon from "../../../assets/images/fileTypeTXT.png";
import XLSIcon from "../../../assets/images/fileTypeXLS.png";
import XLSXIcon from "../../../assets/images/fileTypeXLSX.png";
import ZIPIcon from "../../../assets/images/fileTypeZIP.png";

interface BlobFileCardProps {
  blobFile: IBlobFile;
  location: ILocation;
}

const BlobFileCard: React.FC<BlobFileCardProps> = ({ blobFile, location }) => {
  const formatUTCDate = (inputUTCStringDate: string) => {
    const numberTo2DigitsString = (value: number) => {
      return value.toString().padStart(2, "0");
    };

    const utcDate = new Date(inputUTCStringDate);
    const offsetMinutes = new Date().getTimezoneOffset();
    const localDate = new Date(utcDate.getTime() - offsetMinutes * 60000);
    const year = localDate.getFullYear().toString().slice(2, 4); // Get the last two digits of the year
    const month = numberTo2DigitsString(localDate.getMonth() + 1);
    const day = numberTo2DigitsString(localDate.getDate());
    const hours = localDate.getHours();
    const minutes = numberTo2DigitsString(localDate.getMinutes());
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = numberTo2DigitsString(hours % 12 || 12); // Convert hours to 12-hour format
    return `${month}/${day}/${year} ${formattedHours}:${minutes} ${ampm}`;
  };

  const fileExtension = blobFile.fileName.split(".").pop()?.toLowerCase();

  const getFileIcon = () => {
    switch (fileExtension) {
      case "doc":
      case "rtf":
        return DOCIcon;
      case "docx":
        return DOCXIcon;
      case "jpg":
      case "jpeg":
      case "png":
        return IMGIcon;
      case "ods":
      case "odt":
        return ODSIcon;
      case "pdf":
        switch (blobFile.fileType) {
          case BlobFileType.Prescription_pdf:
            return PDFPIcon;
          case BlobFileType.QuadrantSnapshotReport_pdf:
            return PDFQSRIcon;
          case BlobFileType.ShadeReport_pdf:
            return PDFSRIcon;
          default:
            return PDFIcon;
        }
      case "ply":
        return PLYIcon;
      case "stl":
        return STLIcon;
      case "txt":
        return TXTIcon;
      case "xls":
        return XLSIcon;
      case "xlsx":
        return XLSXIcon;
      case "zip":
        return ZIPIcon;
      default:
        return DEFIcon;
    }
  };

  const previewExtensions = ["pdf", "jpg", "jpeg", "png"];

  async function downloadBlobFile(): Promise<void> {
    try {
      const url = await getBlobFileUrl(
        location?.account.region.apiUrl,
        blobFile.recordId
      );
      const response = await fetch(url);
      const blob = await response.blob();
      const href = URL.createObjectURL(blob);

      // Create dummy <a> element using JavaScript.
      const anchor = document.createElement("a");

      // Add blob file URL as href of <a> element after encoding.
      anchor.href = href;

      // Also set the value of the download attribute
      anchor.download = blobFile.fileName;
      document.body.appendChild(anchor);

      // Click the link element
      anchor.click();
      document.body.removeChild(anchor);

      // Revoke fetched file
      window.URL.revokeObjectURL(href);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  }

  const openPreview = async () => {
    try {
      const url = await getBlobFileUrl(
        location?.account.region.apiUrl,
        blobFile.recordId
      );
      if (url) {
        window.open(url, "_blank");
      }
    } catch (error) {
      console.error("Error fetching blob file URL:", error);
    }
  };

  return (
    <TooltipMUI
      title={[blobFile.fileName]}
      isStatic
      themeColor="dark"
      fontSize={11}
    >
      <StyledBlobFileCard>
        <img
          className={blobFile.thumbnailUrl ? "thumbnailImg" : "thumbnailFile"}
          alt="BlobFile thumbnail"
          src={
            blobFile.thumbnailUrl !== null
              ? blobFile.thumbnailUrl
              : getFileIcon()
          }
        />

        <ButtonsContainer>
          <TooltipMUI title={["Download your file"]}>
            <IconsButton onClick={downloadBlobFile}>
              <DownloadArrowIcon />
            </IconsButton>
          </TooltipMUI>

          {fileExtension && previewExtensions.includes(fileExtension) && (
            <TooltipMUI title={["View your file"]}>
              <IconsButton onClick={openPreview}>
                <EyeIcon />
              </IconsButton>
            </TooltipMUI>
          )}
        </ButtonsContainer>
        <FileName>{blobFile.fileName}</FileName>
        <TimeStampSpan>
          {formatUTCDate(
            blobFile.fileLastModifiedDateTime || blobFile.recordLastUpdated
          )}
        </TimeStampSpan>
      </StyledBlobFileCard>
    </TooltipMUI>
  );
};
export default BlobFileCard;
