import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  CalendarPopUp,
  Calendar,
  CloseCalendarButton,
  CalendarWrapper,
} from "./DateRangeSelector.styled";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { ClickAwayListener } from "@mui/base";
import { colors } from "../../../../theme";
import DeleteIcon from "../../../../components/svg-icons/DeleteIcon";

interface DateRangeProps {
  onDateRangeChange: (data: any) => void;
  data: any;
}

interface Range {
  startDate: Date | any;
  endDate: Date | any;
  key: string;
}

const DateRangeSelector: React.FC<DateRangeProps> = ({
  onDateRangeChange,
  data,
}) => {
  const [range, setRange] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [isCalendarVisible, setCalendarVisible] = useState(false);

  // Effect to handle input changes, limit its values and attach click event listener
  useEffect(() => {
    const inputElement = document.querySelector<HTMLInputElement>(
      ".rdrInputRangeInput"
    );

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      if (newValue === "" || /^\d{0,3}$/.test(newValue)) {
        const numericValue =
          newValue === "" ? "" : Math.min(parseInt(newValue), 999);
        const updatedRange = [
          {
            startDate: numericValue === "" ? null : new Date(numericValue),
            endDate: new Date(""),
            key: "selection",
          },
        ];
        setRange(updatedRange);
        onDateRangeChange({
          startDate: updatedRange[0].startDate,
          endDate: updatedRange[0].endDate,
        });
        if (
          newValue === "1" &&
          (event.nativeEvent as InputEvent).inputType ===
            "deleteContentBackward"
        ) {
          event.preventDefault();
          if (inputElement) {
            event.target.value = "";
          }
        }
      }
    };

    if (inputElement) {
      inputElement.maxLength = 3;
      inputElement.addEventListener("click", handleClick);
    }

    // Cleanup function to remove event listener
    return () => {
      if (inputElement) {
        inputElement.removeEventListener("input", handleInputChange as any);
        inputElement.removeEventListener("click", handleClick);
      }
    };
  }, [onDateRangeChange]);

  const toggleCalendar = () => {
    setCalendarVisible(!isCalendarVisible);
  };

  const handleDateChange = (ranges: any) => {
    setRange([
      {
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
        key: "selection",
      },
    ]);
    onDateRangeChange({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    });
  };

  const handleReset = () => {
    setRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    onDateRangeChange(data);
  };

  // Function to select all text (a workaround to be able to edit input from scratch)
  const handleClick = (event: MouseEvent) => {
    const inputElement = event.target as HTMLElement;
    if (inputElement instanceof HTMLInputElement) {
      inputElement.select();
    }
  };

  const handleCloseCalendar = () => {
    setCalendarVisible(false);
  };

  return (
    <ClickAwayListener onClickAway={() => setCalendarVisible(false)}>
      <Calendar>
        <button onClick={toggleCalendar}>
          <EditCalendarIcon />
        </button>
        <span onClick={handleReset}>Clear</span>
        {isCalendarVisible && (
          <CalendarPopUp>
            <CalendarWrapper>
              <CloseCalendarButton onClick={handleCloseCalendar}>
                <DeleteIcon />
              </CloseCalendarButton>
              <DateRangePicker
                onChange={handleDateChange}
                rangeColors={[colors["orange-1"]]}
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                ranges={range as any}
                months={1}
                direction="horizontal"
                maxDate={new Date()}
              />
            </CalendarWrapper>
          </CalendarPopUp>
        )}
      </Calendar>
    </ClickAwayListener>
  );
};

export default DateRangeSelector;
