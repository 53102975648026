import { FormControlLabel, RadioGroup } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  ICaseRecord,
  IConnectUser,
  IDentalCase,
  ISparkCase,
  ICaseDetails,
} from "../../../services/api/models";
import {
  createDentalCase,
  getSparkCases,
  convertDate,
} from "../../../services/api/caseService";
import { getCurrentUser } from "../../../services/api/userService";
import logo from "../../../assets/images/spark-logo.png";
import i18n, { getLanguageCode } from "../../../utils/i18n";
import { useTranslation } from "react-i18next";
import {
  AppContainer,
  Message,
  MessageNoMargin,
  FormContainer,
  ButtonContainer,
  CustomRadio,
  BirthDateSelector,
} from "./Spark.styled";
import { convertSex } from "../../../utils/convertSex";

interface ISparkProps extends ICaseDetails {
  onTransactionIdReceived: (id: string) => void;
}

type UserStepType =
  | "INIT"
  | "AUTHENTICATION"
  | "CONNECTING"
  | "CONNECTED"
  | "ERROR";

type CaseStepType =
  | "INIT"
  | "CONNEXION"
  | "CASE_SELECTION"
  | "NO_CASES"
  | "CASE_FOUND"
  | "CASE_CREATION"
  | "CASE_FINALIZATION"
  | "ERROR"
  | "PATIENT_GENDER_AND_DOB_SELECTION"
  | "PATIENT_GENDER_SELECTION"
  | "PATIENT_DOB_SELECTION";

type CustomButtonProps = {
  onClick: () => void;
  text: string;
};

const errorMessages = {
  noUserLogged: "ERROR_AUTHENTICATION_FAILURE",
  requestFailure: "NETWORK_ERROR",
  internalError: "ERROR_OCCURRED_WARNING",
};

const submittedStatuses = [
  "UNSUBMITTED",
  "DESIGN_IN_PROGRESS",
  "AWAITING_APPROVAL",
  "IN_PRODUCTION",
  "APPROVED_BY_DOCTOR",
  "REVIEWED_BY_DOCTOR"
];
const shippedStatuses = ["SHIPPED", "SHIPPED_PARTIALLY"];

const Spark: React.FC<ISparkProps> = ({
  pt_pid,
  pt_last_name,
  pt_middle_name,
  pt_first_name,
  pt_birthdate,
  pt_sex,
  case_uid,
  case_date,
  acquisition_date,
  case_type,
  scan_mode,
  scan_object,
  partner_pk,
  lang,
  device_sn,
  transaction_id,
  software_name,
  software_version,
  model_name,
  onTransactionIdReceived,
}) => {
  const { t } = useTranslation();

  const [userStep, setUserStep] = useState<UserStepType>("INIT");
  // On CASES_FOUND, CASE_SELECTION:
  //    If only shipped case, on new case selected, set NO_CASES
  //    Else perform copy ID
  // On NO_CASES, optional PATIENT_GENDER_OR_DOB_SELECTION
  // Then CASE_CREATION, CASE_FINALIZATION OR ERROR
  const [caseStep, setCaseStep] = useState<CaseStepType>("INIT");

  const [patientSubmittedCases, setPatientSubmittedCases] = useState<
    ISparkCase[]
  >([]);
  const [patientShippedCases, setPatientShippedCases] = useState<ISparkCase[]>(
    []
  );
  const [userPk, setUserPk] = useState(0);
  const [localNodeId, setLocalNodeId] = useState(0);
  const [selectedPatientGender, setSelectedPatientGender] = useState(
    convertSex(pt_sex)
  );
  const [selectedPatientDOB, setSelectedPatientDOB] = useState(pt_birthdate);
  const [selectedCaseId, setSelectedCaseId] = useState(-1);
  const [errorMessage, setErrorMessage] = useState("");
  const [submittedDexisCaseId, setSubmittedDexisCaseId] = useState("");
  const [isCaseRefinement, setIsCaseRefinement] = useState(false);

  const patientGenderValid = useCallback(() => {
    return selectedPatientGender === "M" || selectedPatientGender === "F";
  }, [selectedPatientGender]);

  const handleDOBChange = (date: any) => {
    if (date) {
      const selectedDate = new Date(date);
      const year = selectedDate.getFullYear();
      //+1 because getMonth() of the Date object returns a zero-based value
      //+2 to ensure that month is represented by 2 digits
      const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
      const day = selectedDate.getDate().toString().padStart(2, "0");
      setSelectedPatientDOB(`${year}-${month}-${day}`);
    }
  };

  const patientDOBValid = useCallback(() => {
    // DOB format is "2023-01-29"
    if (selectedPatientDOB?.length !== 10) {
      return false;
    }
    const date = new Date(selectedPatientDOB);
    return date.getFullYear() > 1900;
  }, [selectedPatientDOB]);

  const onSelectedCaseChange = (event: any) => {
    setSelectedCaseId(Number(event.target.value));
  };

  const sparkCaseLabel = (c: ISparkCase) => {
    let middlename = "";
    if (!(c.middleName === null || c.middleName.trim().length === 0)) {
      middlename = c.middleName + " ";
    }
    const orderIndicator =
      c.orderIndicator === "P"
        ? "Primary"
        : c.orderIndicator.replace("S", "Refinement ");
    const name = `${c.firstName} ${middlename}${c.lastName}`;
    return `CaseID:${c.caseId} (${c.status} ${orderIndicator}) [${name}]`;
  };

  const sparkCaseCopy = (c: ISparkCase) => {
    return `CaseID:${c.caseId}`;
  };

  const processNewSparkCase = () => {
    // Create dental case for an existing case without verifying its patient Gender/Dob
    if (selectedCaseId !== -1) {
      createConnectCase(selectedCaseId);
    } else {
      if (patientGenderValid() && patientDOBValid()) {
        setCaseStep("NO_CASES");
      } else if (!patientGenderValid() && !patientDOBValid()) {
        setCaseStep("PATIENT_GENDER_AND_DOB_SELECTION");
      } else if (!patientGenderValid() && patientDOBValid()) {
        setCaseStep("PATIENT_GENDER_SELECTION");
      } else if (patientGenderValid() && !patientDOBValid()) {
        setCaseStep("PATIENT_DOB_SELECTION");
      }
    }
  };

  const copyCaseDetails = () => {
    const selectedCase = patientShippedCases
      .concat(patientSubmittedCases)
      .filter((el) => el.caseId === selectedCaseId);
    if (selectedCase.length > 0) {
      const copyText = sparkCaseCopy(selectedCase[0]);
      copyTextToClipboard(copyText);
    }
  };

  async function copyTextToClipboard(text: string) {
    if ("clipboard" in navigator) {
      try {
        return await navigator.clipboard.writeText(text);
      } catch (err: any) {
        console.error("Error writing to clipboard:", err);
        setErrorMessage(err.message);
        setUserStep("ERROR");
      }
    }
  }

  const createConnectCase = useCallback(
    (sparkCaseId: number | undefined) => {
      const caseIsRefinement =
        sparkCaseId !== undefined && sparkCaseId !== -1 && isCaseRefinement;

      setCaseStep("CASE_CREATION");
      const caseInfo: ICaseRecord = {
        case_uid: case_uid,
        case_path: null,
        case_date: convertDate(case_date),
        acquisition_date: acquisition_date,
        case_type: case_type,
        scan_mode: scan_mode,
        scan_object: scan_object,
        device_sn: device_sn,
        pt_pid: pt_pid,
        pt_last_name: pt_last_name,
        pt_middle_name: pt_middle_name,
        pt_first_name: pt_first_name,
        pt_birthdate: selectedPatientDOB,
        pt_sex: selectedPatientGender,
        recipient: partner_pk,
        node_id: localNodeId,
        partner_case_id: sparkCaseId,
        is_refinement_case: caseIsRefinement,
        treatment_id: -1,
        transaction_id: transaction_id,
        software_name: software_name,
        software_version: software_version,
        model_name: model_name,
      };
      createDentalCase("spark", caseInfo)
        .then((response: IDentalCase) => {
          const caseId = response.caseId;
          setSubmittedDexisCaseId(caseId);
          onTransactionIdReceived(response.transactionId);
          setCaseStep("CASE_FINALIZATION");
        })
        .catch((err: any) => {
          setErrorMessage(err.message);
          setCaseStep("ERROR");
        });
    },
    [
      case_date,
      acquisition_date,
      case_type,
      case_uid,
      pt_first_name,
      pt_middle_name,
      pt_last_name,
      device_sn,
      isCaseRefinement,
      transaction_id,
      software_name,
      model_name,
      partner_pk,
      localNodeId,
      pt_pid,
      scan_mode,
      scan_object,
      software_version,
      selectedPatientGender,
      onTransactionIdReceived,
      selectedPatientDOB,
    ]
  );

  const getCases = useCallback(() => {
    setCaseStep("CONNEXION");
    getSparkCases(partner_pk, pt_first_name, pt_last_name, pt_middle_name)
      .then((data: ISparkCase[]) => {
        if (!data || data.length === 0) {
          setCaseStep("NO_CASES");
        } else {
          const subCases = data.filter(
            (c) => submittedStatuses.indexOf(c.status) >= 0
          );
          const shipCases = data.filter(
            (c) => shippedStatuses.indexOf(c.status) >= 0
          );
          setPatientSubmittedCases(subCases);
          setPatientShippedCases(shipCases);
          if (subCases.length > 0 || shipCases.length > 0) {
            if (subCases.length > 0) {
              setSelectedCaseId(subCases[0].caseId);
            } else if (shipCases.length > 0) {
              setSelectedCaseId(shipCases[0].caseId);
              setIsCaseRefinement(true);
            }
            setCaseStep("CASE_FOUND");
          } else {
            setCaseStep("NO_CASES");
          }
        }
      })
      .catch((err: any) => {
        setErrorMessage(err.message);
        setUserStep("ERROR");
      });
  }, [partner_pk, pt_first_name, pt_last_name, pt_middle_name]);

  useEffect(() => {
    if (userStep === "INIT") {
      if (lang) {
        const lang_str = getLanguageCode(lang);
        i18n.changeLanguage(lang_str);
      }

      const getUser = () => {
        setUserStep("AUTHENTICATION");

        let pk = 0;
        let nodeId = 0;
        getCurrentUser()
          .then((data: IConnectUser) => {
            pk = data.userPk;
            setUserPk(pk);
            setUserStep("CONNECTING");
            if (pk === 0) {
              throw new Error(errorMessages.noUserLogged);
            }
          })
          .then((res: any) => {
            if (transaction_id == null) {
              nodeId = res.node_id;
              setLocalNodeId(nodeId);
            } else {
              nodeId = -1;
            }
            setUserStep("CONNECTED");
          })
          .catch((error: any) => {
            if (error.message === errorMessages.noUserLogged) {
              setErrorMessage(errorMessages.noUserLogged);
            } else {
              setErrorMessage(errorMessages.requestFailure);
            }
            setUserStep("ERROR");
          });
      };
      getUser();
    }
    if (caseStep === "INIT") {
      if (userPk > 0) {
        try {
          getCases();
        } catch (error) {
          setErrorMessage(errorMessages.internalError);
        }
      }
    }
    if (caseStep === "NO_CASES") {
      if (patientGenderValid() && patientDOBValid()) {
        if (userStep === "CONNECTED") {
          // Create a dental case for a new case
          createConnectCase(undefined);
        }
      } else if (!patientGenderValid() && !patientDOBValid()) {
        setCaseStep("PATIENT_GENDER_AND_DOB_SELECTION");
      } else if (!patientGenderValid() && patientDOBValid()) {
        setCaseStep("PATIENT_GENDER_SELECTION");
      } else if (patientGenderValid() && !patientDOBValid()) {
        setCaseStep("PATIENT_DOB_SELECTION");
      }
    }

    if (caseStep === "CASE_FOUND" && userStep === "CONNECTED") {
      setCaseStep("CASE_SELECTION");
    }
  }, [
    userStep,
    caseStep,
    getCases,
    lang,
    transaction_id,
    userPk,
    patientDOBValid,
    patientGenderValid,
    createConnectCase,
  ]);

  const CustomButton = ({ onClick, text }: CustomButtonProps) => (
    <Button variant="contained" sx={{ bgcolor: "#009BB4" }} onClick={onClick}>
      {text}
    </Button>
  );

  return (
    <AppContainer>
      <img src={logo} alt="logo spark" />
      {caseStep === "CASE_SELECTION" && patientSubmittedCases.length > 0 && (
        <div>
          <FormContainer>
            <Message>{t("EXISTING_CASE_SUBMITTED1")}</Message>
            <MessageNoMargin>{t("EXISTING_CASE_SUBMITTED2")}</MessageNoMargin>
            <MessageNoMargin>{t("EXISTING_CASE_SUBMITTED3")}</MessageNoMargin>
            <MessageNoMargin>
              <a href="IS_Connect_Instructions.pdf" download target="_blank">
                {t("MORE_INFORMATION_LINK")}
              </a>
            </MessageNoMargin>
            <Message>{t("EXISTING_CASE_SUBMITTED4")}</Message>

            <RadioGroup
              className="radioButtons"
              aria-labelledby="submitted-cases-group-label"
              name="submitted-cases-group"
              value={selectedCaseId}
              onChange={onSelectedCaseChange}
            >
              {patientSubmittedCases.map((c: ISparkCase) => (
                <FormControlLabel
                  key={c.caseId}
                  value={c.caseId}
                  control={<CustomRadio />}
                  label={
                    <span className="radioLabel">{sparkCaseLabel(c)}</span>
                  }
                />
              ))}
              {patientShippedCases.map((c: ISparkCase) => (
                <FormControlLabel
                  key={c.caseId}
                  value={c.caseId}
                  control={<CustomRadio />}
                  label={
                    <span className="radioLabel">{sparkCaseLabel(c)}</span>
                  }
                />
              ))}
            </RadioGroup>
            <ButtonContainer>
              <CustomButton
                text={t("COPY_CASE_INFO")}
                onClick={copyCaseDetails}
              />
            </ButtonContainer>
          </FormContainer>
        </div>
      )}

      {caseStep === "CASE_SELECTION" &&
        patientSubmittedCases.length === 0 &&
        patientShippedCases.length > 0 && (
          <div>
            <FormContainer>
              <Message>{t("EXISTING_CASE_SHIPPED")}</Message>
              <div>
                <RadioGroup
                  className="radioButtons"
                  aria-labelledby="shipped-cases-group-label"
                  name="shipped-cases-group"
                  value={selectedCaseId}
                  onChange={onSelectedCaseChange}
                >
                  {patientShippedCases.map((c: ISparkCase) => (
                    <FormControlLabel
                      key={c.caseId}
                      value={c.caseId}
                      control={<CustomRadio />}
                      label={
                        <span className="radioLabel">{sparkCaseLabel(c)}</span>
                      }
                    />
                  ))}
                </RadioGroup>
              </div>
            </FormContainer>
            <ButtonContainer>
              <CustomButton text={t("OK")} onClick={processNewSparkCase} />
            </ButtonContainer>
          </div>
        )}

      {caseStep === "PATIENT_GENDER_SELECTION" && (
        <div>
          <FormContainer>
            <Message>{t("MISSING_PATIENT_GENDER")}</Message>
            <RadioGroup
              className="radioButtons"
              aria-labelledby="patient-gender-group-label"
              name="patient-gender-group"
              value={selectedPatientGender}
              onChange={(event: any) =>
                setSelectedPatientGender(event.target.value)
              }
            >
              <FormControlLabel
                value="M"
                control={<CustomRadio />}
                label={<span className="radioLabel">{t("Male")}</span>}
              />
              <FormControlLabel
                value="F"
                control={<CustomRadio />}
                label={<span className="radioLabel">{t("Female")}</span>}
              />
            </RadioGroup>
          </FormContainer>
          <ButtonContainer>
            <CustomButton text={t("OK")} onClick={processNewSparkCase} />
          </ButtonContainer>
        </div>
      )}

      {caseStep === "PATIENT_DOB_SELECTION" && (
        <div>
          <FormContainer>
            <Message>{t("MISSING_PATIENT_DOB")}</Message>
          </FormContainer>
          <BirthDateSelector>
            <p className="dob-label">{t("Date of birth")}</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="datepicker-container">
                <DatePicker onChange={handleDOBChange} />
              </div>
            </LocalizationProvider>
          </BirthDateSelector>
          <ButtonContainer>
            <CustomButton text={t("OK")} onClick={processNewSparkCase} />
          </ButtonContainer>
        </div>
      )}

      {caseStep === "PATIENT_GENDER_AND_DOB_SELECTION" && (
        <div>
          <FormContainer>
            <Message>{t("MISSING_PATIENT_GENDER_AND_DOB")}</Message>
            <RadioGroup
              className="radioButtons"
              aria-labelledby="patient-gender-group-label"
              name="patient-gender-group"
              value={selectedPatientGender}
              onChange={(event: any) =>
                setSelectedPatientGender(event.target.value)
              }
            >
              <FormControlLabel
                value="M"
                control={<CustomRadio />}
                label={<span className="radioLabel">{t("Male")}</span>}
              />
              <FormControlLabel
                value="F"
                control={<CustomRadio />}
                label={<span className="radioLabel">{t("Female")}</span>}
              />
            </RadioGroup>
            <p className="dob-label">{t("Date of birth")}</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="datepicker-container">
                <DatePicker onChange={handleDOBChange} />
              </div>
            </LocalizationProvider>
          </FormContainer>
          <div>
            <ButtonContainer>
              <CustomButton text={t("OK")} onClick={processNewSparkCase} />
            </ButtonContainer>
          </div>
        </div>
      )}

      {caseStep === "CASE_FINALIZATION" && (
        <div>
          <h5>
            {t("Your request has been processed with the following Case ID:")}{" "}
            <strong>{submittedDexisCaseId}</strong>
          </h5>
          <h5>{t("You can close this window.")}</h5>
        </div>
      )}
      {(caseStep === "ERROR" || userStep === "ERROR") && (
        <div className="error">
          <h5>{t("ERROR_OCCURRED_WARNING")}</h5>
          <h5> {t(errorMessage)}</h5>
        </div>
      )}
    </AppContainer>
  );
};

export default Spark;
