import React from "react";

const SortAtoZIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="16"
      viewBox="0 0 19 16"
    >
      <g transform="translate(-712.858 -526.715)">
        <g transform="translate(-2941.642 1457.5)">
          <path d="M12,1.1H0V-1.1H12Z" transform="translate(3654.5 -928.5)" />
          <path d="M9,1.1H0V-1.1H9Z" transform="translate(3654.5 -923.5)" />
          <path d="M6,1.1H0V-1.1H6Z" transform="translate(3654.5 -918.5)" />
        </g>
        <g transform="translate(733.015 526.715) rotate(90)">
          <path d="M13.5,1H0V-1H13.5Z" transform="translate(0 5.015)" />
          <path
            d="M3.141,4.555-.707.707.707-.707,3.141,1.727,5.575-.707,6.99.707Z"
            transform="translate(11.429 8.156) rotate(-90)"
          />
        </g>
      </g>
    </svg>
  );
};

export default SortAtoZIcon;
