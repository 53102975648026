import { ICreationLocation, ICreationUser, ILocation } from "./models";
import http from "./http";
import getLoadedConfig from "../appConfigService";



export interface ILocationList {
  count: number;
  value: ILocation[];
}

export async function getLocationById(id: string) {
  const config = getLoadedConfig();
  const url =
    config.globalApiODataUrl +
    `Locations/${id}?$expand=account($expand=region)`;
  const { data } = await http.get<ILocation>(url);
  return data;
}

export async function createLocation(locationData: ICreationLocation) {
  const config = getLoadedConfig();
  const url = config.globalApiODataUrl + "Locations";
  const { data } = await http.post(url, locationData);
  return data;
}

export async function addUserToUserLocations(userData: ICreationUser) {
  const config = getLoadedConfig();
  const url = config.globalApiODataUrl + `UserLocations`;
  const { data } = await http.post(url, userData);
  return data;
}

export async function removeUserFromLocation(
  locationId: string,
  userId: string
) {
  const config = getLoadedConfig();
  const url =
    config.globalApiUrl +
    `UserLocations/removeUserFromLocation?locationId=${locationId}&userId=${userId}`;
  await http.delete(url);
}
