import React from "react";

const DownloadArrowIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16.1"
      viewBox="0 0 18 16.1"
    >
      <g transform="translate(-1464.5 -1255.655)">
        <g transform="translate(1478.515 1255.656) rotate(90)">
          <path d="M12,1.1H0V-1.1H12Z" transform="translate(0 5.015)" />
          <path
            d="M5.015,6.57-.778.778.778-.778,5.015,3.459,9.251-.778,10.807.778Z"
            transform="translate(6.74 10.029) rotate(-90)"
          />
        </g>
        <path d="M18,1.1H0V-1.1H18Z" transform="translate(1464.5 1270.656)" />
      </g>
    </svg>
  );
};

export default DownloadArrowIcon;
