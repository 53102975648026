import React from "react";

const ThreeDViewIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g transform="translate(1793 -4822)">
        <g transform="translate(-3242.946 3537)">
          <text
            transform="translate(1455.382 1301.531)"
            fontSize="11"
            fontFamily="ArialMT, Arial"
          >
            <tspan x="0" y="0">
              3D
            </tspan>
          </text>
          <g transform="translate(1450.946 1286)">
            <g>
              <path d="M11.371,23.061h-.02a11.2,11.2,0,0,1-5-1.177A11.345,11.345,0,0,1,0,11.656v-.25H1.784v.25a9.51,9.51,0,0,0,4.669,8.255l1.206-1.839L12.1,21.585c.223.145.777.534.619.99-.138.4-1.281.5-1.352.486" />
              <path d="M11.5,0h.02a11.2,11.2,0,0,1,5,1.177,11.345,11.345,0,0,1,6.352,10.228v.25H21.088v-.25a9.51,9.51,0,0,0-4.669-8.255L15.213,4.99,10.768,1.477c-.223-.145-.777-.534-.619-.99C10.287.09,11.43-.01,11.5,0" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ThreeDViewIcon;
