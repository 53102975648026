import React from "react";

const MenuIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="4"
      viewBox="0 0 18 4"
    >
      <g transform="translate(-916 -3720) rotate(90)">
        <circle cx="2" cy="2" r="2" transform="translate(3720 -934)" />
        <circle cx="2" cy="2" r="2" transform="translate(3720 -927)" />
        <path
          d="M2,0A2,2,0,1,1,0,2,2,2,0,0,1,2,0Z"
          transform="translate(3720 -927)"
        />
        <circle cx="2" cy="2" r="2" transform="translate(3720 -920)" />
      </g>
    </svg>
  );
};

export default MenuIcon;
