import React from "react";
import { NoPatientIsSelectedContainer } from "./NoDataCard.styled";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

interface NoDataCardProps {
  title: string;
  message: string;
}

const NoDataCard: React.FC<NoDataCardProps> = ({ title, message }) => {
  return (
    <NoPatientIsSelectedContainer>
      <PriorityHighIcon />
      <span>{title}</span>
      <p>{message}</p>
    </NoPatientIsSelectedContainer>
  );
};

export default NoDataCard;
