import {
  IDicomSeries,
  IBlobFile,
  IPatient,
  ICaseInfo,
} from "./models";
import http from "./http";

export interface IPatientResult {
  value: IPatient[];
  "@odata.count": number;
}

export async function getPatientsList(
  regionBaseApi: string,
  sortOrder: "asc" | "desc",
  top: number,
  skip: number,
  query: string = ""
) {
  const url =
    regionBaseApi +
    `odata/v1/Patients?$count=true&$orderby=lastName ${sortOrder}&$top=${top}&$skip=${skip}&$filter=contains(concat(concat(concat(concat(firstName,' '), lastName), ' '), firstName), '${query}')`;
  const { data } = await http.get<IPatientResult>(url);
  return { patients: data.value, totalCount: data["@odata.count"] };
}

export async function getDicomSeriesList(
  patientId: string,
  regionBaseApi: string
) {
  const url =
    regionBaseApi + `api/v1/DicomSeries/GetPatientData?patientId=${patientId}`;
  const { data } = await http.get<IDicomSeries[]>(url);
  return data;
}

export async function getDicomInstanceUrl(
  regionBaseApi: string,
  seriesId: string
) {
  const url =
    regionBaseApi + `api/v1/DicomSeries/GetDicomFileUrl?seriesId=${seriesId}`;
  const { data } = await http.get<string>(url);
  return data;
}

export async function getAllDicomSeries(
  regionBaseApi: string,
  locationId: string
) {
  const url =
    regionBaseApi + `api/v1/DicomSeries/GetAllDicoms?locationId=${locationId}`;
  const { data } = await http.get<IDicomSeries[]>(url);
  return data;
}

export async function getAllBlobFiles(
  regionBaseApi: string,
  locationId: string
) {
  const url =
    regionBaseApi + `api/v1/BlobFiles/GetAllBlobFiles?locationId=${locationId}`;
  const { data } = await http.get<IBlobFile[]>(url);
  return data;
}

export async function getBlobFilesList(
  patientId: string,
  regionBaseApi: string
) {
  const url =
    regionBaseApi + `api/v1/BlobFiles/GetPatientData?patientId=${patientId}`;
  const { data } = await http.get<IBlobFile[]>(url);
  return data;
}

export async function getBlobFileUrl(regionBaseApi: string, recordId: string) {
  const url =
    regionBaseApi + `api/v1/BlobFiles/GetBlobFileUrl?recordId=${recordId}`;
  const { data } = await http.get<string>(url);
  return data;
}

export async function getSeriesDataUrl(
  regionBaseApi: string,
  seriesId: string,
  selectedFormat: string,
  withAttachedFiles: boolean
) {
  const url =
    regionBaseApi +
    `api/v1/DicomSeries/DownloadSeriesData?seriesId=${seriesId}&format=${selectedFormat}&withAttachedFiles=${withAttachedFiles}`;
  const { data } = await http.get<string>(url);
  return data;
}

export async function getConnectCasesInfo(
  seriesId: string,
  regionBaseApi: string
) {
  const url =
    regionBaseApi +
    `api/v1/DicomSeries/getConnectCasesInfo?seriesId=${seriesId}`;
  const { data } = await http.get<ICaseInfo[]>(url);
  return data;
}

export async function FetchTreatmentDataUrl(
  seriesRecordId: string,
  regionBaseApi: string
) {
  const url =
    regionBaseApi +
    `api/v1/BlobFiles/GetTreatmentDataFile?seriesRecordId=${seriesRecordId}`;
  const { data } = await http.get<string>(url);
  return data;
}
