import React from "react";

const FeedbackIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="25"
      height="21"
      viewBox="0 0 25 21"
    >
      <defs>
        <clipPath id="a">
          <rect width="25" height="21" />
        </clipPath>
      </defs>
      <g transform="translate(0 0)">
        <g transform="translate(0 0)" clipPath="url(#a)">
          <path
            d="M25.938,7.985H21.073v4.507a2.648,2.648,0,0,1-2.648,2.648H13.115v1.618a1.349,1.349,0,0,0,1.349,1.349h5.051L23.5,22.1a.675.675,0,0,0,1.152-.478V18.107h1.281a1.348,1.348,0,0,0,1.348-1.349V9.333a1.348,1.348,0,0,0-1.348-1.348"
            transform="translate(-2.127 -1.295)"
          />
          <path
            d="M15.92,0H1.675A1.674,1.674,0,0,0,0,1.674v9.219a1.675,1.675,0,0,0,1.675,1.675H3.264v4.36a.838.838,0,0,0,1.431.593l4.953-4.953H15.92a1.675,1.675,0,0,0,1.675-1.675V1.674A1.674,1.674,0,0,0,15.92,0M5.01,7.56A1.257,1.257,0,1,1,6.266,6.3,1.257,1.257,0,0,1,5.01,7.56m4.189,0A1.257,1.257,0,1,1,10.456,6.3,1.257,1.257,0,0,1,9.2,7.56m4.189,0A1.257,1.257,0,1,1,14.645,6.3,1.257,1.257,0,0,1,13.388,7.56"
            transform="translate(0 0)"
          />
        </g>
      </g>
    </svg>
  );
};

export default FeedbackIcon;
