import React from "react";

const SortAtoZIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="16"
      viewBox="0 0 19 16"
    >
      <g transform="translate(0 1.414)">
        <g transform="translate(0 1.186)">
          <path d="M12-1.1H0V1.1H12Z" transform="translate(0 11.1)" />
          <path d="M9-1.1H0V1.1H9Z" transform="translate(0 6.1)" />
          <path d="M6-1.1H0V1.1H6Z" transform="translate(0 1.1)" />
        </g>
        <g transform="translate(12.001 14.571) rotate(-90)">
          <path d="M13.5,1H0V-1H13.5Z" transform="translate(0 3.141)" />
          <path
            d="M.707,3.848-.707,2.434,3.141-1.414,6.99,2.434,5.575,3.848,3.141,1.414Z"
            transform="translate(14.571) rotate(90)"
          />
        </g>
      </g>
    </svg>
  );
};

export default SortAtoZIcon;
