import styled from "styled-components";
import { colors } from "../../../theme";

export const StyledDicomSeriesCard = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  width: 144px;
  height: 170px;
  background-color: ${colors["gray-1"]};
  box-shadow: 0 1px 5px ${colors["gray-4"]};
  border: 1px solid ${colors["gray-4"]};
  padding: 8px 8px 0 8px;

  & img {
    box-sizing: border-box;
    height: 126px;
    width: 126px;
  }
`;

export const TimeStampSpan = styled.span`
  margin-top: auto;
  padding: 11px 0;
  font-size: 11px;
  color: ${colors["gray-10"]};
  font-family: "Arial";
`;

export const IconsContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  bottom: 36px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 0 8px;

  & > button {
    padding: 0;
    width: 32px;
    height: 32px;
  }

  :focus {
    outline: none;
  }
`;
