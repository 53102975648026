import styled from "styled-components";
import dentistImage from "../../assets/images/dentist-background.png";
import { colors, media } from "../../theme";
import { Link } from "react-router-dom";

export const LandingPageContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-image: url(${dentistImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 25% 40%;

  ${media.phoneL} {
    background-position: 20% 40%;
  }
`;

export const MainContent = styled.main`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 95%;
  text-align: center;
  padding: 20px 5px 20px 10px;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.8);
  font-family: Arial, Helvetica, sans-serif;

  & h1 {
    min-width: max-content;
    font-size: 34px;
    font-weight: 800;
    text-align: left;
    text-shadow: 1px 2px 6px ${colors["gray-5"]};
  }

  ${media.laptop} {
    & h1 {
      font-size: 32px;
    }
  }

  ${media.tablet} {
    & h1 {
      font-size: 28px;
      letter-spacing: -0.5px;
    }
  }

  ${media.phoneL} {
    flex-direction: column;

    & h1 {
      font-size: 34px;
      letter-spacing: 0;
      margin-bottom: 40px;
    }
  }
`;

export const LogoContainer = styled.div`
  box-sizing: border-box;

  & img {
    max-width: 330px;
    width: 100%;
    min-width: 245px;
  }

  ${media.phoneL} {
    & img {
      min-width: unset;
      max-width: 276px;
    }
  }
`;

export const LoginButton = styled.button`
  display: block;
  width: 176px;
  margin: 10px auto 0 auto;
  padding: 10px 20px;
  border: none;
  font-weight: 600;
  font-size: 15px;
  background-color: ${colors["orange-1"]};
  color: white;
  box-shadow: 1px 3px 7px ${colors["gray-5"]};
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    background-color: ${colors["orange-2"]};
  }

  ${media.tablet} {
    margin-top: 0;
  }

  ${media.phoneL} {
    margin: 10px auto;
  }
`;

export const TermsOfUseLink = styled(Link)`
  display: block;
  margin-bottom: 3vh;
  color: ${colors["orange-1"]};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors["orange-2"]};
  }
`;
