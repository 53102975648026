import React, { useState, useEffect, useCallback } from "react";
import { IDentalCaseStatus } from "../../../services/api/models";
import {
  getCasesFromTransactionId,
  getCaseStatus,
  IStatus,
} from "../../../services/api/caseService";
import usePersistedQueryParams from "../../../shared/usePersistedAppQueryParams";
import { useTranslation } from "react-i18next";
import {
  AppContainer,
  ButtonContainer,
  Message,
} from "./RedirectPartner.styled";

type UserStepType =
  | "INIT"
  | "CONNECTING"
  | "WAITING"
  | "REDIRECTING"
  | "ERROR"
  | "DONE";

const RedirectPartner: React.FC = () => {
  const { t } = useTranslation();
  const [userStep, setUserStep] = useState<UserStepType>("INIT");
  const [caseId, setCaseId] = useState("");
  const [status, setStatus] = useState<IStatus>();
  const [partnerCaseDirectLink, setPartnerCaseDirectLink] = useState("");

  const { transaction_id } = usePersistedQueryParams();

  const getStatus = useCallback(async (transaction_id: string | null) => {
    getCasesFromTransactionId(transaction_id).then(
      (data: IDentalCaseStatus | null) => {
        if (data === null) {
          setUserStep("ERROR");
          return null;
        }
        setCaseId(data.caseId);
        setPartnerCaseDirectLink(data.partnerCaseDirectLink);
        const newStatus = getCaseStatus(data.status);
        setStatus((prevState) => {
          if (prevState?.state === newStatus.state) {
            return prevState;
          } else {
            return newStatus;
          }
        });
      }
    );
  }, []);

  useEffect(() => {
    if (transaction_id === null) {
      setUserStep("ERROR");
      return;
    }

    if (status) {
      if (status.state === "Wait") {
        setUserStep("WAITING");
      } else if (status.state === "Done") {
        setUserStep("DONE");
        window.location.href = partnerCaseDirectLink;
      } else {
        setUserStep("ERROR");
      }
    }
  }, [transaction_id, status, partnerCaseDirectLink]);

  useEffect(() => {
    if (userStep === "INIT") {
      getStatus(transaction_id);
    }

    if (userStep === "WAITING") {
      const startTime = new Date().getTime();
      const interval = setInterval(() => {
        if (new Date().getTime() - startTime > 180000) {
          window.location.href = partnerCaseDirectLink
          return;
        }
        getStatus(transaction_id)
      }, 2000);
      return () => clearTimeout(interval)
    }
  }, [userStep, partnerCaseDirectLink, transaction_id, getStatus]);

  return (
    <AppContainer>
      {userStep === "INIT" && (
        <Message>
          <h5>{t("Sending case")}</h5>
          <h5>{t("Please wait")}</h5>
        </Message>
      )}
      {userStep === "WAITING" && (
        <Message>
          <h5>
            {t("Sending case")} {caseId}
          </h5>
          <h5>{t("Please wait while the case is being processed")}</h5>
        </Message>
      )}
      {userStep === "DONE" && (
        <Message>
          <h5>{t("Redirecting")}</h5>
        </Message>
      )}
      {userStep === "ERROR" && (
        <div className="error">
          <h5>{t("ERROR_OCCURRED_WARNING")}</h5>
          <h5>{t("Please close the window and try again")}</h5>
          <ButtonContainer />
        </div>
      )}
    </AppContainer>
  );
};

export default RedirectPartner;
