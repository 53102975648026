import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { IBlobFile, ILocation, IRX } from "../../../services/api/models";
import { useParams } from "react-router-dom";
import { Refresh } from "@mui/icons-material";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import RXCard from "../../../components/shared/RXs/RXCard";
import "react-vertical-timeline-component/style.min.css";
import { colors } from "../../../theme";
import {
  getAllDicomSeries,
  getAllBlobFiles,
} from "../../../services/api/patientService";
import { getLocationById } from "../../../services/api/locationService";
import DateRangeSelector from "./DateRangeSelector/DateRangeSelector";
import {
  TimelineContainer,
  SearchForm,
  ContentHeader,
  MainView,
  SearchContainer,
} from "./Timeline.styled";
import NoDataCard from "../../../components/shared/NoDataCard/NoDataCard";
import Loader from "../../../components/shared/Loader/Loader";
import { normalizeDateTime } from "../../../utils/dateTimeHelper";
import { isWithinInterval, parse } from "date-fns";
import SearchIcon from "../../../components/svg-icons/SearchIcon";
import TooltipMUI from "../../../components/shared/TooltipMUI/TooltipMUI";
import {
  ClearButton,
  IconsButton,
} from "../../../components/common/buttons.styled";
import PatientsListViewIcon from "../../../components/svg-icons/PatientsListViewIcon";
import DeleteIcon from "../../../components/svg-icons/DeleteIcon";
import useAuthService from "../../../services/authService";

interface TimelineProps {
  onShowPatientList: () => void;
}

interface Range {
  startDate: Date | any;
  endDate: Date | any;
}

const Timeline: React.FC<TimelineProps> = ({ onShowPatientList }) => {
  const params: any = useParams<{ locId: string }>();
  const locationId = params.locId;
  const [location, setLocation] = useState<ILocation>();
  const [filteredFiles, setFilteredFiles] = useState<any>();
  const [originalFiles, setOriginalFiles] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentInput, setCurrentInput] = useState("");
  const [dateRange, setDateRanges] = useState({
    startDate: null,
    endDate: null,
  });
  const [paymentStatus, setPaymentStatus] = useState<boolean>(false);
  const { isPaidLocation } = useAuthService();

  const getAllFiles = useCallback(async () => {
    if (!location) {
      return [[], []];
    }
    try {
      const dicomFilesPromise = getAllDicomSeries(
        location.account.region.apiUrl,
        location.regionalLocationId
      );

      const blobFilesPromise = getAllBlobFiles(
        location.account.region.apiUrl,
        location.regionalLocationId
      );

      const [dicoms, blobs] = await Promise.all([
        dicomFilesPromise,
        blobFilesPromise,
      ]);

      return [dicoms, blobs];
    } catch (error) {
      console.error("Error fetching files:", error);
      return [[], []];
    }
  }, [location]);

  const groupFilesByDate = useCallback((dicoms: any, blobFiles: any) => {
    const fetchedRxs: IRX[] = [];
    const groupedFiles: any = [];

    for (const dicomSeries of dicoms as any) {
      const newRX: IRX = {
        seriesInstanceUid: dicomSeries.seriesInstanceUid,
        dicom: dicomSeries,
        attachedFiles: [],
      };
      fetchedRxs.push(newRX);
    }

    for (const blobfile of blobFiles as IBlobFile[]) {
      const existingRX = fetchedRxs.find(
        (rx: IRX) =>
          blobfile.seriesInstanceUid &&
          rx.seriesInstanceUid === blobfile.seriesInstanceUid
      );
      if (existingRX) {
        existingRX.attachedFiles?.push(blobfile);
      } else {
        const existingRxByPatientId = fetchedRxs.find(
          (rx: IRX) => rx.attachedFiles[0]?.patientId === blobfile.patientId
        );

        if (existingRxByPatientId) {
          existingRxByPatientId.attachedFiles.push(blobfile);
        } else {
          const newRX: any = {
            seriesInstanceUid: blobfile.seriesInstanceUid,
            dicom: null,
            attachedFiles: [blobfile],
          };
          fetchedRxs.push(newRX);
        }
      }
    }

    fetchedRxs.forEach((rx: IRX) => {
      const latestRecord = rx.attachedFiles?.sort(
        (a: IBlobFile, b: IBlobFile) =>
          new Date(b.recordLastUpdated) > new Date(a.recordLastUpdated) ? 1 : -1
      )[0]?.recordLastUpdated;

      let result;
      if (latestRecord === undefined) {
        result = rx.dicom?.acquisitionDateTime;
      } else {
        const normalizedAcquisitionDateTime = new Date(
          normalizeDateTime(rx.dicom?.acquisitionDateTime)
        );
        const normalizedLatestRecord = normalizeDateTime(latestRecord);
        if (
          normalizedAcquisitionDateTime.getTime() >
          normalizedLatestRecord.getTime()
        ) {
          result = rx.dicom?.acquisitionDateTime;
        } else {
          result = latestRecord;
        }
      }

      const normalizedDate = normalizeDateTime(result)
        .toString()
        .substring(4, 15);

      if (!groupedFiles[normalizedDate]) {
        groupedFiles[normalizedDate] = [];
      }
      groupedFiles[normalizedDate].push({ ...rx });
    });

    const groupedFilesArray = Object.entries(groupedFiles);
    groupedFilesArray.sort(([dateA], [dateB]) => {
      return new Date(dateB).getTime() - new Date(dateA).getTime();
    });

    const sortedGroupedFiles = groupedFilesArray.reduce(
      (acc, [date, files]) => {
        acc[date] = files;
        return acc;
      },
      {} as any
    );

    return sortedGroupedFiles;
  }, []);

  useEffect(() => {
    const getLocationInfo = async () => {
      try {
        const locationData = await getLocationById(locationId);
        setLocation(locationData);
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };
    getLocationInfo();
  }, [locationId]);

  useEffect(() => {
    const checkPaymentStatus = async () => {
      const status = await isPaidLocation();
      setPaymentStatus(status);
      return status;
    };

    if (!location) {
      return;
    }
    const getinitialData = async (paymentStatus: boolean) => {
      // If the user is not a paid user, do no fetch data
      if (!paymentStatus) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      const [newDicoms, newBlobs] = await getAllFiles();
      const groupedFiles = groupFilesByDate(newDicoms, newBlobs);
      setOriginalFiles(groupedFiles);
      setFilteredFiles(groupedFiles);
      setIsLoading(false);
    };
    checkPaymentStatus().then((status) => {
      getinitialData(status);
    });
  }, [location, getAllFiles, groupFilesByDate, isPaidLocation]);

  const applyFilters = useCallback((query: string, range: Range, files: []) => {
    let filteredData: any = { ...files };
    let searchedInput = query.trim().toLowerCase();

    if (query) {
      filteredData = Object.fromEntries(
        Object.entries(files)
          .map(([date, files]) => {
            const filteredFiles = (files as any).filter((file: any) => {
              const dicomName =
                `${file?.dicom?.firstName} ${file?.dicom?.lastName}`.toLowerCase();
              const attachedNames =
                file.attachedFiles
                  ?.map((attached: any) =>
                    `${attached.firstName} ${attached.lastName}`.toLowerCase()
                  )
                  .join(" ") ?? "";
              return (
                dicomName.includes(searchedInput) ||
                attachedNames.includes(searchedInput)
              );
            });
            return filteredFiles.length > 0 ? [date, filteredFiles] : [];
          })
          .filter((entry) => entry.length !== 0)
      );
    }

    if (range.startDate && range.endDate) {
      filteredData = Object.fromEntries(
        Object.entries(filteredData).filter(([date]) => {
          const currentDate = parse(date, "MMM dd yyyy", new Date());
          return isWithinInterval(currentDate, {
            start: range.startDate,
            end: range.endDate,
          });
        })
      );
    }
    setFilteredFiles(filteredData);
  }, []);

  const manualRefresh = useCallback(async () => {
    if (!location) {
      return;
    }
    // If the user is not a paid user, do no fetch data
    if (!paymentStatus) {
      return;
    }
    setIsLoading(true);
    const [newDicoms, newBlobs] = await getAllFiles();
    const groupedFiles = groupFilesByDate(newDicoms, newBlobs);
    setOriginalFiles(groupedFiles);
    applyFilters(searchQuery, dateRange, groupedFiles);
    setIsLoading(false);
  }, [
    location,
    getAllFiles,
    searchQuery,
    dateRange,
    groupFilesByDate,
    applyFilters,
    paymentStatus,
  ]);

  const handleDateChange = (ranges: Range) => {
    setDateRanges(ranges);
    applyFilters(searchQuery, ranges, originalFiles);
  };

  const getPatientName = useCallback((files: any) => {
    const fullName = `${
      files.dicom?.firstName || files.attachedFiles[0]?.firstName || ""
    } ${
      files.dicom?.lastName || files.attachedFiles[0]?.lastName || ""
    }`.trim();
    return fullName || "Not available";
  }, []);

  const onSearchSubmitHandle = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const formData = new FormData(e.currentTarget);
      const query = (formData.get("searchInput") as string) || "";
      setSearchQuery(query);
      applyFilters(query, dateRange, originalFiles);
    },
    [applyFilters, dateRange, originalFiles]
  );

  const handleClearInput = () => {
    setCurrentInput("");
    setSearchQuery("");
    applyFilters("", dateRange, originalFiles);
  };

  return (
    <TimelineContainer>
      <ContentHeader>
        <SearchContainer>
          <SearchForm onSubmit={onSearchSubmitHandle}>
            <label>
              <SearchIcon />
              <input
                type="text"
                placeholder="Search Patient"
                name="searchInput"
                onChange={(e) => setCurrentInput(e.target.value)}
                value={currentInput}
              />
              <ClearButton
                type="button"
                onClick={handleClearInput}
                className={
                  currentInput.length > 0 ? "clear-button-visible" : ""
                }
              >
                <DeleteIcon />
              </ClearButton>
            </label>
          </SearchForm>
          <TooltipMUI title={["Refresh data"]}>
            <IconsButton onClick={manualRefresh}>
              <Refresh />
            </IconsButton>
          </TooltipMUI>

          <TooltipMUI title={["Change view"]}>
            <IconsButton onClick={onShowPatientList}>
              <PatientsListViewIcon />
            </IconsButton>
          </TooltipMUI>
        </SearchContainer>
        <h1>History Timeline</h1>
        <DateRangeSelector
          onDateRangeChange={handleDateChange}
          data={originalFiles}
        />
      </ContentHeader>
      <MainView>
        {!paymentStatus ? (
          <NoDataCard
            title="Subscription Required"
            message="Access to this feature requires a paid account. Please upgrade to access your files."
          />
        ) : isLoading === true ? (
          <Loader />
        ) : (
          <>
            {location &&
            filteredFiles &&
            Object.keys(filteredFiles).length > 0 ? (
              <VerticalTimeline
                layout="1-column-left"
                animate={false}
                lineColor={`${colors["grey-color"]}`}
              >
                {Object.keys(filteredFiles).map((date: string, index) => (
                  <div key={index}>
                    <span>{date}</span>
                    {filteredFiles[date].map(
                      (files: IRX, filesIndex: number) => (
                        <VerticalTimelineElement key={filesIndex}>
                          <RXCard
                            patient={{
                              patientId: files.dicom?.patientId,
                              firstName: files.dicom?.firstName,
                              lastName: files.dicom?.lastName,
                            }}
                            rx={files}
                            location={location}
                            key={files.seriesInstanceUid}
                          />
                          <span>{getPatientName(files)}</span>
                        </VerticalTimelineElement>
                      )
                    )}
                  </div>
                ))}
              </VerticalTimeline>
            ) : (
              <NoDataCard
                title="No Data Found"
                message="Sorry, no data found for the selected criteria. Please try a different search or refine filters."
              />
            )}
          </>
        )}
      </MainView>
    </TimelineContainer>
  );
};

export default Timeline;
