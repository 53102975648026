import React, { useEffect, useState } from "react";
import { IDicomSeries, ILocation } from "../../../services/api/models";
import { getDicomInstanceUrl } from "../../../services/api/patientService";
import getLoadedConfig from "../../../services/appConfigService";
import {
  IconsContainer,
  StyledDicomSeriesCard,
  TimeStampSpan,
} from "./DicomSeriesCard.styled";
import DownloadButton from "./DownloadButton";
import ThreeDViewIcon from "../../svg-icons/ThreeDViewIcon";
import { IconsButton } from "../../common/buttons.styled";
import TooltipMUI from "../TooltipMUI/TooltipMUI";
import ModelButton from "./DicomAppsButton";
import useAuthService from "../../../services/authService";

interface DicomSeriesCardProps {
  dicomSeries: IDicomSeries;
  location: ILocation;
  showDownload: boolean;
}

const DicomSeriesCard: React.FC<DicomSeriesCardProps> = ({
  dicomSeries,
  location,
  showDownload,
}) => {
  const isMeshFile = dicomSeries.modality === "ES";
  const authService = useAuthService();
  const [userOid, setUserOid] = useState("");

  async function openInstanceInMeshViewer(
    dicomId: string,
    apiUrl: string
  ): Promise<void> {
    const response = await getDicomInstanceUrl(apiUrl, dicomId);
    const meshviewerUrl = getLoadedConfig().meshviewerUrl;
    const url = meshviewerUrl + "/?url=" + encodeURIComponent(response);
    window.open(url, "_blank");
  }

  const formatDate = (date: string) => {
    const year = date.slice(2, 4); // Get the last two digits of the year
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);
    const hours = parseInt(date.slice(8, 10)); // Convert hours to a number
    const minutes = date.slice(10, 12);
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = (hours % 12 || 12).toString().padStart(2, "0"); // Convert hours to 12-hour format
    return `${month}/${day}/${year} ${formattedHours}:${minutes} ${ampm}`;
  };

  useEffect(() => {
    const fetchUser = async () => {
      const oid = await authService.getUserOid();
      setUserOid(oid);
    };
    fetchUser();
  }, [authService]);

  return (
    <StyledDicomSeriesCard>
      <IconsContainer>
        {showDownload && (
          <DownloadButton dicomSeries={dicomSeries} location={location} />
        )}
        {isMeshFile && (
          <>
            <TooltipMUI title={["View your file"]} themeColor="gray">
              <IconsButton
                onClick={() =>
                  openInstanceInMeshViewer(
                    dicomSeries.recordId,
                    location.account.region.apiUrl
                  )
                }
              >
                <ThreeDViewIcon />
              </IconsButton>
            </TooltipMUI>
            {dicomSeries.caseType === "Orthodontics" && (
              <ModelButton
                locationId={location.regionalLocationId}
                regionalUrl={location.account.region.apiUrl}
                seriesId={dicomSeries.recordId}
                seriesInstanceUid={dicomSeries.seriesInstanceUid}
                userOid={userOid}
              />
            )}
          </>
        )}
      </IconsContainer>

      <img alt="Dicom thumbnail" src={dicomSeries.thumbnailUrl} />
      {dicomSeries.acquisitionDateTime && (
        <TimeStampSpan>
          {formatDate(dicomSeries.acquisitionDateTime)}
        </TimeStampSpan>
      )}
    </StyledDicomSeriesCard>
  );
};

export default DicomSeriesCard;
