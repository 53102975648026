import React from "react";
import { ErrorTitle, MessageContainer } from "./ErrorBoundary.styled";

interface IProps {
  title?: string;
  content?: string | React.ReactElement | React.ReactFragment;
  children: any;
}

interface IState {
  error?: Error;
}

class ErrorBoundary extends React.PureComponent<IProps, IState> {
  state: IState = {};
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error });
    console.error("Render Error:", error, errorInfo);
  }

  render() {
    if (this.state.error) {
      const { title = "Oops! Something went wrong :(" } = this.props;
      const content =
        this.props.content || "Please reload the page and try again.";

      return (
        <MessageContainer>
          <ErrorTitle>{title}</ErrorTitle>
          <p>{content}</p>
        </MessageContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
