import styled from "styled-components";
import { colors, media } from "../../theme";

export const MainContainer = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 70px);

  ${media.tablet} {
    min-height: calc(100vh - 65px);
  }
`;

export const ErrorBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  min-height: 70px;
  padding: 30px 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
`;

export const ErrorMessage = styled.p`
  font-weight: 600;
  color: ${colors["error-red-text"]};
  letter-spacing: 0.5px;
`;
