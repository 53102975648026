import styled from "styled-components";
import { colors } from "../../../theme";

export const NoPatientIsSelectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: fit-content;
  height: fit-content;
  padding: 50px 150px;
  margin: 10% auto 0 auto;
  background-color: whitesmoke;
  border-radius: 8px;
  box-shadow: 4px 4px 2px 1px rgb(0, 0, 0, 0.25);

  & span {
    color: ${colors["orange-1"]};
    font-weight: 700;
    font-size: 22px;
  }

  & svg {
    align-self: center;
    width: 64px;
    height: 64px;
    color: black;
    margin-bottom: 20px;
  }
`;
