import styled from "styled-components";
import { colors } from "../../../theme";

export const StyledBlobFileCard = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  width: 144px;
  height: 170px;
  background-color: ${colors["gray-1"]};
  box-shadow: 0 1px 5px ${colors["gray-4"]};
  border: 1px solid ${colors["gray-4"]};
  padding: 8px 8px 0 8px;
  outline: none;
  contain: paint;
  padding-top: 0;

  & .thumbnailImg {
    box-sizing: border-box;
    overflow: hidden;
    width: inherit;
    height: 137px;
    object-fit: cover;
  }

  & .thumbnailFile {
    box-sizing: border-box;
    object-fit: contain;
    max-height: 65px;
    max-width: 65px;
    margin: 35px auto;
  }

  & .tooltip {
    z-index: 10;
    padding: 1px 5px;
    font-size: 11px;
    background-color: ${colors["gray-10"]};
    border-radius: 0;
  }
`;

export const ButtonsContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  bottom: 35px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  & button {
    padding: 0;
    outline: none;
  }

  & button:first-child {
    & svg {
      width: 18px;
    }
  }
`;

export const FileInfo = styled.span`
  width: inherit;
  text-align: center;
  margin-top: auto;
  font-size: 11px;
  color: ${colors["gray-10"]};
  font-family: "Arial";
`;

export const TimeStampSpan = styled(FileInfo)`
  padding: 2px 0;
`;

export const FileName = styled(FileInfo)`
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  max-width: 97%;
  box-sizing: border-box;
  text-align: center;
`;
