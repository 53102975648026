import React, { useState, useEffect, useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BlockBlobClient } from "@azure/storage-blob";
import { Stepper, Step, StepLabel } from "@mui/material";
import { AttachFile, Clear, SyncRounded, Close } from "@mui/icons-material";
import getLoadedConfig from "../../../services/appConfigService";
import {
  BlobFileType,
  IBlobFile,
  IRX,
  ICaseCreation,
  IDicomSeries,
  ILocation,
  IPartner,
  IPatient,
  ICaseDetails,
  IUserAttachments,
  IStoredAttachments,
} from "../../../services/api/models";
import {
  createCase,
  getPartnersList,
  finalizePartnerCase,
  finalizeDentalCase,
} from "../../../services/api/partnerService";
import { FetchTreatmentDataUrl } from "../../../services/api/patientService";
import {
  CaseInformation,
  FormField,
  PartnerInformation,
  View,
  Title,
  Divider,
  LabContainer,
  LabCircle,
  LabLabel,
  PremiumPartner,
  NonPremiumPartner,
  LabsList,
  Actions,
  BaseButton,
  NextStepButton,
  LoaderContainer,
  CaseSentMessage,
  DueDate,
  AttachmentContainer,
  ErrorMessage,
  CaseDialog,
  MainContent,
  PartnerList,
  Attachment,
  RefreshButton,
  TitleWrapper,
  PceFrame,
} from "./ExportCase.styled";
import { XmlReader } from "./XMLParser";
import useAuthService from "../../../services/authService";
import Loader from "../../../components/shared/Loader/Loader";
import GreenCheck from "../../../assets/images/green-check.png";
import useTabVisibility from "../../../utils/useTabVisibility";
import { format, parse } from "date-fns";
import Spark from "../../../pages/partners/spark/Spark";
import Sprintray from "../../../pages/partners/sprintray/Sprintray";
import { IconsButton } from "../../common/buttons.styled";
import DeleteIcon from "../../../components/svg-icons/DeleteIcon";
import FileDialog from "./FileDialog";

interface IFile {
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
}

interface IPrescription {
  dueDate: {
    value: Date;
    isFetched: Boolean;
  };
  comment: {
    value: string;
    isFetched: Boolean;
  };
  recordIds: string[];
}

interface IndexationStateProps {
  partnerId: string;
  isFrameOpen: boolean;
  values: ICaseDetails;
}

interface ExportCaseProps {
  open: boolean;
  onClose: () => void;
  dicomSeries: IDicomSeries;
  location: ILocation;
  rx: IRX | null;
  patient: IPatient;
  patientBlobs: IBlobFile[];
}

const ExportCase: React.FC<ExportCaseProps> = ({
  open,
  onClose,
  dicomSeries,
  location,
  rx,
  patientBlobs,
  patient,
}) => {
  const { HideAttachmentButton } = getLoadedConfig();
  const [allPartners, setAllPartners] = useState<IPartner[]>([]);
  const [labPartners, setLabPartners] = useState<IPartner[]>([]);
  const [thirdPartyPremiumPartners, setThirdPartyPremiumPartners] = useState<
    IPartner[]
  >([]);
  const [thirdPartyNonPremiumPartners, setThirdPartyNonPremiumPartners] =
    useState<IPartner[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<IPartner | null>(null);
  const [canAdjustData, setCanAdjustData] = useState<boolean>();
  const [error, setError] = useState<{ type: string; message: string }>({
    type: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [creatingCase, setCreated] = useState(false);
  const { getAuthToken } = useAuthService();
  const [activeStep, setActiveStep] = useState(0);
  const isTabVisible = useTabVisibility();
  const [allSelectedAttachments, setAllSelectedAttachments] = useState<any[]>(
    []
  );
  const [allBlobAttachments, setAllBlobAttachments] = useState<any>({
    defaultAttachments: rx?.attachedFiles.concat(patientBlobs) || [],
    selectedAttachments: rx?.attachedFiles || [],
  });
  const [prescription, setPrescription] = useState<IPrescription>({
    dueDate: {
      value: new Date(),
      isFetched: false,
    },
    comment: {
      value: "",
      isFetched: false,
    },
    recordIds: [],
  });
  const rxData: any = rx;
  const config = getLoadedConfig();
  const [indexationState, setIndexationState] = useState<IndexationStateProps>({
    partnerId: "",
    isFrameOpen: false,
    values: {} as ICaseDetails,
  });
  const [showFileDialog, setShowFileDialog] = useState(false);

  const getPartners = useCallback(async () => {
    setIsLoading(true);
    setError({ type: "", message: "" });
    try {
      const response = await getPartnersList(
        dicomSeries.recordId,
        location.account.region.apiUrl
      );
      const labPart = [];
      const premiumPart = [];
      const nonPremiumPart = [];
      for (const partner of response) {
        if (partner.partner_type === "laboratory") {
          labPart.push(partner);
        } else if (partner.partner_type === "thirdparty") {
          partner.premium
            ? premiumPart.push(partner)
            : nonPremiumPart.push(partner);
        }
      }

      FetchTreatmentDataUrl(
        dicomSeries.recordId,
        location.account.region.apiUrl
      )
        .then((res) => {
          XmlReader(res).then((result) => {
            if (result && "treatment" in result) {
              const newSelectedPartner = response.find(
                (el) =>
                  el.partner_pk ===
                  result.treatment?.partnerLabPrimaryKey?._attributes.value
              );
              setSelectedPartner(newSelectedPartner || null);
              setCanAdjustData(true);
            }
          });
        })
        .catch((error) => {
          console.error("Error fetching treatment data: ", error);
        });

      setAllPartners(response);
      setLabPartners(labPart);
      setThirdPartyPremiumPartners(premiumPart);
      setThirdPartyNonPremiumPartners(nonPremiumPart);

      //selectedPartner is needed here. By using it, we have to include it in the dependency array. When updating the state for the SelectedPartner with the newly fetched data, we would cause infinite rerenders. Therefore we need to access the selectedPartner through prevState
      setSelectedPartner((prevState) => {
        const updatedSelectedPartner = response.find(
          (el) => el.partner_pk === prevState?.partner_pk
        );
        if (updatedSelectedPartner?.paired === false) {
          setError({
            type: "pairing",
            message: "Partner not activated. Activate this partner via",
          });
        }
        return updatedSelectedPartner || null;
      });
    } catch (error: any) {
      if (error.statusCode === 403) {
        setError({
          type: "connecting",
          message: "An IS Connect account is required, please sign up at",
        });
      } else {
        setError({
          type: "fetching",
          message:
            "Something went wrong while getting the data. Please try again!",
        });
      }
      console.error("Error fetching partners list:", error);
    }
    setIsLoading(false);
  }, [dicomSeries, location]);

  useEffect(() => {
    if (!isTabVisible) {
      return;
    }
    getPartners();
  }, [isTabVisible, getPartners]);

  const getFileName = (blobFile: any) => {
    return blobFile.name || blobFile.fileName;
  };

  const createNewCase = async (seriesId: string, recipient: string) => {
    if (isLoading || !selectedPartner?.paired) {
      return;
    }

    let storedAttachments: IStoredAttachments[] = [];
    let userAttachments: IUserAttachments[] = [];
    setError({ type: "", message: "" });
    setIsLoading(true);

    // Filter attachments to determine which ones are new attachments from user (without recordId)
    if (allSelectedAttachments.length > 0) {
      allSelectedAttachments.forEach(async (file: any) => {
        if (!file.recordId) {
          userAttachments.push({
            fileName: getFileName(file),
            fileSize: parseInt(file.size),
            file: file,
          });
        } else {
          storedAttachments.push({
            fileName: getFileName(file),
            recordId: file.recordId,
          });
        }
      });
    }

    try {
      const createdCase: ICaseCreation = {
        series_id: seriesId,
        recipient: recipient,
        due_date: prescription.dueDate.value.toISOString(),
        submission_comment: prescription.comment.value,
        user_attachments: userAttachments,
        partner_type: selectedPartner!.partner_type,
      };

      const response = await createCase(
        createdCase,
        location.account.region.apiUrl
      );

      if (response) {
        const caseId = response.dentalCase.caseId;
        const sasUrlResponses = response.fileUploads;
        let uploadPromises = userAttachments.map(async (attachment) => {
          const sasUrl = sasUrlResponses.find(
            (item: any) => item.fileName === getFileName(attachment)
          );
          if (sasUrl) {
            const blobClient = new BlockBlobClient(sasUrl.url);
            await blobClient.uploadData(attachment.file as File);
          }
        });

        await Promise.all(uploadPromises);

        // Prepare JSON Payload for finalizing the case
        const finalizedPayload = {
          series_id: seriesId,
          case_id: caseId,
          stored_attachments: storedAttachments,
          partner_type: selectedPartner.partner_type,
        };

        // Finalize the case with attachments
        finalizeDentalCase(location.account.region.apiUrl, finalizedPayload);

        setCreated(true);
        setTimeout(() => {
          setCreated(false);
          onClose();
        }, 2000);
      }
    } catch (error) {
      console.error("Error creating and sending the case: ", error);
      setCreated(false);
      setError({
        type: "general",
        message: "Something went wrong. Please try again!",
      });
    }
    setIsLoading(false);
  };

  const togglePartnerSelection = (pk: string) => {
    setError({ type: "", message: "" });

    if (selectedPartner?.partner_pk === pk) {
      setSelectedPartner(null);
      setActiveStep(0);
      setCanAdjustData(true);
      return;
    }

    const newSelectedPartner = allPartners.find((el) => el.partner_pk === pk);

    if (newSelectedPartner?.paired === false) {
      setError({
        type: "pairing",
        message: "Partner not activated. Activate this partner via",
      });
    }

    if (
      newSelectedPartner?.software_name === "csc_agent" &&
      newSelectedPartner?.partner_type !== "laboratory"
    ) {
      setActiveStep(1);
      setCanAdjustData(false);
    } else {
      setActiveStep(0);
      setCanAdjustData(true);
    }

    setSelectedPartner(newSelectedPartner || null);
  };

  const hasPrescription = (attachedFiles: IBlobFile[]) => {
    return attachedFiles.some(
      (item: IBlobFile) =>
        item.fileName.split(".").pop()?.toLowerCase() === "pdf" &&
        item.fileType === BlobFileType.Prescription_pdf
    );
  };

  useEffect(() => {
    const allAttachments = allBlobAttachments.selectedAttachments;
    setAllSelectedAttachments(allAttachments);

    const hasPrescriptionFiles = hasPrescription(rxData.attachedFiles);
    if (activeStep === 1 && hasPrescriptionFiles) {
      if (canAdjustData) {
        setIsLoading(true);
      }

      FetchTreatmentDataUrl(
        dicomSeries.recordId,
        location.account.region.apiUrl
      )
        .then((response) => {
          XmlReader(response)
            .then((result) => {
              if (result && "treatment" in result) {
                setPrescription({
                  dueDate: {
                    value:
                      result.treatment.deliveryDate._attributes.value === ""
                        ? new Date()
                        : new Date(
                            result.treatment.deliveryDate._attributes.value
                          ),
                    isFetched:
                      !!result.treatment.deliveryDate._attributes.value,
                  },
                  comment: {
                    value: result.treatment.comments._attributes.value,
                    isFetched: !!result.treatment.comments._attributes.value,
                  },
                  recordIds: [],
                });
              }
            })
            .finally(() => setIsLoading(false));
        })
        .catch((error) => {
          console.error("Error fetching partners list:", error);
        });
    }
  }, [
    activeStep,
    location,
    dicomSeries,
    rxData,
    canAdjustData,
    allBlobAttachments,
  ]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCloseError = () => {
    setError({ type: "", message: "" });
  };

  const removeFile = (fileToRemove: IBlobFile | IFile) => {
    const updatedAllAttachments = allSelectedAttachments.filter(
      (file: IBlobFile | IFile) => file !== fileToRemove
    );

    const updatedSelectedAttachments =
      allBlobAttachments.selectedAttachments.filter(
        (file: IBlobFile | IFile) => file !== fileToRemove
      );

    setAllSelectedAttachments(updatedAllAttachments);

    setAllBlobAttachments({
      defaultAttachments: allBlobAttachments.defaultAttachments,
      selectedAttachments: updatedSelectedAttachments,
    });
  };

  const handleDateChange = (date: Date) => {
    setPrescription({
      ...prescription,
      dueDate: {
        ...prescription.dueDate,
        value: date,
      },
    });
  };

  const getAcquisitionDate = (dicomSeries: IDicomSeries) => {
    let acquisitionDateTime =
      dicomSeries.acquisitionDateTime || dicomSeries.seriesDateTime;
    if (acquisitionDateTime && acquisitionDateTime.length === 14) {
      const parsedDate = parse(
        acquisitionDateTime,
        "yyyyMMddHHmmss",
        new Date()
      );
      const formatedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss");
      return formatedDate;
    } else if (acquisitionDateTime && acquisitionDateTime.length === 8) {
      const parsedDate = parse(acquisitionDateTime, "yyyyMMdd", new Date());
      return format(parsedDate, "yyyy-MM-dd");
    } else return "";
  };

  const onCaseFinalization = (transactionId: string) => {
    finalizePartnerCase(
      location.account.region.apiUrl,
      dicomSeries.recordId,
      transactionId
    )
      .then(() => {
        let domain = window.location.origin;
        let url =
          domain +
          `/redirect-partner?transaction_id=${transactionId}&embedded=true`;
        window.open(url, "_blank");
      })
      .catch((error: any) => {
        console.error("Error creating and sending the case: ", error);
        setCreated(false);
        setError({
          type: "general",
          message: "Something went wrong. Please try again!",
        });
      });
  };

  const OpenIndexationWindow = async (partner: IPartner) => {
    let transactionId = uuidv4();
    let values: ICaseDetails = {
      pt_pid: patient.patientId,
      pt_sex: patient.gender,
      pt_first_name: patient.firstName,
      pt_middle_name: patient.middleName,
      pt_last_name: patient.lastName,
      pt_birthdate: patient.dateOfBirth,
      case_uid: dicomSeries.seriesInstanceUid,
      case_date: getAcquisitionDate(dicomSeries),
      acquisition_date: dicomSeries.acquisitionDateUtc,
      case_type: dicomSeries.caseType,
      scan_mode: dicomSeries.scanMode,
      scan_object: dicomSeries.scanObject,
      device_sn: dicomSeries.deviceSerialNumber,
      token: await getAuthToken(),
      lang: "en",
      partner_pk: partner.partner_pk,
      transaction_id: transactionId,
      software_name: "DEXIS IS Cloud",
      software_version: config.buildVersion,
      model_name: dicomSeries.deviceModelName,
    };
    setIndexationState({
      isFrameOpen: true,
      partnerId: partner.partner_id,
      values: values,
    });
  };

  const uuidv4 = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c: any) =>
      (
        ((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >>
        (c / 4)
      ).toString(16)
    );
  };

  const handleFrameClosed = () => {
    onClose();
    setIndexationState({
      ...indexationState,
      isFrameOpen: false,
    });
  };

  return (
    <>
      {showFileDialog ? (
        <FileDialog
          open={showFileDialog}
          onClose={() => setShowFileDialog(false)}
          resourceFiles={allBlobAttachments}
          setResourceFiles={setAllBlobAttachments}
        />
      ) : (
        <CaseDialog open={open} fullWidth>
          <View>
            {canAdjustData === false ? (
              <Stepper activeStep={1}>
                <Step className="activeStep">
                  <StepLabel>Select partner</StepLabel>
                </Step>
              </Stepper>
            ) : (
              <Stepper activeStep={selectedPartner ? 1 : activeStep}>
                {["Select partner", "Complete information"].map(
                  (label, index) => (
                    <Step
                      key={label}
                      className={activeStep >= index ? "activeStep" : ""}
                    >
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  )
                )}
              </Stepper>
            )}
            {creatingCase === true ? (
              <CaseSentMessage>
                <p>Case Sent!</p>
              </CaseSentMessage>
            ) : (
              <>
                {activeStep === 0 || (activeStep === 1 && !canAdjustData) ? (
                  <PartnerInformation>
                    <TitleWrapper>
                      <Title>Export</Title>
                      <RefreshButton onClick={getPartners}>
                        <SyncRounded />
                      </RefreshButton>
                    </TitleWrapper>
                    <MainContent>
                      {isLoading ? (
                        <LoaderContainer>
                          <Loader />
                        </LoaderContainer>
                      ) : (
                        <>
                          <Divider>
                            <span>Partners</span>
                            <hr />
                          </Divider>
                          <PartnerList>
                            {thirdPartyPremiumPartners.map((partner) => (
                              <PremiumPartner
                                key={partner.partner_pk}
                                selected={
                                  selectedPartner?.partner_pk ===
                                  partner.partner_pk
                                }
                                paired={partner.paired}
                                onClick={() =>
                                  partner.paired === true &&
                                  (partner.software_name === "web_indexer"
                                    ? OpenIndexationWindow(partner)
                                    : togglePartnerSelection(
                                        partner.partner_pk
                                      ))
                                }
                              >
                                <img
                                  src={GreenCheck}
                                  alt="green check"
                                  className="checkmark"
                                />
                                <img
                                  src={partner.partner_logo}
                                  alt={partner.partner_name}
                                  className="logo"
                                />
                              </PremiumPartner>
                            ))}
                          </PartnerList>
                          <PartnerList>
                            {thirdPartyNonPremiumPartners.map((partner) => (
                              <NonPremiumPartner
                                key={partner.partner_pk}
                                onClick={() =>
                                  partner.paired === true &&
                                  (partner.software_name === "web_indexer"
                                    ? OpenIndexationWindow(partner)
                                    : togglePartnerSelection(
                                        partner.partner_pk
                                      ))
                                }
                                selected={
                                  selectedPartner?.partner_pk ===
                                  partner.partner_pk
                                }
                                paired={partner.paired}
                              >
                                <img
                                  src={GreenCheck}
                                  alt="green check"
                                  className="checkmark"
                                />
                                <img
                                  src={partner.partner_logo}
                                  alt={partner.partner_name}
                                  className="logo"
                                />
                              </NonPremiumPartner>
                            ))}
                          </PartnerList>
                          <Divider>
                            <span>Labs Exchange</span>
                            <hr />
                          </Divider>
                          <LabsList>
                            {labPartners.map((partner) => (
                              <LabContainer key={partner.partner_pk}>
                                <LabCircle
                                  onClick={() =>
                                    togglePartnerSelection(partner.partner_pk)
                                  }
                                  selected={
                                    selectedPartner?.partner_pk ===
                                    partner.partner_pk
                                  }
                                  paired={partner.paired}
                                >
                                  <img
                                    src={GreenCheck}
                                    alt="green check"
                                    className="checkmark"
                                  />
                                  {partner.partner_name
                                    .trim()
                                    .split(" ")
                                    .map((word) => word[0].toUpperCase())
                                    .join("")}
                                </LabCircle>
                                <LabLabel>{partner.partner_name}</LabLabel>
                              </LabContainer>
                            ))}
                          </LabsList>
                        </>
                      )}
                    </MainContent>

                    {error.message && (
                      <ErrorMessage>
                        <p>
                          {error.message}{" "}
                          {(error.type === "pairing" ||
                            error.type === "connecting") && (
                            <a
                              href="https://dentalconnect.dexis.com/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              IS Connect
                            </a>
                          )}
                        </p>
                        <button onClick={handleCloseError}>
                          <Clear />
                        </button>
                      </ErrorMessage>
                    )}
                  </PartnerInformation>
                ) : (
                  <>
                    {isLoading ? (
                      <LoaderContainer>
                        <Loader />
                      </LoaderContainer>
                    ) : (
                      <CaseInformation>
                        <Title>Case Information</Title>
                        <MainContent>
                          <FormField>
                            <span>Dr. Name</span>
                            <input
                              disabled
                              value={`Dr. ${location && location.name}`}
                            />
                          </FormField>
                          <FormField>
                            <span>Patient</span>
                            <input
                              disabled
                              value={`${patient?.firstName} ${patient?.lastName}`}
                            />
                          </FormField>
                          <FormField>
                            <span>Due Date</span>
                            <DueDate>
                              <DatePicker
                                selected={prescription?.dueDate?.value}
                                onChange={handleDateChange}
                                placeholderText="Due Date"
                                dateFormat={"dd-MM-yyyy"}
                                minDate={new Date()}
                              />
                            </DueDate>
                          </FormField>
                          <FormField className="form-container-row">
                            <span>Comment</span>
                            <textarea
                              placeholder="Add comment here."
                              value={prescription?.comment?.value}
                              onChange={(e) =>
                                setPrescription({
                                  ...prescription,
                                  comment: {
                                    ...prescription.comment,
                                    value: e.target.value,
                                  },
                                })
                              }
                            />
                          </FormField>
                          {error.message && (
                            <ErrorMessage>
                              <p>{error.message}</p>
                              <button onClick={handleCloseError}>
                                <Clear />
                              </button>
                            </ErrorMessage>
                          )}
                          <FormField className="form-container-row">
                            <div className="attachments">
                              <span>Attachments</span>
                              {!HideAttachmentButton && (
                                <label onClick={() => setShowFileDialog(true)}>
                                  <AttachFile />
                                </label>
                              )}
                            </div>
                            <AttachmentContainer>
                              {allSelectedAttachments.map((blobFile: any) => (
                                <Attachment key={blobFile.recordId}>
                                  <span>
                                    {blobFile.fileName || blobFile.name}
                                  </span>
                                  <IconsButton
                                    type="button"
                                    onClick={() => removeFile(blobFile)}
                                    className={"clear-button-visible"}
                                  >
                                    <DeleteIcon />
                                  </IconsButton>
                                </Attachment>
                              ))}
                            </AttachmentContainer>
                          </FormField>
                        </MainContent>
                      </CaseInformation>
                    )}
                  </>
                )}
                <Actions>
                  {activeStep === 0 ? (
                    <>
                      <BaseButton onClick={onClose}>Cancel</BaseButton>
                      <NextStepButton
                        disabled={!selectedPartner || !selectedPartner.paired}
                        onClick={handleNext}
                      >
                        Next
                      </NextStepButton>
                    </>
                  ) : (
                    <>
                      {canAdjustData ? (
                        <BaseButton onClick={handleBack}>Back</BaseButton>
                      ) : (
                        <BaseButton onClick={onClose}>Cancel</BaseButton>
                      )}
                      <NextStepButton
                        disabled={!selectedPartner?.paired}
                        onClick={() =>
                          createNewCase(
                            dicomSeries.recordId,
                            selectedPartner!.partner_pk
                          )
                        }
                      >
                        Send
                      </NextStepButton>
                    </>
                  )}
                </Actions>
              </>
            )}
          </View>
          <PceFrame open={indexationState.isFrameOpen}>
            <Close
              className="close-frame-button"
              onClick={() => handleFrameClosed()}
            />
            {indexationState.partnerId === "SPRINTRAY_ID" ? (
              <Sprintray
                {...indexationState.values}
                onTransactionIdReceived={onCaseFinalization}
              />
            ) : indexationState.partnerId === "SPARK_ID" ? (
              <Spark
                {...indexationState.values}
                onTransactionIdReceived={onCaseFinalization}
              />
            ) : (
              "Partner not supported"
            )}
          </PceFrame>
        </CaseDialog>
      )}
    </>
  );
};

export default ExportCase;
