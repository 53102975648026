import { styled, createGlobalStyle } from "styled-components";

export const AppLayout = styled.div`
  min-height: 100vh;
  background-color: white;
`;

export const BodyLayout = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  }
`;
