import React from "react";

const DicomExport: React.FC = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2549 2C35.604 1.99973 36.5178 3.51029 35.6924 4.69744L27.2809 16.7957C26.5461 17.8525 24.9322 17.7772 24.3098 16.6394L24.3098 16.6394L24.3054 16.6312L22.6908 13.6129C20.4126 14.8743 18.4496 16.0888 16.9786 17.8634C15.3586 19.8177 14.267 22.5467 14.267 27C14.267 27.4569 13.9574 27.8557 13.5147 27.9688C13.072 28.082 12.609 27.8807 12.3897 27.4799C10.7258 24.438 9.99986 21.8362 10 19.5681C10.0001 17.2817 10.7385 15.412 11.8648 13.8538C12.9778 12.3139 14.4605 11.0903 15.9406 10.052C16.6832 9.53117 17.4383 9.04814 18.1561 8.59387L18.4503 8.40787C18.7876 8.19474 19.1142 7.98839 19.4289 7.7855L17.6911 4.53947C17.0449 3.3502 17.9582 2.00328 19.2271 2.00302L34.2549 2ZM25.8717 15.319L33.7414 4.0001L19.6724 4.00293L21.6229 7.6462C21.8646 8.09774 21.7261 8.65892 21.3021 8.94614C20.7369 9.32893 20.1277 9.71384 19.5154 10.1007L19.2256 10.2839C18.5099 10.7368 17.7897 11.198 17.0892 11.6894C15.6831 12.6757 14.4085 13.7487 13.4857 15.0254C12.5761 16.2837 12.0001 17.7505 12 19.5683C11.9999 20.5743 12.1764 21.7106 12.592 23.0005C13.0727 20.2942 14.0667 18.2423 15.4389 16.587C17.3802 14.2451 20.0118 12.7916 22.6176 11.3721C22.8517 11.2446 23.1269 11.2158 23.3823 11.2921C23.6377 11.3684 23.852 11.5436 23.9778 11.7786L25.8717 15.319ZM11 7C11.5523 7 12 7.44772 12 8C12 8.55228 11.5523 9 11 9H5C3.34315 9 2 10.3431 2 12V29C2 30.6569 3.34315 32 5 32H28C29.6569 32 31 30.6569 31 29V19C31 18.4477 31.4477 18 32 18C32.5523 18 33 18.4477 33 19V29C33 31.7614 30.7614 34 28 34H5C2.23858 34 0 31.7614 0 29V12C0 9.23858 2.23858 7 5 7H11Z"
        fill="#4D4D4D"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default DicomExport;
