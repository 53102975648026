import styled from "styled-components";
import { colors } from "../../../theme";
import { FormControl, Radio } from "@mui/material";

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;

  & img {
    margin: 20px;
  }

  & .error {
    color: ${colors["error-red-text"]};
  }
`;

export const Message = styled.p`
  padding: 0 50px;
  color: black;
  font-size: 15px;
  font-weight: bold;
`;

export const MessageNoMargin = styled(Message)`
  margin-block-start: 0px;
  margin-block-end: 0px;
`;

export const FormContainer = styled(FormControl)`
  & .radioButtons {
    padding: 0 50px;
  }

  & .radioLabel {
    font-family: "Arial";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: ${colors["gray-14"]};
  }

  & .dob-label {
    padding: 0 50px;
    font-family: "Arial";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: ${colors["gray-14"]};
  }

  & .datepicker-container {
    padding: 0 50px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 50px;
  justify-content: center;
`;

export const CustomRadio = styled(Radio)`
  color: ${colors["gray-14"]};
  &.Mui-checked {
    color: ${colors["login-blue"]};
  }
`;

export const BirthDateSelector = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 50px;
`;