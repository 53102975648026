import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppErrorBoundary from "./components/shared/AppErrorBoundary";
import { AppConfigProvider } from "./components/shared/useAppConfig";
import RootComponent from "./shared/RootComponent";
import { AuthServiceProvider } from "./services/authService";
import { AppLayout } from "./App.styled";

const App: React.FC = () => {
  return (
    <AppLayout>
      <AppErrorBoundary>
        <AppConfigProvider>
          <AuthServiceProvider>
            <BrowserRouter>
              <RootComponent />
            </BrowserRouter>
          </AuthServiceProvider>
        </AppConfigProvider>
      </AppErrorBoundary>
    </AppLayout>
  );
};

export default App;
