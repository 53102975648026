import React, { useState } from "react";
import { CircularProgress, MenuItem } from "@mui/material";
import { IDicomSeries, ILocation } from "../../../services/api/models";
import { getSeriesDataUrl } from "../../../services/api/patientService";
import DownloadArrowIcon from "../../svg-icons/DownloadArrowIcon";
import { StyledIconsButton, StyledMenu } from "./DownloadButton.styled";
import TooltipMUI from "../TooltipMUI/TooltipMUI";

interface DicomSeriesCardProps {
  dicomSeries: IDicomSeries;
  location: ILocation;
}

const DownloadButton: React.FC<DicomSeriesCardProps> = ({
  dicomSeries,
  location,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const nonDicomFileFormats = ["PLY", "STL", "OBJ", "XORDER", "DENTALPROJECT"];

  const isMeshFile = dicomSeries.modality === "ES";
  const openDropDown = (event: React.MouseEvent<HTMLElement>) => {
    setIsMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const closeDropDown = () => {
    setIsMenuOpen(false);
    setAnchorEl(null);
  };

  const handleFormatChange = async (
    format: string,
    withAttachedFile: boolean
  ) => {
    closeDropDown();

    if (location?.account.region.apiUrl && dicomSeries.recordId) {
      try {
        setLoading(true);
        const response = await getSeriesDataUrl(
          location.account.region.apiUrl,
          dicomSeries.recordId,
          format,
          withAttachedFile
        );

        // Create a hidden anchor element
        const anchor = document.createElement("a");
        anchor.href = response;

        // Trigger a click event on the anchor element
        anchor.click();

        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    }
  };

  return (
    <>
      {loading ? (
        <CircularProgress size={28} />
      ) : (
        <>
          <TooltipMUI title={["Download your file"]} themeColor="gray">
            <StyledIconsButton onClick={openDropDown} $isMenuOpen={isMenuOpen}>
              <DownloadArrowIcon />
            </StyledIconsButton>
          </TooltipMUI>
          <StyledMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={closeDropDown}
          >
            <MenuItem onClick={() => handleFormatChange("DICOM", false)}>
              DICOM
            </MenuItem>
            {isMeshFile &&
              nonDicomFileFormats.map((format, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleFormatChange(format, false)}
                >
                  {format}
                </MenuItem>
              ))}
          </StyledMenu>
        </>
      )}
    </>
  );
};

export default DownloadButton;
