import React, { useContext } from "react";
import {
  MsalProvider,
  UnauthenticatedTemplate as MsalUnauthenticatedTemplate,
  AuthenticatedTemplate as MsalAuthenticatedTemplate,
} from "@azure/msal-react";
import { getMsalClient } from "./msalClient";
import MsalContainer from "./MsalContainer";
import IAuthService from "../../shared/interfaces/IAuthService";
import { AccountInfo } from "@azure/msal-browser";
import { ILocation, IUser, ILocationListResult } from "../api/models";
import http from "../api/http";
import getLoadedConfig from "../appConfigService";
import jwtDecode from "jwt-decode";

const accountIdentifierKey = "_account_Id_";
const accountInfoKey = "_account_info_";
const locationInfoKey = "_location_info_";

export const getCurrentAccountId = (throwIfNotFound: boolean = false) => {
  const id = sessionStorage.getItem(accountIdentifierKey) || undefined;
  if (!id && throwIfNotFound) {
    throw new Error("Can't find logged in user's account ID.");
  }

  return id;
};

export const getLoggedInUser = (throwIfNotFound: boolean = true) => {
  const accountId = getCurrentAccountId(throwIfNotFound);
  const account = getMsalClient().getAccountByHomeId(accountId || "");

  if (!account && throwIfNotFound) {
    throw new Error("No logged in user found");
  }
  return account;
};

async function getUserAccount(): Promise<IUser | undefined> {
  const data = sessionStorage.getItem(accountInfoKey);
  if (data) {
    const accountInfo = JSON.parse(data) as IUser;
    return accountInfo;
  } else {
    try {
      const config = getLoadedConfig();
      const url = config.globalApiUrl + `users/account`;

      const res = await http.get(url);
      let user = res.data["0"] as IUser;

      sessionStorage.setItem(accountInfoKey, JSON.stringify(user));

      return user;
    } catch (error) {
      return undefined;
    }
  }
}

async function loadCurrentLocation() {
  const data = sessionStorage.getItem(locationInfoKey);
  if (data) {
    const locationInfo = JSON.parse(data) as ILocation;
    return locationInfo;
  } else {
    try {
      const config = getLoadedConfig();
      const url = config.globalApiODataUrl +
        "Locations?$expand=account($expand=region)&$expand=userLocations($expand=user)&$expand=PlanTransactions";
      
      const { data } = await http.get<ILocationListResult>(url);
      let location = (data.value && data.value.length > 0) ? data.value[0] : null;
      if (location) {
        // Get tiering enablement status
        const statusUrl = config.globalApiUrl + 'locations/tieringStatus';
        const {data} = await http.get<boolean>(statusUrl);
        location.cloudTieringEnabled = data;
        sessionStorage.setItem(locationInfoKey, JSON.stringify(location));
      }
      
      return location;
    } catch (error) {
      return null;
    }
  }
}

const setLoggedInUser = (account: AccountInfo | null) => {
  if (!account) {
    sessionStorage.removeItem(accountIdentifierKey);
    sessionStorage.removeItem(accountInfoKey);
    sessionStorage.removeItem(locationInfoKey);
  } else {
    sessionStorage.setItem(accountIdentifierKey, account.homeAccountId);
  }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const getAzureAdScopes = () => getLoadedConfig().azureAd.scopes;

// Base class to keep in common base behaviour
abstract class BaseAuthService implements IAuthService {
  locationStorageKey = locationInfoKey;

  login = async () => {
    this.setLoggedInUser(null);
    await getMsalClient().loginRedirect({
      scopes: getAzureAdScopes().globalScopes,
      prompt: "login",
    });
  };

  logout = async () => {
    this.setLoggedInUser(null);
    await getMsalClient().logoutRedirect();
  };

  logoutRedirect = async (postLogoutRedirectUri: string) => {
    this.setLoggedInUser(null);
    await getMsalClient().logoutRedirect({
      postLogoutRedirectUri: postLogoutRedirectUri,
    });
  };

  get isLoggedIn() {
    return !!this.getLoggedInUser(false);
  }

  setLoggedInUser = (account: AccountInfo | null) => {
    setLoggedInUser(account);
  };

  getLoggedInUser = (throwIfNotFound = true) =>
    getLoggedInUser(throwIfNotFound);

  loginWithToken = async (token: string) => {
    this.setLoggedInUser(null);
    if (token) {
      await getMsalClient().loginRedirect({
        scopes: getAzureAdScopes().globalScopes,
        extraQueryParameters: { id_token_hint: token },
      });
    }
  };

  getAuthToken = async () => {
    let account = getLoggedInUser();
    if (account) {
      const request = {
        scopes: getAzureAdScopes().regionalScopes,
        account: account,
      };

      try {
        const authResponse = await getMsalClient().acquireTokenSilent(request);
        return authResponse.accessToken;
      } catch (error) {
        console.error("Error acquiring token: ", error);
        throw error;
      }
    } else {
      throw new Error("Cannot acquire token without a logged-in user.");
    }
  };

  isTokenExpired = (token: string) => {
    if (!token) {
      // Token is not provided
      return true;
    }
    interface TokenPayLoad {
      exp: number;
    }
    try {
      const decodedToken = jwtDecode<TokenPayLoad>(token);
      const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds

      // Check if the current time is after the token expiration time
      const currentTime = Date.now();
      return currentTime > expirationTime;
    } catch (error) {
      // Error occurred while decoding the token
      console.error("Error decoding token:", error);
      return true; // Treat as expired token to be safe
    }
  };

  abstract getUserOid(): Promise<string>;
  abstract getUserId(): Promise<string>;
  abstract getUserEmail(): Promise<string>;
  abstract getLocationPlan(): Promise<string>;
  abstract getUserAvatar(): Promise<string>;
  abstract getDisplayName(): Promise<string>;
  abstract getFirstName(): Promise<string>;
  abstract getLastName(): Promise<string>;
  abstract isUserAccountSet(): Promise<boolean>;
  abstract isUserAllowed(): Promise<boolean>;
  abstract getCurrentLocation(): Promise<ILocation>;
  abstract isPaidLocation(): Promise<boolean>;
}

// Auth Service for ISCloud
class ISCloudAuthService extends BaseAuthService {
  getUserOid = async () => {
    const account = (await getUserAccount()) as IUser;

    if (account) {
      return account.oid;
    } else {
      return "Unknown";
    }
  };

  getUserId = async () => {
    const account = (await getUserAccount()) as IUser;

    if (account) {
      return account.recordId;
    } else {
      return "Unknown";
    }
  };

  getUserEmail = async () => {
    const account = (await getUserAccount()) as IUser;

    if (account) {
      return account.emailAddress;
    } else {
      return "Unknown";
    }
  };

  getLocationPlan = async () => {
    const currentLocation = (await this.getCurrentLocation()) as ILocation;
    if (
      currentLocation &&
      currentLocation.planTransactions &&
      currentLocation.planTransactions.length > 0
    ) {
      const currentDate = new Date();
      const validTransaction = currentLocation.planTransactions.find((transaction) => {
        const expiresOn = new Date(transaction.expiresOn);
        return expiresOn > currentDate && transaction.recordStatus === "Active";
      });
      if (validTransaction) {
        return validTransaction.transactionType.toString();
      }
    }
    return "Free";
  };

  isPaidLocation = async () => {
    const locationPlan = await this.getLocationPlan();
    const currentLocation = (await this.getCurrentLocation()) as ILocation;

    return currentLocation.cloudTieringEnabled === false || locationPlan !== 'Free';
  };

  getUserAvatar = async () => {
    const account = (await getUserAccount()) as IUser;

    if (account) {
      // Take the first letter of the surname and the first letter of the firstname for avatar
      const firstName =
        account.firstName != null ? account.firstName[0].toUpperCase() : "";
      const surname =
        account.surname != null ? account.surname[0].toUpperCase() : "";
      const avatar = firstName + surname;
      return avatar;
    } else {
      return "";
    }
  };

  getDisplayName = async () => {
    const account = (await getUserAccount()) as IUser;

    if (account && (account.firstName || account.surname)) {
      const firstName = account.firstName ? account.firstName : "";
      const surname = account.surname ? account.surname : "";
      const displayName = (firstName + " " + surname).trim();
      return displayName;
    } else {
      return "";
    }
  };

  getFirstName = async () => {
    const account = (await getUserAccount()) as IUser;
    return account && account.firstName ? account.firstName : "";
  };

  getLastName = async () => {
    const account = (await getUserAccount()) as IUser;
    return account && account.surname ? account.surname : "";
  };

  isUserAccountSet = async () => {
    const account = (await getUserAccount()) as IUser;
    return account ? true : false;
  };

  isUserAllowed = async () => {
    const account = (await getUserAccount()) as IUser;
    return account.isAllowed;
  };

  getCurrentLocation = async() => {
    return (await loadCurrentLocation()) as ILocation;
  }
}

/// Auth Provider
const context = React.createContext<IAuthService | Error>(
  new Error("MSAL Client isn't available on context!")
);

const FactoryAuthService = (): IAuthService | null => {
  return new ISCloudAuthService();
};

interface IProps {
  children: any;
}

export const AuthServiceProvider: React.FC<IProps> = ({ children }) => {
  const instanceAuthService = FactoryAuthService();
  if (!instanceAuthService) throw new Error("Cannot create autService");

  // Wrap in MSAL provider
  return (
    <context.Provider value={instanceAuthService}>
      <MsalProvider instance={getMsalClient()}>
        <MsalContainer authService={instanceAuthService} logMessages={true}>
          {children}
        </MsalContainer>
      </MsalProvider>
    </context.Provider>
  );
};

function useAuthService() {
  const ctx = useContext(context);
  if (ctx instanceof Error) {
    throw ctx;
  }
  return ctx;
}

export const UnauthenticatedTemplate: React.FC<IProps> = ({ children }) => (
  <MsalUnauthenticatedTemplate
    homeAccountId={getCurrentAccountId()}
    children={children}
  />
);

export const AuthenticatedTemplate: React.FC<IProps> = ({ children }) => (
  <MsalAuthenticatedTemplate
    homeAccountId={getCurrentAccountId()}
    children={children}
  />
);

export default useAuthService;
