import {
  ICaseRecord,
  IDentalCaseAnswer,
  IDentalCaseStatus,
  IDentalCaseStatusAnswer,
  ISparkCase,
  ISprintRayTreatmentType,
} from "./models";
import http from "./http";
import getLoadedConfig from "../appConfigService";

export async function getSparkCases(
  partnerPk: string | null,
  firstName: string | null,
  lastName: string | null,
  middleName: string | null
) {
  firstName = firstName || "";
  lastName = lastName || "";
  middleName = middleName || "";
  const config = getLoadedConfig();
  const url =
    config.connectUrl +
    `spark/search?labPk=${partnerPk}&firstName=${firstName}&lastName=${lastName}&middleName=${middleName}`;
  const { data } = await http.get<ISparkCase[]>(url);
  return data;
}

export async function getSprintrayTreatmentList(partnerPk: string) {
  const config = getLoadedConfig();
  const url =
    config.connectUrl + `sprintray/treatmenttypes?recipient=${partnerPk}`;
  const { data } = await http.get<ISprintRayTreatmentType[]>(url);
  return data;
}

// Route shall be spark or sprintray
export async function createDentalCase(route: string, caseInfo: ICaseRecord) {
  const config = getLoadedConfig();

  const url = config.connectUrl + route;
  const { data } = await http.post<IDentalCaseAnswer>(url, caseInfo);
  return data.queryable[0];
}

export function convertDate(case_date: string | null): string | null {
  if (case_date == null) {
    return null;
  }
  if (case_date.length === 8) {
    return (
      case_date.slice(0, 4) +
      "-" +
      case_date.slice(4, 6) +
      "-" +
      case_date.slice(6)
    );
  }
  if (case_date.length < 11) {
    return case_date;
  }
  const yearMonthDay = case_date.slice(0, 10);
  const T_sep = case_date.slice(10, 11);
  const hours = case_date.slice(11, 13);
  const min = case_date.slice(13, 15);
  const sec = case_date.slice(15, 17);
  if (
    T_sep === "T" &&
    !isNaN(Number(hours)) &&
    !isNaN(Number(min)) &&
    !isNaN(Number(sec))
  ) {
    return `${yearMonthDay}T${hours}:${min}:${sec}`;
  } else {
    return case_date;
  }
}

export async function getCasesFromTransactionId(
  transactionId: string | null
): Promise<IDentalCaseStatus | null> {
  const config = getLoadedConfig();
  const url =
    config.connectODataUrl +
    `DentalCase?$filter=TransactionId eq '${transactionId}'&$select=caseid,casepk,partnerCaseDirectLink,status`;

  const { data } = await http.get<IDentalCaseStatusAnswer>(url);
  if (data.value && data.value.length === 1) {
    return data.value[0];
  }
  return null;
}

export interface IStatus {
  color: string;
  statusString: string;
  state: string;
}

export function getCaseStatus(status: number): IStatus {
  const caseStatus: IStatus = {
    color: "#7D7E7E",
    statusString: "Unsubmitted",
    state: "Wait",
  };

  switch (true) {
    case status === 0:
    case undefined:
      return caseStatus;
    case status < 14:
      caseStatus.state = "Wait";
      return caseStatus;
    case status === 14:
      caseStatus.state = "Done";
      return caseStatus;
    case status > 14:
      caseStatus.state = "Error";
      return caseStatus;
    default:
      return caseStatus;
  }
}
