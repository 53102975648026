import React, { useState } from "react";
import Timeline from "./Timeline/Timeline";
import PatientList from "./PatientList/PatientList";

const LocationData: React.FC = () => {
  const [showTimeline, setShowTimeline] = useState(false);

  const handleShowTimeline = () => {
    setShowTimeline(true);
  };

  const handleShowPatientList = () => {
    setShowTimeline(false);
  };

  return (
    <>
      {showTimeline ? (
        <Timeline onShowPatientList={handleShowPatientList} />
      ) : (
        <PatientList onShowTimeline={handleShowTimeline} />
      )}
    </>
  );
};

export default LocationData;
