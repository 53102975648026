import styled from "styled-components";
import { colors } from "../../../../theme";

export const PatientRXSContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: center;
  width: 100%;
  overflow: hidden;
`;

export const PatientLoaderContainer = styled.div`
  display: flex;
  height: calc(100% - 55px);
  justify-content: center;
`;

export const PatientFilesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-flow: wrap;
  gap: 20px 15px;
`;

export const DicomContainer = styled.div`
  position: relative;
`;

export const ExportCaseContainer = styled.div`
  position: absolute;
  z-index: 99;
  right: 0;
  margin-right: 14px;
  margin-top: 12px;

  & button {
    width: 30px;
    height: 30px;
  }
`;

export const StyledRXBox = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 15px 15px 15px;
  border: 1px solid ${colors["gray-5"]};
  border-radius: 8px;
  background-color: white;
  gap: 5px 15px;
`;

export const RXHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  & svg {
    position: unset;
  }

  & p:first-of-type {
    margin-right: auto;
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;

  & button {
    padding: 5px;
  }

  & button img {
    width: 28px;
  }
  & .iconTooltip {
    font-size: 14px;
  }
`;

export const PartnerLogo = styled.img`
  object-fit: contain;
  padding-top: 4px;
  width: 8%;
  height: 6%;
`;
