import styled from "styled-components";
import { OrangeButton } from "../../../common/buttons.styled";
import { colors, media } from "../../../../theme";

export const CreationFormContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  max-width: 1000px;
  background-color: ${colors["background-grey-light-color"]};
  margin: 10% auto 0 auto;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  border-radius: 8px;

  & label {
    font-weight: 600;
  }

  & .inputs-container {
    width: 75%;

    & .flagsMenu {
      padding-top: 16px;

      button:hover,
      button:active {
        background-color: white;
      }
    }
  }

  & div > .input {
    height: 46px;
    border-radius: 4px;
    margin-bottom: 12px;
    padding: 8px;
    border: 1px solid ${colors["gray-5"]};
  }

  ${media.laptop} {
    width: 70%;
  }

  ${media.tablet} {
    width: 80%;

    & .inputs-container {
      width: 80%;
      margin-top: 0;

      & .flagsMenu {
        padding-top: 5px;
      }
    }

    & div {
      margin-top: 5px;
    }
  }

  ${media.phoneL} {
    width: 95%;

    & .inputs-container {
      width: 90%;
      font-size: 15px;
    }
  }
`;

export const NextStepButton = styled(OrangeButton)`
  margin: 12px 20px 12px auto;

  ${media.phoneL} {
    width: unset;
    min-width: 80px;
    height: 45px;
  }

  ${media.phoneS} {
    height: 40px;
  }
`;

export const EmbeddedLocationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 100px);

  & img {
    width: 140px;
    margin-right: 8px;
  }

  ${media.laptop} {
    & img {
      width: 100px;
    }
  }

  ${media.tablet} {
    & img {
      width: 60px;
    }
  }

  ${media.phoneL} {
    & img {
      width: 50px;
    }
  }
`;

export const EmbeddedMessagesContainer = styled.section`
  display: flex;
  flex-direction: column;
  color: #807d7d;
  font-size: 26px;
  padding-right: 10px;

  & > b {
    color: #4b8935;
  }

  & > b.error {
    color: ${colors["error-red-text"]};
  }

  ${media.laptop} {
    font-size: 22px;
  }

  ${media.tablet} {
    font-size: 20px;
  }

  ${media.phoneL} {
    font-size: 18px;
  }

  ${media.phoneS} {
    font-size: 15px;
  }
`;

export const UserTypeSelection = styled.div`
  display: grid;
  grid-template-rows: 3fr 1fr 1fr;
  margin: 10% auto 0 auto;
  width: 80%;

  ${media.tablet} {
    width: 95%;
  }

  ${media.phoneL} {
    width: 100%;
  }
`;

export const SelectionButton = styled.button`
  display: flex;
  align-items: center;
  padding: 20px 24px;
  font-weight: 600;
  background-color: #f8f8f8;
  border: none;
  border-radius: 12px;
  cursor: pointer;

  & svg {
    color: white;
    background-color: grey;
    border-radius: 6px;
    margin-right: 12px;
  }

  & .selected {
    background-color: ${colors["orange-1"]};
  }

  ${media.phoneL} {
    padding: 15px 20px;
    font-size: 12px;

    & svg {
      width: 20px;
      height: 20px;
    }
  }

  ${media.phoneS} {
    padding: 12px 18px;
  }
`;

export const SelectionsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const WelcomeMessages = styled.section`
  text-align: center;
  padding: 0 30px;

  & h1 {
    font-size: 28px;
    font-weight: 600;
  }

  & span {
    font-weight: 600;
    color: black;
  }

  & li {
    margin-bottom: 18px;
    font-size: 16px;
    color: grey;
    list-style: none;
  }

  & p {
    font-size: 16px;
    font-weight: 600;
    margin: 35px 0 15px 0;
  }

  ${media.tablet} {
    padding: 0 20px;

    & h1 {
      font-size: 26px;
    }
  }

  ${media.phoneL} {
    padding: 0 10px;

    & h1 {
      font-size: 22px;
    }

    & ul {
      padding-left: 30px;
    }

    & span {
      letter-spacing: 0.5px;
    }

    & li {
      margin-bottom: 15px;
      font-size: 15px;
      text-align: start;
    }

    & p {
      font-size: 15px;
      margin: 25px 0 10px 0;
      letter-spacing: 0.5px;
    }
  }

  ${media.phoneS} {
    & h1 {
      font-size: 20px;
    }

    & ul {
      padding-left: 15px;
    }

    & li {
      margin-bottom: 15px;
      font-size: 15px;
    }

    & p {
      font-size: 15px;
      margin: 25px 0 10px 0;
    }
  }
`;

export const TermsOfUsePopUp = styled.div`
  position: absolute;
  top: -10px;
  width: 150%;
  height: 150%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    margin: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors["orange-1"]};
    border-radius: 10px;
  }

  ${media.laptop} {
    width: 90vw;
  }

  ${media.tablet} {
    width: 97vw;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 2px 0 0 0;
  border: 1px solid ${colors["orange-1"]};
  cursor: pointer;
  transition: 0.3s ease;

  & svg {
    color: ${colors["orange-1"]};
  }

  &:hover {
    box-shadow: 0 0 1px 2px #e7e6e6;
  }
`;

export const TermsOfUseLink = styled.span`
  color: #551a8b;
  cursor: pointer;
  text-decoration: underline;
`;
