import React from "react";
import ErrorBoundary from "./ErrorBoundary";
import { ErrorContainer, ReloadButton } from "./ErrorBoundary.styled";

interface IProps {
  children: any;
}

const handleReload = () => {
  window.location.reload();
};

const Content: React.FC = () => {
  return (
    <>
      <p>Please reload the page and try again.</p>
      <ErrorContainer>
        <ReloadButton onClick={handleReload}>Reload</ReloadButton>
      </ErrorContainer>
    </>
  );
};

const content = <Content />;

const AppErrorBoundary: React.FC<IProps> = ({ children }) => {
  return <ErrorBoundary content={content}>{children}</ErrorBoundary>;
};

export default AppErrorBoundary;
