import {
  Configuration,
  BrowserCacheLocation,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";
import getAppConfig from "../appConfigService";

const isProdEnv = process.env.NODE_ENV === "production";
let _msalClient: PublicClientApplication;

export const consoleTextStyles = {
  Title: "background: SteelBlue; color: #000",
  Unset: "background: unset; color:unset",
};

export function getMsalClient() {
  if (!_msalClient) {
    _msalClient = new PublicClientApplication(getMsalClientConfig());
  }
  return _msalClient;
}

export function getMsalClientConfig() {
  const {
    azureAd: { tenantName, clientId, policies, loginDomain },
  } = getAppConfig();

  const msalConfig: Configuration = {
    auth: {
      clientId,
      authority: `https://${loginDomain}/${tenantName}.onmicrosoft.com/${policies.login}`,
      knownAuthorities: [loginDomain!],
      navigateToLoginRequestUrl: true,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback: (
          level: LogLevel,
          message: string,
          containsPii: boolean
        ): void => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              isProdEnv || console.info(message);
              return;
            case LogLevel.Verbose:
              isProdEnv || console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
        piiLoggingEnabled: false,
      },
    },
  };

  return msalConfig;
}
