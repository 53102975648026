import styled from "styled-components";
import { colors, media } from "../../../theme";
import { Dialog, DialogActions } from "@mui/material";

export const CaseDialog = styled(Dialog)`
  ${media.phoneL} {
    & div[role="dialog"] {
      width: 100%;
      min-height: 100vh;
      margin: 0;
      border-radius: 0;
    }
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 320px;
`;

export const MainContent = styled.div`
  padding: 10px;
`;

export const CaseSentMessage = styled.div`
  display: flex;
  align-items: center;
  min-height: 300px;
  margin: auto;

  p {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  ${media.phoneL} {
    min-height: unset;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;

  & h2 {
    margin: 0 30px;
  }
`;

export const Title = styled.h2`
  text-align: center;
  font-size: 24px;
  margin: auto;

  ${media.phoneL} {
    margin-bottom: 20px;
  }
`;

export const RefreshButton = styled.button`
  width: 30px;
  height: 30px;
  margin-top: 3px;
  padding: 0;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  color: ${colors["orange-1"]};
  cursor: pointer;

  & svg {
    width: inherit;
    height: inherit;
  }

  &:hover {
    transform: scale(1.15);
    transition: 0.3s ease-out;
  }
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  padding-bottom: 8px;

  span {
    color: ${colors["grey-color"]};
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
  }

  hr {
    height: 1px;
    flex: 1 0 0;
    background-color: ${colors["grey-color"]};
  }
`;

export const PartnerList = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  gap: 10px;
  width: 100%;
  padding: 5px 0;
`;

export const LabsList = styled(PartnerList)`
  justify-content: space-around;
`;

export const Partner = styled.div<{ selected: boolean; paired: boolean }>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  overflow: hidden;
  outline: ${(props) =>
    props.selected
      ? `solid 2px ${colors["orange-1"]}`
      : `1px solid ${colors["grey-color"]}`};
  cursor: ${(props) => (props.paired ? "pointer" : "default")};
  opacity: ${(props) => (props.paired ? "1" : "0.5")};

  & img.logo {
    box-sizing: border-box;
    object-fit: contain;
    width: inherit;
    height: inherit;
    padding: 12px;
  }

  & img.checkmark {
    display: ${(props) => (props.selected && props.paired ? "block" : "none")};
    position: absolute;
    bottom: 6px;
    right: 6px;
    width: 16px;
    height: 16px;

    ${media.phoneS} {
      bottom: 4px;
      right: 5px;
      width: 15px;
      height: 15px;
    }
  }
`;

export const PremiumPartner = styled(Partner)<{ paired: boolean }>`
  width: 162px;
  height: 120px;

  ${media.phoneS} {
    width: 130px;
    height: 85px;
  }
`;

export const NonPremiumPartner = styled(Partner)`
  width: 78px;
  height: 48px;
  cursor: pointer;

  ${media.phoneS} {
    width: 60px;
    height: 40px;
  }
`;

export const LabContainer = styled.div`
  margin: 10px auto;

  ${media.phoneL} {
    margin-bottom: 0;
  }
`;

export const LabCircle = styled(Partner)`
  position: relative;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  cursor: pointer;

  ${media.phoneS} {
    width: 50px;
    height: 50px;
  }
`;

export const LabLabel = styled.h4`
  margin-top: 8px;
  font-weight: bold;
  text-align: center;

  ${media.phoneS} {
    font-size: 15px;
  }
`;

export const Actions = styled(DialogActions)`
  gap: 18px;

  ${media.phoneS} {
    gap: 0;
  }
`;

export const BaseButton = styled.button`
  box-sizing: border-box;
  width: 50%;
  padding: 20px 10px;
  border: 1px solid ${colors["grey-color"]};
  border-radius: 5px;
  font-size: 18px;
  letter-spacing: 1px;

  &:hover {
    cursor: pointer;
  }

  ${media.phoneL} {
    padding: 17px 10px;
    font-size: 16px;
    font-weight: 600;
  }

  ${media.phoneS} {
    padding: 13px 7px;
  }
`;

export const NextStepButton = styled(BaseButton)`
  background-color: ${colors["orange-1"]};
  color: white;

  &:disabled {
    border: 1px solid ${colors["grey-color"]};
    color: ${colors["grey-color"]};
    background-color: ${colors["background-grey-light-color"]};
  }
`;

export const PartnerInformation = styled.div`
  & .css-ypiqx9-MuiDialogContent-root {
    overflow-y: hidden;
  }
`;

export const View = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  min-height: inherit;

  & .activeStep svg {
    color: #ffa07a;
  }

  ${media.phoneL} {
    padding: 10px;
  }
`;

export const FormField = styled.div`
  display: flex;
  justify-content: space-between;

  & span {
    width: 130px;
    font-weight: 600;
  }

  & input {
    width: 100%;
    max-width: 300px;
    height: 22px;
  }

  & textarea {
    height: 70px;
    resize: none;
  }

  & label {
    cursor: pointer;
  }

  & .attachments {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 8px;

    & svg {
      padding: 8px;
      border-radius: 2px;
      border: 1px solid black;
    }

    & input {
      display: none;
    }
  }
`;

export const CaseInformation = styled.div`
  & ${MainContent} {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  & .form-container-row {
    flex-direction: column;

    & > span {
      margin-bottom: 8px;
    }
  }
`;

export const DueDate = styled.div`
  width: 100%;
  & .react-datepicker__day--selected {
    background-color: ${colors["orange-1"]};
  }

  & .react-datepicker-wrapper {
    width: inherit;
  }

  & .react-datepicker__input-container {
    display: flex;
    justify-content: end;
  }
`;

export const AttachmentContainer = styled.div`
  box-sizing: border-box;
  height: 150px;
  border: 1px solid black;
  border-radius: 2px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 3px;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 20px;
    background: #ffd3c7;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors["orange-1"]};
    border-radius: 20px;
    height: 50%;
  }
`;

export const Attachment = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  & span:first-of-type {
    width: 100%;
    padding: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & button {
    margin-right: 5px;
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2px 6px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 8px;
  font-weight: 600;

  & p {
    display: inline;
    margin: 0;
  }

  & button {
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    color: inherit;
  }
`;

export const PceFrame = styled(Dialog)`
  & div[role="dialog"] {
    width: -webkit-fill-available;
    height: 100%;
  }
  & .close-frame-button {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 28px;
    cursor: pointer;
  }
`;
