import React from "react";

const AttachmentIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g transform="translate(1601 -4637.911)">
        <circle
          cx="10"
          cy="10"
          r="10"
          transform="translate(-1601 4638.026)"
          fill="none"
          opacity="0.083"
        />
        <g transform="translate(-1600.895 4637.911)">
          <path d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#aaa" />
        </g>
        <path
          d="M-.67-9.221v-1.968h2.2v1.968ZM-.67,0V-7.778h2.2V0Z"
          transform="translate(-1591.4 4653.554)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default AttachmentIcon;
