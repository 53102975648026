import styled from "styled-components";
import { colors, media } from "../../../theme";

export const UserCardContainer = styled.article`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  background-color: whitesmoke;
  padding: 10px 10px 10px 40px;
  border-radius: 12px;
  height: 260px;
  width: 100%;
  border: 1px solid #d5d5d5;

  & svg {
    color: black;
  }

  ${media.tablet} {
    padding: 10px 25px;
    height: 250px;
  }

  ${media.phoneL} {
    padding: 10px 15px;
    height: 230px;
  }

  ${media.phoneS} {
    padding: 10px;
    height: 190px;
  }
`;

export const UserCardHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  & svg {
    font-size: 45px;
  }

  ${media.tablet} {
    font-size: 20px;
    padding: 10px;

    & svg {
      font-size: 35px;
    }
  }

  ${media.phoneL} {
    font-size: 15px;
    padding: 5px;

    & svg {
      font-size: 30px;
    }
  }

  ${media.phoneS} {
    font-size: 10px;
    padding: 2px;

    & svg {
      font-size: 25px;
    }
  }
`;

export const UsersList = styled.ul`
  list-style: none;
  line-height: 30px;
  padding: 0;
  overflow: auto;

  & li {
    text-align-last: justify;
    text-wrap: nowrap;
    padding-right: 10px;
  }

  &::-webkit-scrollbar {
    background-color: ${colors["gray-4"]};
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors["orange-1"]};
    outline: 1px solid ${colors["orange-1"]};
    border-radius: 7px;
  }

  ${media.tablet} {
    font-size: 18px;
  }

  ${media.phoneL} {
    font-size: 16px;
  }

  ${media.phoneS} {
    font-size: 14px;
  }
`;

export const ErrorMessage = styled.p`
  color: ${colors["error-red-text"]};
`;
