import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import cs from './translate/cs.json'
import da from './translate/da.json'
import de from './translate/de.json'
import el from './translate/el.json'
import es from './translate/es.json'
import en from './translate/en.json'
import fr from './translate/fr.json'
import hr from './translate/hr.json'
import it from './translate/it.json'
import ja from './translate/ja.json'
import ko from './translate/ko.json'
import nl from './translate/nl.json'
import pl from './translate/pl.json'
import pt from './translate/pt.json'
import ru from './translate/ru.json'
import sv from './translate/sv.json'
import tr from './translate/tr.json'
import zh from './translate/zh.json'
import zhtw from './translate/zh-tw.json'
// the translations ressources
const resources = {
  cs: {    translation: cs  },
  da: {    translation: da  },
  de: {    translation: de  },
  el: {    translation: el  },
  es: {    translation: es  },
  en: {    translation: en  },
  fr: {    translation: fr  },
  hr: {    translation: hr  },
  it: {    translation: it  },
  ja: {    translation: ja  },
  ko: {    translation: ko  },
  nl: {    translation: nl  },
  pl: {    translation: pl  }, 
  pt: {    translation: pt  },
  ru: {    translation: ru  },
  sv: {    translation: sv  },
  tr: {    translation: tr  },
  zhtw: {  translation: zhtw  },
  zh: {   translation: zh  },
};

export const getLanguageCode = (lang: string): string => {
  if (lang.toLowerCase() === "zh-tw") {
    return "zhtw";
  } else {
    const langStr = lang.substring(0, 2).toLowerCase();
    if ( ['cs','da','de','el','es','en','fr','hr','it','ja','ko',
    'nl','pl','pt','ru','sv','tr','zh'].includes(langStr)) {
      return langStr;
    } else {
      return 'en';
    }
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage

    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;