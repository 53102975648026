import styled from "styled-components";

export const ErrorContainer = styled.div`
  margin-top: 2em;
`;

export const ReloadButton = styled.button`
  min-width: 80px;
`;

export const ErrorTitle = styled.h4`
  max-width: 50vw;
`;

export const ErrorText = styled.p`
  font-weight: bold;
`;

export const MessageContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
`;

export const LoadingContainer = styled(MessageContainer)`
  padding: 8px 20px;
`;
