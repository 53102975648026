import { IConnectUser } from "./models";
import http from "./http";
import getLoadedConfig from "../appConfigService";

export async function getCurrentUser() {
  const config = getLoadedConfig();
  const url = config.connectUrl + `users/current`;
  const { data } = await http.get<IConnectUser>(url);
  return data;
}
