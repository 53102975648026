import React from "react";
import { StyledTooltip } from "./TooltipMui.styled";

type TooltipProps = {
  children: React.ReactNode;
  title: string[];
  fontSize?: number;
  themeColor?: "light" | "gray" | "dark";
  isVisible?: boolean;
  isStatic?: boolean;
};

const TooltipMUI: React.FC<TooltipProps> = ({
  children,
  title,
  fontSize = 13,
  themeColor = "light",
  isVisible = true,
  isStatic = false,
}) => {
  const linesOfText = title.map((text) => (
    <>
      <span>{text}</span>
      <br />
    </>
  ));

  return (
    <StyledTooltip
      fontSize={fontSize}
      themeColor={themeColor}
      isVisible={isVisible}
      title={linesOfText}
      followCursor={!isStatic}
      enterDelay={100}
      placement={isStatic ? "bottom" : "bottom-start"}
      arrow={isStatic}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: isStatic ? [0, -9] : [5, 10],
            },
          },
        ],
      }}
    >
      {children}
    </StyledTooltip>
  );
};

export default TooltipMUI;
